import { find } from 'lodash'
import {
  SHOPIFY_ORDER_CUSTOM_ATTRIBUTE_GIFT_EMAIL_KEY,
  SHOPIFY_ORDER_CUSTOM_ATTRIBUTE_GIFT_TEXT_KEY
} from '../../../src/constants'

export default {
  setCart(state, cart) {
    state.cart = cart
  },

  syncGiftNote(state) {
    const { cart } = state

    if (!cart) {
      return
    }

    state.giftNote.text =
      find(cart.attributes, {
        key: SHOPIFY_ORDER_CUSTOM_ATTRIBUTE_GIFT_TEXT_KEY
      })?.value || ''

    state.giftNote.email =
      find(cart.attributes, {
        key: SHOPIFY_ORDER_CUSTOM_ATTRIBUTE_GIFT_EMAIL_KEY
      })?.value || ''
  },

  setPromoShopifyProducts(state, promoShopifyProducts = []) {
    state.promoShopifyProducts = promoShopifyProducts
  },
  setProducts(state, products = []) {
    state.products = products
  },
  setSubscriptions(state, subscriptions = []) {
    state.subscriptions = subscriptions
  },
  setFeaturedProduct(state, product) {
    state.featuredProduct = product
  },
  setPromotionalProducts(state, products) {
    state.promotionalProducts = products
  },
  setIngredients(state, ingredients) {
    state.ingredients = ingredients
  },
  setProductMenu(state, menu = []) {
    state.productMenu = menu
  },
  setShopMenu(state, menu) {
    state.shopMenu = menu
  },
  setGiftNoteEditorIsOpen(state, value) {
    state.giftNote.editorIsOpen = value
  },
  setGiftNoteText(state, value) {
    state.giftNote.text = value
  },
  setGiftNoteEmail(state, value) {
    state.giftNote.email = value
  },
  setRebuyProducts(state, products) {
    state.rebuyProducts = products
  },
  setRebuyTracker(state, productId) {
    state.rebuyTracker = productId
  }
}
