import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import queryString from 'query-string'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  data: () => ({
    skyHigh: false,
    analyticsCounter: 0,
    nonCBDSite: process.env.nonCBDSite,
    noIndex: process.env.noIndex
  }),

  computed: {
    ...mapState('screen', {
      width: state => state.width,
      height: state => state.height,
      touch: state => state.touch,
      webpSupport: state => state.webpSupport,
      transitioning: state => state.transitioning,
      scrollPos: state => state.scroll,
      scrolling: state => state.scrolling,
      gutter: state => state.gutter,
      overlay: state => state.overlay,
      backgroundImages: state => state.backgroundImages
    }),
    ...mapGetters('screen', {
      bp: 'breakpoint',
      scheme: 'scheme',
      colors: 'colors',
      shadowColor: 'shadowColor',
      semiopaqueColor: 'semiopaqueColor',
      transparentColor: 'transparentColor',
      themeColor: 'themeColor'
    }),
    hasBackground() {
      return this.backgroundImages?.length >= 1
    }
  },

  watch: {
    '$route.query'({ preview: isPreview }, { preview: wasPreview }) {
      this.checkPreviewMode(isPreview, wasPreview)
    },
    transitioning(is, was) {
      if (!is && was !== undefined) {
        this.setSkyHigh(this.height)
      }
    }
  },

  created() {
    this.debouncedResize = debounce(this.resize, 250, { leading: false })
    this.debouncedMutated = debounce(this.mutated, 250)
    this.throttledScroll = throttle(this.scroll, 250)
    this.throttledMouseMove = throttle(this.mouseMove, 50, {
      leading: false,
      trailing: true
    })
  },

  mounted() {
    this.setup()
    window.addEventListener('orientationchange', this.debouncedResize, {
      passive: true
    })
    window.addEventListener('resize', this.debouncedResize, { passive: true })
    window.addEventListener('scroll', this.throttledScroll, { passive: true })
    window.addEventListener('mousemove', this.throttledMouseMove, {
      passive: true
    })
    document.addEventListener('keydown', this.keyDown, { passive: true })
  },

  beforeDestroy() {
    window.removeEventListener('orientationchange', this.debouncedResize)
    window.removeEventListener('resize', this.debouncedResize, {
      passive: true
    })
    window.removeEventListener('scroll', this.throttledScroll, {
      passive: true
    })
    window.removeEventListener('mousemove', this.throttledMouseMove, {
      passive: true
    })
    document.removeEventListener('keydown', this.keyDown, { passive: true })
  },

  methods: {
    ...mapMutations({
      setGoogleClientId: 'setGoogleClientId',
      setGoogleSessionId: 'setGoogleSessionId',
      setDiscountCode: 'setDiscountCode',
      setFeatureFlag: 'setFeatureFlag',
      setRefersionCode: 'setRefersionCode'
    }),
    ...mapActions('screen', {
      setTouch: 'setTouch',
      setWebmSupport: 'setWebmSupport',
      setWebpSupport: 'setWebpSupport',
      setScreenSize: 'setScreenSize',
      setScroll: 'setScroll',
      setCursor: 'setCursor'
    }),
    async setup() {
      if (process.env.gtmId) {
        this.setupGoogleAnalytics()
      } else {
        console.log('Analytics are disabled.')
      }

      this.setTouch()
      this.setWebmSupport()
      this.setWebpSupport()
      this.checkPreviewMode()
      this.setDiscount()
      this.setFeatureFlagFromParams()
      this.setRefersion()

      this.storeAttributionParams()

      const screenSize = await this.setScreenSize()
      this.setSkyHigh(screenSize && screenSize.height)
      this.scroll()
    },
    setSkyHigh(height = window.innerHeight) {
      const threshold = this.bp.tall ? height * 2 : height * 2.5
      this.skyHigh = threshold <= this.$el.clientHeight
    },
    async resize() {
      const screenSize = await this.setScreenSize()
      if (screenSize) {
        // wait for vue update
        await this.$nextTick()
        // force repaint to ensure correct sizes
        await new Promise(resolve => requestAnimationFrame(resolve))
        this.setSkyHigh(screenSize.height)
        this.$bus.$emit('resize', screenSize)
      }
    },
    mutated() {
      this.$bus.$emit('documentMutated')
    },
    scroll() {
      const pos = window.pageYOffset || document.documentElement.scrollTop
      this.$bus.$emit('scroll', pos)
      this.setScroll(pos)
    },
    mouseMove({ pageX = 0, pageY = 0 }) {
      this.setCursor({ x: pageX, y: pageY })
    },
    keyDown(e) {
      if ('key' in e) {
        switch (e.key) {
          case 'ArrowLeft':
            this.$bus.$emit('leftKeydown')
            break
          case 'ArrowRight':
            this.$bus.$emit('rightKeydown')
            break
          case 'Escape':
          case 'Esc':
            this.$bus.$emit('escKeydown')
            break
          default:
            this.$bus.$emit('keydown', e)
            break
        }
      } else {
        switch (e.keyCode) {
          case 37:
            this.$bus.$emit('leftKeydown')
            break
          case 39:
            this.$bus.$emit('rightKeydown')
            break
          case 27:
            this.$bus.$emit('escKeydown')
            break
          default:
            this.$bus.$emit('keydown', e)
            break
        }
      }
    },
    checkPreviewMode(isPreview = this.$route.query.preview, wasPreview) {
      if (isPreview) {
        this.$toast.info('you are viewing a preview of this page', {
          action: {
            text: 'ok',
            onClick: (e, toast) => {
              toast.goAway(0)
            }
          }
        })
      } else if (wasPreview) {
        this.$toast.clear()
      }
    },
    setDiscount() {
      const param = process.env.discountParam
      if (!param) {
        return
      }
      const qs = queryString.parse(location.search)
      this.setDiscountCode(qs[param])
    },
    setFeatureFlagFromParams() {
      const param = process.env.featureFlagParam
      if (!param) {
        return
      }
      const qs = queryString.parse(location.search)
      this.setFeatureFlag(qs[param])
    },
    setRefersion() {
      const qs = queryString.parse(location.search)
      this.setRefersionCode(qs.rfsn)
    },
    storeAttributionParams() {
      this.expireAttributionParams()

      this.storeUTM()
      this.storeAspireTransactionID()
    },
    expireAttributionParams() {
      // Keep local storage attribution params for 3 days before expiring
      const localStorageKeys = ['utm', 'aspire']

      localStorageKeys.forEach(key => {
        const localStorageParams = localStorage.getItem(key)
        if (localStorageParams) {
          const THREE_DAYS = 1000 * 60 * 60 * 24 * 3
          const entry = JSON.parse(localStorageParams)

          if (Date.now() - entry.timestamp > THREE_DAYS) {
            localStorage.removeItem(key)
          }
        }
      })
    },
    storeUTM() {
      const qs = queryString.parse(location.search)

      const supportedUTMParams = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'utm_platform',
        'utm_creative_format',
        'utm_marketing_tactic'
      ]

      const utmParams = Object.keys(qs)
        .filter(key => supportedUTMParams.includes(key))
        .reduce((acc, key) => {
          acc[key] = qs[key]
          return acc
        }, {})

      // If there is at least one UTM param,
      // We overwrite the local storage
      if (Object.keys(utmParams).length > 0) {
        localStorage.setItem(
          'utm',
          JSON.stringify({
            value: utmParams,
            timestamp: new Date().getTime()
          })
        )
      }
    },
    storeAspireTransactionID() {
      const qs = queryString.parse(location.search)

      if (qs.transaction_id) {
        localStorage.setItem(
          'aspire',
          JSON.stringify({
            value: {
              transaction_id: qs.transaction_id
            },
            timestamp: new Date().getTime()
          })
        )
      }
    },
    setupGoogleAnalytics() {
      if (typeof this.$gtag === 'function') {
        const measurementId = process.env.ga4MeasurementId

        this.$gtag('js', new Date())
        this.$gtag('config', measurementId)

        this.$gtag('get', measurementId, 'client_id', googleClientId => {
          this.setGoogleClientId(googleClientId)
          // Setting this up to use in GTM
          window.googleClientId = googleClientId
          console.log('ClientId:', googleClientId)

          this.$gtag('get', measurementId, 'session_id', googleSessionId => {
            this.setGoogleSessionId(googleSessionId)
            console.log('sessionId:', googleSessionId)
            console.log('Analytics initialized.')
          })
        })
      } else if (this.analyticsCounter < 10) {
        this.analyticsCounter++
        setTimeout(this.setupGoogleAnalytics, 200)
      } else {
        console.warn('Analytics were not initialized.')
      }
    }
  },

  head() {
    const width = this.width
    const height = this.height
    const gutter = this.gutter
    const scrolling = this.scrolling
    const color = this.themeColor
    const { backgroundColors, textColor } = this.colors
    const shadowColor = this.shadowColor
    const semiopaqueColor = this.semiopaqueColor
    const transparentColor = this.transparentColor
    const page = this.$route.name
    const scheme = this.scheme
    const overlay = this.overlay
    const touch = this.touch
    const mobile = this.$device.isMobileOrTablet
    const webpSupport = this.webpSupport
    const isDev = process.env.isDev
    const classes = [`page--${page} scheme-${scheme}`]
    if (overlay) {
      classes.push('is-overlay')
      classes.push(`overlay--${overlay}`)
    }
    if (process.client) {
      if (touch) {
        classes.push('is-touch')
      } else {
        classes.push('is-not-touch')
      }
      if (mobile) {
        classes.push('is-mobile')
      }
      if (webpSupport === false) {
        classes.push('has-no-webp-support')
      } else {
        classes.push('has-webp-support')
      }
      if (scrolling) {
        classes.push('is-scrolling')
      }
      if (isDev) {
        classes.push('is-dev')
      }
    } else {
      classes.push('has-webp-support', 'is-not-touch')
    }
    const widthStyle =
      typeof width === 'string' || mobile ? '100vw' : `${width}px`
    let style = `--win-width: ${widthStyle}; --win-height: ${
      typeof height === 'string' ? height : `${height}px`
    };${gutter ? ` --x-gutter: ${gutter}px;` : ''} --background-color: ${
      backgroundColors[0]
    }; --text-color: ${textColor}; --shadow-color: ${shadowColor}; --semiopaque-color: ${semiopaqueColor}; --transparent-color: ${transparentColor};`

    if (backgroundColors.length > 1) {
      classes.push('has-gradient-background')

      // this is forcing the gradient to be fully visible at the top of the page.
      // The last gradient will then be the color of the rest of the page
      const gradientItems = backgroundColors.reduce((items, color, index) => {
        items.push(
          `${color} ${((index + 1) / backgroundColors.length) * height}px`
        )
        return items
      }, [])
      style += `--background-color-image: linear-gradient(${gradientItems.join(
        ', '
      )})`
    }

    const meta = [{ hid: 'color', name: 'theme-color', content: color }]
    if (this.nonCBDSite || this.noIndex) {
      meta.push({ hid: 'robots', name: 'robots', content: 'noindex, nofollow' })
    }

    return {
      meta,
      htmlAttrs: {
        lang: 'en',
        style,
        class: classes
      }
    }
  }
}
