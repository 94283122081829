/**
 * Returns an array of cart line inputs to for use in Storefront API
 * GraphQL mutations such as `cartLinesAdd`.
 *
 * @see {@link https://shopify.dev/docs/api/storefront/2024-04/input-objects/CartLineInput | CartLineInput}
 */
export default function formatLineItemInputs(lines) {
  return lines.map(({ node }) => ({
    merchandiseId: node.merchandise.id,
    quantity: node.quantity,
    attributes: node.attributes.map(a => ({
      key: a.key,
      value: a.value
    })),
    sellingPlanId: node.sellingPlanAllocation?.sellingPlan?.id
  }))
}
