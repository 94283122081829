<template>
  <div
    v-if="typeof visible === 'boolean'"
    :class="classes"
    class="cloud"
    aria-hidden="true"
  >
    <transition
      appear
      :css="false"
      @enter="enter"
      @leave="leave"
      @after-leave="afterLeave"
    >
      <div v-if="visible && !dismissed" class="cloud__wrap">
        <img
          ref="image"
          :src="src"
          alt
          :class="`cloud-bg-${image}`"
          class="cloud__primary"
        />
        <img
          v-for="i in 4"
          :key="`poof-${i}`"
          :src="src"
          alt
          :class="`cloud-bg-${image}`"
          class="cloud__poof"
        />
        <div class="cloud__poof-text">poof</div>
      </div>
    </transition>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { mapState } from 'vuex'
import { clouds } from '~/recess.json'

export default {
  name: 'Cloud',

  props: {
    image: {
      default: undefined,
      type: [String, Number]
    },
    distance: {
      default: undefined,
      type: [String, Number]
    },
    skyId: {
      default: undefined,
      type: Number
    },
    dismissed: {
      default: false,
      type: Boolean
    },
    visible: {
      default: undefined,
      type: Boolean
    },
    restartAfter: {
      default: 120000, // ms, defaults to 2 minutes
      type: [Number, String]
    },
    dismissable: {
      default: true,
      type: Boolean
    }
  },

  data() {
    return {
      sky: this.skyId,
      enterTimeline: null,
      leaveTimeline: null,
      restartTimer: null
    }
  },

  computed: {
    ...mapState('screen', {
      scroll: state => state.scroll,
      height: state => state.height,
      overlay: state => state.overlay
    }),
    classes() {
      const image = this.image
      const distance = this.distance
      return {
        [`cloud--${image}`]: image,
        [`cloud--distance-${distance}`]: distance,
        'cloud--dismissable': this.dismissable,
        'is-visible': this.visible,
        'is-dismissed': this.dismissed
      }
    },
    src() {
      const image = clouds.images[this.image - 1]
      let src
      if (image) {
        src = image.src
      }
      return src
    }
  },

  watch: {
    dismissed(dismissed) {
      if (dismissed && this.restartAfter) {
        this.restart()
      }
    },
    dismissable(dismissable) {
      if (dismissable) {
        this.bindDismissEvents()
      } else {
        this.unbindDismissEvents()
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.sky = this.skyId || this.$_uid
    })
    if (this.dismissable) {
      this.bindDismissEvents()
    }
  },

  beforeDestroy() {
    this.unbindDismissEvents()
    this.clearTimelines()
    this.clearRestartTimer()
  },

  methods: {
    restart() {
      this.clearRestartTimer()
      this.restartTimer = setTimeout(() => {
        this.$emit('dismiss', false)
      }, parseInt(this.restartAfter, 10))
    },
    clearRestartTimer() {
      if (this.restartTimer) {
        clearTimeout(this.restartTimer)
        this.restartTimer = null
      }
    },
    forceRestart() {
      this.clearRestartTimer()
      this.$emit('dismiss', false)
    },
    enter(el, done) {
      const vm = this
      const primary = el.querySelectorAll('.cloud__primary')
      this.enterTimeline = gsap.timeline({
        callbackScope: vm,
        onComplete: done,
        autoRemoveChildren: true,
        paused: true
      })
      this.enterTimeline.set(primary, {
        autoAlpha: 0,
        scale: 0.6
      })
      this.enterTimeline.to(primary, 1, {
        autoAlpha: 1,
        scale: 1,
        ease: 'back.inOut(1.7)'
      })
      this.enterTimeline.play()
    },
    leave(el, done) {
      if (!this.dismissed) {
        return
      }
      const vm = this
      const primary = el.querySelectorAll('.cloud__primary')
      const poof = el.querySelectorAll('.cloud__poof')
      const poofText = el.querySelectorAll('.cloud__poof-text')
      this.leaveTimeline = gsap.timeline({
        callbackScope: vm,
        onComplete: done,
        autoRemoveChildren: true,
        paused: true
      })
      this.leaveTimeline.set(primary, {
        autoAlpha: 1,
        filter: 'blur(0)',
        scaleX: 1,
        scaleY: 1
      })
      this.leaveTimeline.to(primary, {
        autoAlpha: 0.75,
        scaleY: 0.25,
        scaleX: 1.25,
        filter: 'blur(0.5rem)',
        duration: 0.33,
        ease: 'circ.in'
      })
      this.leaveTimeline.add('poof', '-=0.05')
      this.leaveTimeline.set(primary, {
        autoAlpha: 0
      })
      if (poof) {
        this.leaveTimeline.set(
          poof,
          {
            autoAlpha: 0.75,
            scale: 0.9,
            x(i) {
              return ['-50%', '-50%', '50%', '50%'][i]
            },
            y(i) {
              return ['50%', '-50%', '-50%', '50%'][i]
            }
          },
          'poof'
        )
        this.leaveTimeline.to(poof, {
          scale: 1.25,
          x(i) {
            return ['-100%', '-100%', '100%', '100%'][i]
          },
          y(i) {
            return ['100%', '-100%', '-100%', '100%'][i]
          },
          duration: 0.25,
          ease: 'circ.out'
        })
        this.leaveTimeline.to(
          poof,
          {
            autoAlpha: 0,
            duration: 0.25,
            delay: 0.05,
            ease: 'circ.out'
          },
          '-=0.2'
        )
      }
      if (poofText) {
        this.leaveTimeline.to(
          poofText,
          {
            keyframes: [
              { autoAlpha: 0, duration: 0 },
              { autoAlpha: 1, duration: 0.2 },
              { autoAlpha: 0, duration: 0.5 }
            ]
          },
          'poof'
        )
      }
      this.leaveTimeline.play()
    },
    afterLeave() {
      this.$emit('dismiss-complete')
    },
    bindDismissEvents() {
      document.addEventListener('mousedown', this.docClick, { passive: true })
      this.$bus.$on('cloudDismiss', this.cloudDismiss)
    },
    unbindDismissEvents() {
      document.removeEventListener('mousedown', this.docClick)
      this.$bus.$off('cloudDismiss', this.cloudDismiss)
    },
    docClick(e) {
      if (this.dismissed || !this.dismissable || this.overlay) {
        return
      }
      const image = this.$refs.image
      if (image) {
        const rect = image.getBoundingClientRect()
        const x = e.targetTouches ? e.targetTouches[0].pageX : e.pageX
        const y = e.targetTouches ? e.targetTouches[0].pageY : e.pageY
        const top = rect.top + this.scroll
        const right = rect.left + rect.width
        const left = rect.left
        const bottom = rect.top + rect.height + this.scroll
        const hit = x < right && x > left && y < bottom && y > top
        if (hit) {
          this.dismiss()
        }
      }
    },
    dismiss() {
      this.$emit('dismiss', true)
      this.$bus.$emit('cloudDismiss', this.sky)
    },
    /**
     * when another cloud with the same top position is
     * dismissed, dismiss it if it's not already
     * @function
     */
    cloudDismiss(dismissedSky) {
      const sameSky = this.sky === dismissedSky
      const dismissable = this.dismissable && !this.dismissed
      if (sameSky && dismissable) {
        this.$emit('dismiss', true)
      }
    },
    clearTimelines() {
      if (this.enterTimeline) {
        this.enterTimeline.seek(0)
        this.enterTimeline.clear()
      }
      if (this.leaveTimeline) {
        this.leaveTimeline.seek(0)
        this.leaveTimeline.clear()
      }
    }
  }
}
</script>

<style lang="scss">
/*! purgecss start ignore */

$cloud-images: 10;
$cloud-distance-incr: 20; // percentage increment
$cloud-distances: 4;

.cloud {
  @apply z-troposphere;
  --distance: #{$cloud-distance-incr}%;
  display: block;
  max-height: 100%;
  max-width: 100%;
  opacity: 0;
  position: relative;
  user-select: none;
  width: var(--distance);
}

.cloud.absolute {
  position: absolute;
}

.cloud.is-visible {
  opacity: 1;
}

.cloud.is-dismissed {
  pointer-events: none;
}

// increasingly larger ("closer") clouds and faster
// speeds give the illusion of distance
@for $distance from 1 to ($cloud-distances + 1) {
  .cloud--distance-#{$distance} {
    width: calc(var(--distance) * #{$distance});
  }
}

// create a little variety in the sizes between
// images at the furthest distance (1)
@for $cloud from 1 to ($cloud-images + 1) {
  .cloud--distance-1.cloud--#{$cloud} {
    --distance: #{$cloud-distance-incr + ($cloud * 2)}%;
  }
}

.cloud__wrap {
  position: relative;
  width: 100%;
}

.cloud__primary,
.cloud__poof {
  display: block;
  opacity: 0;
}

.cloud__primary {
  max-height: 100%;
  position: relative;
  visibility: hidden;
}

.cloud__poof {
  font-size: theme('fontSize.2xs');
  left: 50%;
  line-height: 1;
  margin-left: -12.5%;
  margin-top: -12.5%;
  position: absolute;
  top: 50%;
  visibility: hidden;
  width: 25%;
  z-index: 2;
}

.cloud__poof-text {
  display: inline-block;
  font-size: theme('fontSize.xs');
  font-weight: theme('fontWeight.bold');
  left: 50%;
  line-height: 1;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -100%);
  visibility: hidden;
  z-index: 3;
}

@media (hover) {
  .cloud--dismissable .cloud__wrap {
    transform-style: preserve-3d;
    transition: transform var(--hover-speed);
  }

  .cloud--dismissable:hover .cloud__wrap,
  [data-js-focus-visible]
    .cloud--dismissable:not(.is-dismissed)[data-focus-visible-added]
    .cloud__wrap {
    transform: scale(0.9);
  }
}

@screen md {
  .cloud__poof-text {
    font-size: theme('fontSize.sm');
  }
}

/*! purgecss end ignore */
</style>
