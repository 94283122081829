import { colors } from '~/recess.json'

const defaultBg = [colors['default-background']]
const defaultTxt = colors['default-text-color']

export default {
  setTouch(state, touch) {
    state.touch = touch
  },
  setWebmSupport(state, supports) {
    state.webmSupport = supports
  },
  setWebpSupport(state, supports) {
    state.webpSupport = supports
  },
  setScreenSize(state, { width, height }) {
    state.width = width
    state.height = height
  },
  setScroll(state, pos) {
    state.scroll = pos
  },
  setScrolling(state, scrolling) {
    state.scrolling = scrolling
  },
  setScrollTimer(state, id) {
    state.scrollTimer = id
  },
  setCursor(state, { x, y }) {
    state.cursorX = x
    state.cursorY = y
  },
  setCursorMoving(state, moving) {
    state.cursorMoving = moving
  },
  setCursorTimer(state, id) {
    state.cursorTimer = id
  },
  setGutter(state, size) {
    state.gutter = size
  },
  setGlobalBannerHeight(state, size) {
    state.globalBannerHeight = size
  },
  setHeaderHeight(state, height) {
    state.headerHeight = height
  },
  setPageColors(
    state,
    { backgroundColors = defaultBg, textColor = defaultTxt }
  ) {
    // ⚠️ page colors always need to be set!
    state.pageColors = {
      backgroundColors,
      textColor
    }
  },
  addSectionColors(state, colors) {
    state.sectionColors.unshift(colors)
  },
  removeSectionColors(state, colors) {
    const sectionColors = state.sectionColors
    state.sectionColors = sectionColors.filter(s => s.id !== colors.id)
  },
  flushSectionColors(state) {
    state.sectionColors = []
  },
  setOverlay(state, overlay) {
    state.overlay = overlay
    // submenu opened always gets wiped when setting overlay
    state.shopMenuSubmenuOpenedKey = null
  },
  setTransitioning(state, transitioning) {
    state.transitioning = transitioning
  },
  setShopMenuSubmenuOpenedKey(state, value) {
    state.shopMenuSubmenuOpenedKey = value
  },
  setBackgroundImages(state, value) {
    state.backgroundImages = value
  }
}
