<template>
  <form class="flex flex-col" autocomplete="off" @submit.prevent="handleSubmit">
    <div class="note-wrapper relative flex items-center">
      <textarea
        id="text"
        v-autogrow
        class="note-bubble relative"
        :value="text"
        required
        :maxlength="maxLength"
        :minLength="minLength"
        :placeholder="textPlaceholder"
        @input="textChange"
      />
      <img class="note-bubble__arrows" src="/bubble-arrows.svg" />
      <div class="note-characters-remaining">
        <p class="text-xs">characters remaining {{ charactersRemaining }}</p>
      </div>
    </div>

    <div class="self-center">
      <img class="gifting-can mt-2" src="/gift-can.png" />
    </div>

    <p class="my-em">
      <form-input
        id="email"
        class="p-1/2em my-1/2em"
        :value="email"
        name="recipient email"
        type="email"
        required
        :placeholder="emailPlaceholder"
        @input="emailChange"
      />
    </p>

    <div class="flex items-center gap-1 justify-start mt-em">
      <cta
        type="button"
        :shadow-color="getColor('blue')"
        :background-color="getColor('lavender')"
        :text-color="getColor('cloud-burst')"
        small
        @click="close"
      >
        Cancel
      </cta>
      <cta
        :shadow-color="getColor('blue')"
        :background-color="getColor('lavender')"
        :text-color="getColor('cloud-burst')"
        type="submit"
        small
      >
        Save
      </cta>
    </div>

    <div class="mt-6">
      <sanity-blocks :blocks="giftingNotice" />
    </div>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import FormInput from '~/components/form/input'

export default {
  name: 'GiftNoteEditor',
  components: { FormInput },
  data() {
    return {
      maxLength: 200,
      minLength: 1,
      emailPlaceholder: 'will@takearecess.com'
    }
  },
  computed: {
    ...mapState({
      text: state => state.shop.giftNote.text,
      email: state => state.shop.giftNote.email,
      giftingNotice: state => state.settings.cart.giftingNotice,
      textPlaceholder: state => state.settings.cart.giftNoteTextPlaceholder
    }),
    charactersRemaining() {
      return this.maxLength - this.text.length
    }
  },
  methods: {
    ...mapActions({
      close: 'shop/giftNoteEditorClose'
    }),

    textChange(e) {
      this.$store.dispatch('shop/giftNoteTextChange', e.target.value)
    },

    emailChange(e) {
      this.$store.dispatch('shop/giftNoteEmailChange', e.target.value)
    },

    handleSubmit() {
      this.$store.dispatch('shop/giftNoteSave')
    }
  },
  apollo: {
    $client: 'cart'
  }
}
</script>

<style scoped>
.note-wrapper {
  --foreground-color: #c3caff;
  --background-color: #482e96;
  --placeholder-color: #635296;
  --background-offset: 10px;
  --arrow-y-offset: 46px;
  color: var(--background-color);
  margin: 0 auto;
  margin-bottom: calc(var(--background-offset) + var(--arrow-y-offset));
  position: relative;
  width: 100%;
}

.note-characters-remaining {
  bottom: calc((var(--background-offset) + 2px) * -1);
  color: #3e415a;

  /* this is a hack to make any words wrap down instead of up */
  height: 0;

  left: 65%;
  max-width: 37%;
  position: absolute;
}

.note-bubble__arrows {
  bottom: calc(var(--arrow-y-offset) * -1);
  left: calc(50% - 20px);
  position: absolute;
}

.note-bubble {
  background-color: var(--foreground-color);
  border: 2px solid var(--background-color);
  border-radius: 8px;
  box-shadow: var(--background-color) var(--background-offset)
    var(--background-offset) 0;
  box-sizing: content-box;
  line-height: 2em;
  min-height: 80px;
  padding: theme('spacing.2');
  resize: none;
  width: 100%;
}

.note-bubble::placeholder {
  color: var(--placeholder-color);
}

.note-bubble:focus {
  outline: none;
}

.gifting-can {
  width: 100px;
}

@screen md {
  .gifting-can {
    width: 200px;
  }
}
</style>
