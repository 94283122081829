export default {
  title: 'Image',
  name: 'image',
  type: 'image',
  options: {
    metadata: ['image']
  },
  fields: [
    {
      title: 'No compression',
      name: 'noCompression',
      type: 'boolean'
    }
  ]
}
