<template functional>
  <div
    :ref="data.ref"
    :style="[data.style, data.staticStyle]"
    :class="[
      data.class,
      data.staticClass,
      { 'rich-text--standard': props.standard }
    ]"
    class="rich-text"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'RichText',

  functional: true,

  props: {
    altList: {
      default: false,
      type: Boolean
    },
    noBullets: {
      default: false,
      type: Boolean
    },
    standard: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style>
.rich-text {
  --bullet-gutter: 0.25em;
  --bullet-offset: calc(-1 * 0.25em);
  --bullet-width: 2em;
  line-height: theme('lineHeight.relaxed');
}

.rich-text--standard {
  @apply text-sm;
}

.rich-text > * {
  -webkit-margin-after: 0;
  -webkit-margin-before: 0;
  margin-top: 1.5rem;
}

.rich-text h1,
.rich-text h2,
.rich-text h3,
.rich-text h4,
.rich-text h5,
.rich-text h6 {
  font-weight: theme('fontWeight.medium');
  line-height: theme('lineHeight.tight');
  margin-bottom: 1rem;
  margin-top: 3rem;
}

.rich-text h3 {
  font-size: 1.5em;
}

.rich-text h4 {
  font-size: 1.125em;
}

.rich-text h1:first-child,
.rich-text h2:first-child,
.rich-text h3:first-child,
.rich-text h4:first-child,
.rich-text h5:first-child,
.rich-text h6:first-child {
  margin-bottom: 0;
}

.rich-text > *:first-child {
  margin-top: 0;
}

.rich-text .media-block,
.rich-text .gallery-block {
  margin-bottom: 3rem;
}

.rich-text ul:not([class]),
.rich-text ol {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  text-align: left;
}

.rich-text li:not([class]) {
  margin: 0;
  padding-bottom: 0.75rem;
  padding-left: calc(var(--bullet-width) + var(--bullet-gutter));
  position: relative;
}

.rich-text li:not([class])::before {
  left: 0;
  margin-left: var(--bullet-offset);
  position: absolute;
  text-align: right;
  width: var(--bullet-width);
}

.rich-text--no-bullets li:not([class])::before {
  display: none;
}

.rich-text--no-bullets li:not([class]) {
  padding-left: 0;
}

.rich-text ol {
  counter-reset: rich-counter;
}

.rich-text ol li {
  counter-increment: rich-counter;
}

.rich-text ol li::before {
  content: counter(rich-counter, decimal) '. ';
  font-weight: bold;
}

.rich-text ul:not([class]) li::before {
  @apply font-antidote;
}

.rich-text ul:not([class]) li:nth-child(odd)::before {
  content: '↑';
}

.rich-text ul:not([class]) li:nth-child(even)::before {
  content: '←';
}

.rich-text li:not([class]):first-child {
  margin-top: 0;
}

.rich-text a:not(.cta) {
  display: inline;
  font-weight: theme('fontWeight.medium');
  text-decoration: none;
  transition: color var(--hover-speed);
}

.rich-text a:not(.cta):hover,
[data-js-focus-visible] .rich-text a:not(.cta)[data-focus-visible-added] {
  color: var(--link-color);
  text-decoration: underline;
}

.rich-text strong,
.rich-text b {
  font-weight: theme('fontWeight.medium');
}

.rich-text.font-medium strong,
.rich-text.font-medium b {
  font-weight: theme('fontWeight.bold');
}

.rich-text em,
.rich-text i {
  font-style: italic;
}

.rich-text hr {
  border: 0;
  border-top: 1px solid theme('colors.cloud-burst');
  display: block;
  height: 0;
}

.rich-text blockquote {
  background-image: radial-gradient(
    ellipse at center,
    var(--background-color) 0%,
    transparent 50%
  );
  font-family: theme('fontFamily.antidote');
  font-size: 1.5em;
  line-height: theme('lineHeight.snug');
  margin-bottom: 2rem;
  margin-top: 1.5rem;
}

.rich-text blockquote > *:first-child::before {
  content: '“';
  display: inline;
}

.rich-text blockquote > *:last-child::after {
  content: '”';
  display: inline;
}

.rich-text small {
  font-size: 0.75em;
}

@screen sm {
  .rich-text--standard ul:not([class]),
  .rich-text--alt-list ul:not([class]),
  .rich-text--standard ol,
  .rich-text--alt-list ol {
    column-count: 2;
    column-fill: balance-all;
    column-gap: calc(var(--bullet-gutter) * 4);
  }

  .rich-text--standard li:not([class]),
  .rich-text--alt-list li:not([class]) {
    break-inside: avoid;
  }

  .rich-text--standard {
    @apply text-base;
  }
}

@screen md {
  .rich-text {
    --bullet-gutter: 0.75em;
    --bullet-width: 2em;
  }

  .rich-text h3 {
    font-size: 1.75em;
  }

  .rich-text h4 {
    font-size: 1.286em;
  }

  .rich-text blockquote {
    font-size: 2em;
  }
}

@screen lg {
  .rich-text--standard {
    @apply text-lg;
  }
}

@screen 2xl {
  .rich-text h3 {
    font-size: 2em;
  }

  .rich-text h4 {
    font-size: 1.429em;
  }
}
</style>
