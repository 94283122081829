<template functional>
  <small><slot /></small>
</template>

<script>
export default {
  name: 'SanitySmall',

  functional: true
}
</script>
