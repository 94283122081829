export default {
  title: 'Link',
  name: 'link',
  type: 'object',
  fields: [
    {
      title: 'CBD',
      name: 'cbd',
      type: 'boolean',
      description:
        'If CBD is enabled, this record will not be shown on the non-CBD storefront.'
    },
    {
      title: 'Title',
      name: 'title',
      type: 'string'
    },
    {
      title: 'Page',
      name: 'page',
      type: 'reference',
      to: [{ type: 'page' }]
    },
    {
      title: 'URL',
      name: 'url',
      type: 'url',
      validation: Rule => Rule.uri({ allowRelative: true })
    },
    {
      title: 'Open this link in a new window',
      name: 'external',
      type: 'boolean'
    }
  ],
  validation: Rule =>
    Rule.custom((value = {}) => {
      let valid = true
      const page = value.page
      if (!page) {
        return valid
      }
      const url = value.url
      const externalLink = value.external
      const pageAndUrl = page !== undefined && url !== undefined
      const pageAndExternal = page !== undefined && externalLink
      if (pageAndUrl) {
        valid = "Unset URL (page's URL will be determined automatically)"
      } else if (pageAndExternal) {
        valid = 'Page does not exist outside of the site'
      }
      return valid
    }),
  preview: {
    select: {
      title: 'title',
      pageTitle: 'page.title'
    },
    prepare: ({ title, pageTitle }) => ({
      title: title || pageTitle || 'New Page'
    })
  }
}
