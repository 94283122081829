<template>
  <div
    :is="tag"
    v-observe-visibility="visibilityChange"
    v-bind="$attrs"
    :class="classes"
    class="float"
    @mouseover="play"
    @mouseleave="stop"
    @focus.prevent="play"
    @blur="stop"
    @click="$emit('click', $event)"
  >
    <slot />
  </div>
</template>

<script>
import isTagLink from '~/util/is-tag-link'

export default {
  name: 'Float',

  props: {
    tag: {
      default: 'div',
      type: String
    },
    speed: {
      default: 5, // seconds
      type: [String, Number]
    },
    offset: {
      default: '-5%',
      type: String
    },
    reverse: {
      default: false,
      type: Boolean
    },
    shadow: {
      default: false,
      type: Boolean
    },
    onHover: {
      default: false,
      type: Boolean
    },
    stopped: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      isLink: isTagLink(this.tag),
      paused: false
    }
  },

  computed: {
    classes() {
      return {
        'is-link': this.isLink,
        'is-reverse': this.reverse,
        'is-shadowed': this.shadow,
        'is-paused': this.paused,
        'is-onhover': this.onHover,
        'is-stopped': this.stopped
      }
    }
  },

  watch: {
    speed() {
      this.resize()
    },
    offset() {
      this.resize()
    }
  },

  mounted() {
    this.$nextTick(this.resize)
    this.$bus.$on('resize', this.resize)
  },

  beforeDestroy() {
    this.$bus.$off('resize', this.resize)
  },

  methods: {
    resize() {
      const el = this.$el
      const speed = parseInt(this.speed, 10)
      const offset = this.offset
      if (el && el.style) {
        el.style.setProperty('--float-duration', `${speed}s`)
        el.style.setProperty('--float-offset', offset)
      }
    },
    visibilityChange(visible) {
      if (!this.isLink) {
        this.$emit('update:stopped', !visible)
      }
    },
    play() {
      this.paused = false
      if (this.stopped) {
        this.$emit('update:stopped', false)
      }
    },
    pause() {
      this.paused = true
    },
    stop() {
      if (!this.stopped) {
        this.$emit('update:stopped', true)
      }
    }
  }
}
</script>

<style>
@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(var(--float-offset));
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes float-shadowed {
  0% {
    box-shadow: 0 8px 0.5px -8px rgba(0, 0, 0, 0.3);
    transform: translateY(0);
  }

  50% {
    box-shadow: 0 8px 0.5px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(var(--float-offset));
  }

  100% {
    box-shadow: 0 8px 0.5px -8px rgba(0, 0, 0, 0.3);
    transform: translateY(0);
  }
}

.float {
  transform-origin: center;
  transform-style: preserve-3d;
}

.is-active .float,
button:hover .float.is-onhover,
button[data-focus-visible-added] .float.is-onhover,
a:hover .float.is-onhover,
a[data-focus-visible-added] .float.is-onhover,
.float:not(.is-stopped, .is-onhover),
.float.is-onhover:hover:not(.is-stopped),
.float[data-focus-visible-added]:not(.is-stopped, .is-onhover),
.float.is-onhover[data-focus-visible-added] {
  animation: float var(--float-duration) ease-in-out infinite;
}

.float.is-shadowed {
  animation-name: float-shadowed !important;
}

.float.is-reverse {
  animation-direction: reverse !important;
}

.float.is-paused {
  animation-play-state: paused !important;
}

.float.is-link {
  display: inline-block;
}

.float.block {
  display: block;
}

.float.flex {
  display: flex;
}
</style>
