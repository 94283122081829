<template>
  <div :class="classes" class="flipper">
    <transition name="flip">
      <span
        :key="`flip-${valueDisplay}`"
        :style="valueStyle"
        class="flipper__value"
        >{{ valueDisplay }}</span
      >
    </transition>
    <span class="flipper__sizer" aria-hidden="true">{{ valueDisplay }}</span>
  </div>
</template>

<script>
const defaultSpeed = 500 // ms, ensure this matches the css

export default {
  name: 'Flipper',

  props: {
    value: {
      default: null,
      type: [Number, String],
      required: true
    },
    speed: {
      default: defaultSpeed,
      type: [Number, String]
    },
    max: {
      default: null,
      type: Number
    }
  },

  data() {
    return {
      isNumber: false,
      reverse: false
    }
  },

  computed: {
    valueStyle() {
      const style = {}
      const speed = parseInt(this.speed, 10)
      if (speed !== defaultSpeed) {
        style.transitionDuration = `${speed}ms`
      }
      return style
    },
    classes() {
      return {
        'is-reverse': this.reverse
      }
    },
    valueOverMax() {
      const value = this.value
      const max = this.max
      const isNumber = !isNaN(value)
      if (isNumber && max) {
        return value > max
      } else {
        return false
      }
    },
    valueDisplay() {
      const value = this.value
      const overMax = this.valueOverMax
      let display = value
      if (overMax) {
        display = this.max
      }
      return display
    }
  },

  watch: {
    value(newValue, oldValue) {
      const isNumber = !isNaN(newValue)
      if (isNumber && oldValue !== null) {
        this.reverse = newValue < oldValue
      } else {
        this.reverse = false
      }
    }
  }
}
</script>

<style>
.flipper,
.flipper__value {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
}

.flipper {
  --flipper-width: 1.75rem;
  height: 100%;
  min-height: 1em;
  min-width: var(--flipper-width);
  overflow: hidden;
  position: relative;
}

.flipper__value {
  left: 0;
  position: absolute;
  text-align: inherit;
  top: 0;
  transition: top var(--hover-speed), opacity var(--hover-speed);
  width: 100%;
}

.flipper__sizer {
  visibility: hidden;
}

.is-reverse .flip-leave-to,
.flip-enter {
  opacity: 0;
  top: 100%;
}

.flip-enter-to {
  opacity: 1;
  top: 0;
}

.is-reverse .flip-enter,
.flip-leave-to {
  opacity: 0;
  top: -100%;
}
</style>
