<template>
  <div></div>
</template>

<script>
export default {
  created() {
    this.$router.replace('/404')
  }
}
</script>
