import { Storage } from '~storage'

export default function nuxtUniversalStorage(ctx, inject) {
  let options = {
  "vuex": {
    "namespace": "storage"
  },
  "cookie": false,
  "localStorage": {
    "prefix": "recess-production-"
  },
  "ignoreExceptions": false,
  "initialState": {
    "cartId": null
  }
}
  const cookie = false

  options = {
    ...options,
    cookie
  }

  // Create new instance of Storage class
  const storage = new Storage(ctx, options)

  // Inject into context as $storage
  ctx.$storage = storage
  inject('storage', storage)
}
