export default () => {
  let _pix = document.getElementById(
    '_pix_id_d8fa6bc1-8325-4e4f-f62a-2d40b80730a6'
  )
  if (!_pix) {
    const protocol = '//'
    const a = Math.random() * 1000000000000000000
    _pix = document.createElement('iframe')
    _pix.style.display = 'none'
    _pix.setAttribute(
      'src',
      protocol +
        's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3Dd8fa6bc1-8325-4e4f-f62a-2d40b80730a6%26type%3D55%26m%3D1&ex-fch=416613&ex-src=https://www.takearecess.com/&ex-hargs=v%3D1.0%3Bc%3D4133329570701%3Bp%3DD8FA6BC1-8325-4E4F-F62A-2D40B80730A6' +
        '&cb=' +
        a
    )
    _pix.setAttribute('id', '_pix_id_d8fa6bc1-8325-4e4f-f62a-2d40b80730a6')
    document.body.appendChild(_pix)
  }
}
