<template>
  <div
    v-observe-visibility="visibilityOptions"
    :class="classes"
    class="marquee"
  >
    <div
      v-if="contentLength && Number.isFinite(contentLength)"
      class="marquee__wrap"
    >
      <span
        v-for="i in contentLength"
        :key="`repeat-${i}`"
        ref="items"
        :style="{ ...scrollStyle, ...gutterStyle }"
        class="marquee__scroll"
      >
        <slot :visible="visible" />
      </span>
    </div>
  </div>
</template>

<script>
import imagesLoaded from 'imagesloaded'
import { mapState } from 'vuex'

export default {
  props: {
    vertical: {
      default: false,
      type: Boolean
    },
    speed: {
      default: 12,
      type: [Number, String]
    },
    fixedSpeed: {
      default: false,
      type: Boolean
    },
    gutter: {
      default: 0,
      type: [Number, String]
    },
    reverse: {
      default: false,
      type: Boolean
    },
    // if static scroll width is not calculated
    // and the marquee content is visible by default
    static: {
      default: false,
      type: Boolean
    },
    paused: {
      default: false,
      type: Boolean
    },
    // v-observe-visibility options
    visibility: {
      default: () => ({}),
      type: [Boolean, Object]
    }
  },

  data() {
    return {
      contentLength: 2,
      inlineSize: 0,
      loaded: this.static,
      visible: true,
      render: 0
    }
  },

  computed: {
    ...mapState({
      transitioning: state => state.screen.transitioning
    }),
    classes() {
      return {
        'is-vertical': this.vertical,
        'is-paused': this.paused || !this.visible,
        'is-reverse': this.reverse,
        'is-static': this.static,
        'is-loaded': this.loaded
      }
    },
    scrollDuration() {
      if (this.fixedSpeed) {
        return this.speed
      } else if (this.inlineSize) {
        return Math.round(this.inlineSize / this.speed)
      }
      return undefined
    },
    scrollStyle() {
      return {
        animationDuration: `${this.scrollDuration}s`
      }
    },
    gutterStyle() {
      return {
        paddingRight: `${this.gutter * 0.25}em`
      }
    },
    visibilityOptions() {
      if (this.transitioning || this.visibility === false) {
        return false
      }
      return {
        throttle: 300,
        leading: 'hidden',
        callback: this.visibilityChange,
        ...this.visibility
      }
    }
  },

  mounted() {
    this.$nextTick(this.refresh)
    this.$bus.$on('resize', this.resize)
  },

  beforeDestroy() {
    this.$bus.$off('resize', this.resize)
  },

  methods: {
    async refresh() {
      const content = this.$refs.items[0]
      await new Promise(resolve => imagesLoaded(content, resolve))

      const containerInlineSize = this.vertical
        ? this.$el.clientHeight
        : this.$el.clientWidth
      const contentInlineSize = this.vertical
        ? content.clientHeight
        : content.clientWidth

      this.inlineSize = containerInlineSize

      if (contentInlineSize && contentInlineSize < containerInlineSize) {
        this.contentLength =
          Math.ceil(containerInlineSize / contentInlineSize) + 2
      } else {
        this.contentLength = 2
      }

      this.loaded = true
    },
    visibilityChange(visible) {
      this.visible = visible
    },
    resize({ sameWidth }) {
      if (sameWidth) {
        return
      }
      this.refresh()
    }
  }
}
</script>

<style>
.marquee {
  contain: content;
  content-visibility: auto;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  user-select: none;
  white-space: nowrap;
}

.marquee.is-vertical {
  max-height: 100%;
  max-width: none;
}

.marquee.absolute {
  position: absolute;
}

.marquee__wrap {
  display: flex;
  flex-wrap: nowrap;
  opacity: 0;
  transition: opacity var(--fade-speed);
}

.is-vertical .marquee__wrap {
  flex-direction: column;
}

.is-static .marquee__wrap,
.is-loaded .marquee__wrap {
  opacity: 1;
}

.is-static .marquee__wrap {
  transition: none;
}

@keyframes scroll-horizontal {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes scroll-vertical {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -100%, 0);
  }
}

.marquee__scroll {
  align-items: flex-start;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: scroll-horizontal;
  animation-timing-function: linear;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-start;
  transform-style: preserve-3d;
}

.is-vertical .marquee__scroll {
  animation-name: scroll-vertical;
}

.is-paused .marquee__scroll {
  animation-play-state: paused;
}

.is-reverse .marquee__scroll {
  animation-direction: reverse;
}
</style>
