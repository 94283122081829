import { render, staticRenderFns } from "./scrolling-banner-text.vue?vue&type=template&id=efd5fe26&"
import script from "./scrolling-banner-text.vue?vue&type=script&lang=js&"
export * from "./scrolling-banner-text.vue?vue&type=script&lang=js&"
import style0 from "./scrolling-banner-text.vue?vue&type=style&index=0&id=efd5fe26&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports