/*
For some reason, the Admin GraphQL Shopify API returns Money type for prices (so "39.99")
while the Storefront API returns MoneyV2 ({amount: "39.99"}). We need to
convert those Money (v1) to MoneyV2.
*/
export const formatProductVariants = variants => {
  return {
    edges: variants.map(({ node }) => ({
      node: {
        ...node,
        id: node.id,
        sku: node.sku,
        price: {
          amount: node.price,
          __typename: 'MoneyV2'
        },
        compareAtPrice: {
          amount: node.compareAtPrice,
          __typename: 'MoneyV2'
        },
        quantityAvailable: node.inventoryQuantity
      }
    }))
  }
}

export const formatProduct = product => {
  const variants = formatProductVariants(product.variants.edges)

  return {
    ...product,
    id: product.id,
    variants
  }
}

export default (edges = []) => {
  return edges.map(({ node }) => formatProduct(node))
}
