/*
For some reason, the Admin GraphQL Shopify API returns Money type for prices (so "39.99")
while the Storefront API returns MoneyV2 ({amount: "39.99"}). We need to convert
those Money (v1) to MoneyV2.
*/
export const formatSubscriptionVariants = variants => {
  return {
    edges: variants.map(({ node }) => ({
      node: {
        ...node,
        price: {
          amount: node.price,
          __typename: 'MoneyV2'
        },
        compareAtPrice: {
          amount: node.compareAtPrice,
          __typename: 'MoneyV2'
        },
        quantityAvailable: node.inventoryQuantity
      }
    }))
  }
}

function formatSellingPlans(subscription) {
  const sellingPlanGroups = subscription?.sellingPlanGroups?.edges || []
  const sellingPlans = sellingPlanGroups.flatMap(o =>
    (o.node.sellingPlans?.edges || [])
      .filter(({ node }) => !!node?.deliveryPolicy?.intervalCount)
      .map(({ node }) => ({
        ...node,
        interval: node.deliveryPolicy.interval,
        intervalCount: node.deliveryPolicy.intervalCount
      }))
  )
  return sellingPlans
}

export const formatSubscription = subscription => {
  const variants = formatSubscriptionVariants(subscription.variants.edges)
  const sellingPlans = formatSellingPlans(subscription)
  return {
    ...subscription,
    id: subscription.id,
    variants,
    sellingPlans
  }
}

export default (edges = []) => {
  return edges
    .map(({ node }) => formatSubscription(node))
    .filter(o => o.sellingPlans.length > 0)
}
