export default stringNumber => {
  try {
    // Parsing null returns NaN so instead we return
    // what was passed.
    if (!stringNumber) {
      return stringNumber
    }

    return parseFloat(stringNumber).toFixed(2)
  } catch (error) {
    return stringNumber
  }
}
