import wait from 'waait'
import eventBus from '~/plugins/event-bus'

const fitEvent = new CustomEvent('fit', { bubbles: true })

const fit = async el => {
  await wait(500)
  const ctx = el.fitText
  if (ctx.disabled) {
    el.style.opacity = '1'
    el.style.fontSize = ''
    el.style.transition = ''
    return
  }
  const minNum = parseFloat(ctx.min)
  const maxNum = parseFloat(ctx.max)
  let size = el.clientWidth / (ctx.ratio * 10)
  el.style.opacity = '0'
  if (maxNum) {
    size = Math.min(size, maxNum)
  }
  if (minNum) {
    size = Math.max(size, minNum)
  }
  el.style.fontSize = `${size}px`
  // force repaint to ensure correct sizes
  await new Promise(resolve => requestAnimationFrame(resolve))
  el.style.opacity = '1'
  el.dispatchEvent(fitEvent)
}

const defaults = {
  ratio: 1,
  min: null,
  max: null,
  disabled: false
}

const updateOptions = (el, opts = 1) => {
  if (typeof opts === 'object' && opts !== null) {
    el.fitText = {
      ...defaults,
      ...opts
    }
  } else if (typeof opts === 'number') {
    el.fitText = {
      ...defaults,
      ...{
        ratio: opts
      }
    }
  }
}

export const FitText = {
  inserted(el, binding) {
    el.style.opacity = '0'
    el.style.transition = 'opacity 0.25s'
    updateOptions(el, binding.value)
    el.fitTextResize = () => fit(el)
    eventBus.$on('resize', el.fitTextResize)
    fit(el)
  },
  update(el, binding) {
    updateOptions(el, binding.value)
    fit(el)
  },
  unbind(el) {
    eventBus.$off('resize', el.fitTextResize, { passive: true })
  }
}

export default function(Vue) {
  Vue.directive('FitText', FitText)
}
