export default string => {
  const strArr = string.split(' ')
  if (strArr.length > 3) {
    // drop the last 2 words and store it
    const last2 = strArr.splice(-2, 2)
    // join the text back and if it has more than 1 word
    // add the span tag to the last 2 words
    return (
      strArr.join(' ') +
      (strArr.length > 1
        ? ` <span class="inline-block">${last2.join(' ')}</span>`
        : last2.join(' '))
    )
  }
  return strArr.join(' ')
}
