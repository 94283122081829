<template functional>
  <div class="ordered-list container container--narrow text-center">
    <ol>
      <slot />
    </ol>
  </div>
</template>

<script>
export default {
  name: 'SanityOrderedList',

  functional: true
}
</script>
