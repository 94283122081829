<template>
  <li class="list-item"><slot /></li>
</template>

<script>
export default {
  name: 'ListItem'
}
</script>

<style scoped>
.list-item {
  @apply leading-tight;
  font-size: var(--menu-size);
  font-weight: theme('fontWeight.medium');
  padding: var(--menu-gutter) 0;
}
</style>
