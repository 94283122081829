import { ApolloClient, HttpLink, InMemoryCache, useQuery } from '@apollo/client'
import { useActiveWorkspace } from 'sanity'

import getEnv from '../lib/getEnv'

function createClient(dataset) {
  let env

  // Sanity is being used within Nuxt
  if (window?.$nuxt?.$config) {
    env = window.$nuxt.$config.sanityEnv
    // Sanity is being used on its own
  } else {
    env = getEnv(process.env)
  }

  const config = env.shopify[dataset] || env.shopify.development
  const headers = { 'X-Shopify-Storefront-Access-Token': config.token }
  return new ApolloClient({
    link: new HttpLink({ headers, uri: config.uri }),
    cache: new InMemoryCache()
  })
}

const getClient = (() => {
  const clients = new Map()
  return ({ dataset }) => {
    if (!clients.has(dataset)) {
      clients.set(dataset, createClient(dataset))
    }
    return clients.get(dataset)
  }
})()

export default function useShopifyQuery(query, options = {}) {
  const { activeWorkspace } = useActiveWorkspace()
  const client = getClient(activeWorkspace)
  const result = useQuery(query, { ...options, client })
  return result
}
