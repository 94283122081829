import { IoIosChatbubbles } from 'react-icons/io'
import inlineEditor from './blocks/inline-editor'
import linkField from './fields/link'

export default {
  title: 'Testimonials',
  name: 'testimonial',
  type: 'document',
  icon: IoIosChatbubbles,
  fields: [
    {
      title: 'Attribution',
      name: 'attribution',
      type: 'string'
    },
    {
      ...inlineEditor,
      title: 'Quote',
      name: 'quote',
      description: 'Without quotation marks'
    },
    {
      ...linkField,
      title: 'Optional Link',
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      title: 'Product Categories',
      name: 'productCategories',
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [{ type: 'productCategory' }]
        }
      ]
    }
  ],
  preview: {
    select: {
      title: 'attribution'
    }
  }
}
