<template>
  <button
    :is="tag"
    v-bind="$attrs"
    :class="[isLink ? 'button--link' : '', 'button']"
    :style="{ '--button-background-color': backgroundColor }"
    v-on="$listeners"
  >
    <slot />
  </button>
</template>

<script>
import { colors } from '~/recess.json'

export default {
  name: 'FormButton',
  props: {
    tag: {
      default: 'button',
      type: String
    },
    isLink: {
      default: false,
      type: Boolean
    },
    backgroundColor: {
      default: colors.lavender,
      type: String
    }
  }
}
</script>

<style scoped>
.button {
  align-items: center;
  background: var(--button-background-color);
  border: var(--input-border);
  border-radius: var(--input-border-radius);
  display: flex;
  font-weight: theme('fontWeight.medium');
  height: var(--input-height);
  justify-content: center;
  padding: var(--input-gutter);
  text-align: center;
  text-transform: uppercase;
}

.button--link {
  background: none;
  border: none;
  text-decoration: underline;
  text-transform: lowercase;
}
</style>
