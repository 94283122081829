<template functional>
  <div
    :ref="data.ref"
    :class="[data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    class="window"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <div class="window__top">
      <i v-for="i in 3" :key="`window-button-${i}`" class="window__button" />
      <slot name="window-top" />
    </div>
    <div class="window__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'WindowFrame',
  functional: true
}
</script>

<style>
.window {
  --button-size: 8px;
  --button-gutter: 3px;
  --edge-size: 4px;
  background-color: theme('colors.gray');
  display: flex;
  flex-direction: column;
  padding: var(--edge-size);
}

.window__top {
  align-items: center;
  border-bottom: 1px solid theme('colors.dark-gray');
  box-sizing: content-box;
  display: flex;
  flex: 0 1 auto;
  flex-wrap: nowrap;
  padding-bottom: var(--edge-size);
  padding-top: calc(var(--edge-size) - var(--button-gutter));
}

.window__button {
  background-color: theme('colors.dark-gray');
  border-radius: 50%;
  display: block;
  height: var(--button-size);
  margin: var(--button-gutter);
  width: var(--button-size);
}

.window__content {
  background-color: theme('colors.white');
  flex: 1 1 auto;
  height: 100%;
  position: relative;
}

@screen sm {
  .window {
    --button-size: 14px;
    --button-gutter: 4px;
    --edge-size: 5px;
  }
}
</style>
