import random from 'lodash/random'
import sample from 'lodash/sample'
import { clouds } from '~/recess.json'

// image and "distance" can be set or randomized
const imageCount = clouds.images.length
const distanceCount = clouds.distances
const driftColumns = 6
const driftSpeedMin = 1
const driftSpeedMax = 6

export const randomCloudImage = () => {
  return random(1, imageCount)
}

export const randomCloudDistance = distances => {
  if (distances && Array.isArray(distances)) {
    return sample(distances)
  }
  return random(1, distanceCount)
}

export const randomCloudSpeed = (distance = 1) => {
  return Math.max(
    Math.min(random(driftSpeedMin * distance, driftSpeedMax * distance), 12),
    3
  )
}

export const randomCloudColumn = () => {
  return random(1, driftColumns)
}
