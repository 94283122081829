<template>
  <div
    v-if="typeof webmSupport === 'boolean'"
    :class="classes"
    class="can-spinner"
  >
    <video
      v-if="webmSupport && !noAutoplay"
      ref="video"
      width="213"
      height="320"
      muted
      loop
      playsinline
      class="can-spinner__video"
    >
      <source src="/video/spinner.mp4" type="video/mp4;codecs=hvc1" />
      <source src="/video/spinner.webm" type="video/webm" />
    </video>
    <img
      v-else
      src="~/assets/images/spinner.png"
      alt="loading..."
      class="can-spinner__image"
    />
  </div>
</template>

<script>
import wait from 'waait'
import { mapState } from 'vuex'

export default {
  name: 'CanSpinner',

  data: () => ({
    loading: false,
    noAutoplay: false
  }),

  computed: {
    ...mapState('screen', {
      webmSupport: state => state.webmSupport
    }),

    classes() {
      return {
        'is-loading': this.loading
      }
    }
  },

  methods: {
    start() {
      this.loading = true
      const video = this.$refs.video
      if (!video || !this.webmSupport) {
        return
      }
      const promise = video.play()
      // some browsers don't return promise
      if (promise !== undefined) {
        promise.catch(() => {
          this.noAutoplay = true
        })
      }
    },
    async finish() {
      await wait(500)
      this.loading = false
      await wait(250)
      if (this.loading) {
        // loading may have occurred again at this point
        return
      }
      const video = this.$refs.video
      if (!video || !this.webmSupport) {
        return
      }
      video.pause()
    }
  }
}
</script>

<style>
.can-spinner {
  bottom: var(--x-gutter);
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: var(--x-gutter);
  transition: opacity var(--hover-speed);
  width: theme('width.16');
  z-index: theme('zIndex.spinner');
}

.can-spinner.is-loading {
  opacity: 1;
}

.can-spinner__video,
.can-spinner__image {
  display: block;
  height: auto;
  width: 100%;
}

@screen md {
  .can-spinner {
    bottom: 2rem;
    right: 2rem;
    width: theme('width.24');
  }
}
</style>
