import transformRebuyResponse from '~/util/rebuy/transform-rebuy-response'
import extractShopifyId from '~/util/shopify/extract-shopify-id'

async function fetchRebuyProducts({
  dataSourceId,
  productIds = [],
  variantIds = []
}) {
  const url = new URL(`${process.env.rebuyApiEndpoint}/${dataSourceId}`)

  url.searchParams.set('key', process.env.rebuyApiKey)

  if (productIds.length > 0) {
    url.searchParams.set(
      'shopify_product_ids',
      productIds.map(id => extractShopifyId(id)).join(',')
    )
  }

  if (variantIds.length > 0) {
    url.searchParams.set(
      'shopify_variant_ids',
      variantIds.map(id => extractShopifyId(id)).join(',')
    )
  }

  const options = {
    headers: { 'Content-Type': 'application/json' },
    redirect: 'follow',
    method: 'GET'
  }

  const response = await fetch(url, options)
  const data = await response.json()
  return transformRebuyResponse(data)
}

export default fetchRebuyProducts
