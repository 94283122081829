<template functional>
  <span class="xl:text-xl 2xl:text-2xl"><slot /></span>
</template>

<script>
export default {
  name: 'SanityLargeText',

  functional: true
}
</script>
