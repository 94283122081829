<template functional>
  <div class="testimonials-block container py-y-gutter">
    <testimonials :testimonials="props.testimonials" />
  </div>
</template>

<script>
export default {
  name: 'SanityTestimonialsBlock',

  functional: true,

  props: {
    testimonials: {
      default: () => [],
      type: Array
    }
  }
}
</script>
