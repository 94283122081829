import { render, staticRenderFns } from "./hero-gallery-thumbnail.vue?vue&type=template&id=7b9b7c06&"
import script from "./hero-gallery-thumbnail.vue?vue&type=script&lang=js&"
export * from "./hero-gallery-thumbnail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports