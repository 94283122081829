import { AiFillInfoCircle } from 'react-icons/ai'
import imageField from './fields/image'
import linkField from './fields/link'

export default {
  title: 'Where to buy',
  name: 'whereToBuy',
  type: 'document',
  icon: AiFillInfoCircle,
  __experimental_actions: ['update', 'publish'],
  fields: [
    {
      title: 'Banners',
      name: 'banners',
      type: 'array',
      of: [
        {
          title: 'Banner',
          name: 'banner',
          type: 'object',
          fields: [
            {
              title: 'Enabled',
              name: 'enabled',
              type: 'boolean',
              validation: Rule => Rule.required()
            },
            {
              title: 'Content',
              name: 'content',
              type: 'string',
              validation: Rule => Rule.required(),
              description:
                'By default, the whole text will be turned into a link (if a page or URL is defined below). Use double accolades to only turn a specific part into a link. Example: "To check this out, {{click here}}!".'
            },
            imageField,
            linkField
          ]
        }
      ]
    }
  ]
}
