import groq from 'groq'
import pageFields from '../fields/page'
import productFields from '../fields/product'

export default groq`
  *[_type == "featuredProduct"][0].product->{
    ...${pageFields},
    ...${productFields},
    description,
  }
`
