import { IoIosChatbubbles } from 'react-icons/io'
import { TestimonialsBlockPreview } from '../../components'

export default {
  title: 'Testimonials',
  name: 'testimonials',
  type: 'object',
  fields: [
    {
      title: 'Testimonials',
      name: 'testimonials',
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [{ type: 'testimonial' }]
        }
      ]
    }
  ],
  preview: {
    select: {
      testimonials: 'testimonials'
    }
  },
  components: {
    preview: TestimonialsBlockPreview
  },
  icon: IoIosChatbubbles
}
