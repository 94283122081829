<template>
  <section :is="tag" v-bind="$attrs" class="section" :class="classes">
    <slot name="top">
      <div v-if="title || subtitle" class="container">
        <header class="section__header">
          <h2 class="section__title heading">
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-if="titleHtml"
              class="section__title-text"
              v-html="titleHtml"
            />
            <!-- eslint-enable vue/no-v-html -->
            <div v-if="hasTitleImages">
              <div
                v-for="(image, i) in titleImages.slice(0, 2)"
                :key="`image-${i}`"
                class="section__title-image"
              >
                <float :offset="i % 2 ? '-15%' : '-10%'" :speed="i % 2 ? 8 : 5">
                  <sanity-media :image="image" />
                </float>
              </div>
            </div>
            <slot name="title" />
          </h2>
          <div class="section__subtitle heading--sm">
            <h3 v-if="subtitle">
              {{ subtitle }}
            </h3>
            <slot name="header" />
          </div>
        </header>
      </div>
    </slot>
    <div class="section__content">
      <div>
        <slot />
      </div>
    </div>
    <slot name="bottom" />
    <div v-if="cta" class="text-center mt-y-gutter lg:mt-y-gutter-sm">
      <cta tag="recess-link" v-bind="cta" :sanity-link="cta" fill>
        {{ ctaTitle || cta.title || (cta.page ? cta.page.title : '') }}
      </cta>
    </div>
  </section>
</template>

<script>
import preventOrphanedWord from '~/util/text/prevent-orphaned-word'

export default {
  name: 'PageSection',

  props: {
    tag: {
      default: 'section',
      type: String
    },
    title: {
      default: null,
      type: String
    },
    subtitle: {
      default: null,
      type: String
    },
    titleImages: {
      default: () => [],
      type: Array
    },
    cta: {
      default: undefined,
      type: Object
    },
    ctaTitle: {
      default: undefined,
      type: String
    },
    short: {
      default: false,
      type: Boolean
    },
    fullHeight: {
      default: false,
      type: Boolean
    },
    centered: {
      default: false,
      type: Boolean
    }
  },

  computed: {
    classes() {
      return {
        'section--title-images': this.hasTitleImages,
        'section--short': this.short,
        'section--full-height': this.fullHeight,
        'section--centered': this.centered
      }
    },
    titleHtml() {
      const title = this.title
      if (!title) {
        return
      }
      return preventOrphanedWord(title)
    },
    hasTitleImages() {
      const images = this.titleImages
      return images && images.length > 0
    }
  }
}
</script>

<style>
.section {
  @apply py-y-gutter;
  --section-title-image-size: 3em;
  --section-title-image-gutter: var(--x-gutter-sm);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: var(--section-min-h);
  position: relative;
}

.section.pt-0 {
  padding-top: 0;
}

.section.pb-0 {
  padding-bottom: 0;
}

.section.py-0 {
  padding-bottom: 0;
  padding-top: 0;
}

.section--short {
  @apply py-y-gutter-sm;
  min-height: 0;
}

.section--full-height {
  --section-min-h: var(--win-height);
}

.section__header,
.section__content,
.section__content > div {
  width: 100%;
}

.section__header {
  flex: 0 1 auto;
  margin-bottom: theme('spacing.y-gutter-sm');
  position: relative;
  text-align: center;
  width: 100%;
}

.section__title {
  display: inline-block;
  font-weight: theme('fontWeight.medium');
  max-width: 100%;
  position: relative;
}

.section--title-images .section__title {
  padding-left: calc(var(--section-title-image-size) / 3);
  padding-right: calc(var(--section-title-image-size) / 3);
  width: 100%;
}

.section__title:only-child {
  margin-bottom: 0;
}

.section__title-image {
  align-items: center;
  display: flex;
  height: var(--section-title-image-size);
  max-width: none;
  position: absolute;
  top: 50%;
  width: calc(var(--section-title-image-size) - 1em);
}

.section__title-image:nth-child(odd) {
  left: 0;
  transform: translate(0, -50%);
}

.section__title-image:nth-child(even) {
  right: 0;
  transform: translate(0, -50%);
}

.section__content {
  display: flex;
  flex: 1 1 auto;
}

.section--centered .section__content {
  align-items: center;
}

.section__content > div {
  flex: 0 1 auto;
}

@screen xs {
  .section__title-image {
    width: calc(var(--section-title-image-size) - 1.5em);
  }
}

@screen md {
  .section {
    @apply py-y-gutter-lg;
  }

  .section__header {
    margin-left: auto;
    margin-right: auto;
  }

  .section__subtitle {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }

  .section--title-images .section__title {
    padding-left: calc(var(--section-title-image-size) / 1.5);
    padding-right: calc(var(--section-title-image-size) / 1.5);
    width: auto;
  }

  .section__title-image:nth-child(odd) {
    left: auto;
    right: 100%;
    transform: translate(50%, -50%);
  }

  .section__title-image:nth-child(even) {
    left: 100%;
    right: auto;
    transform: translate(-50%, -50%);
  }
}

@screen xl {
  .section__title-image {
    width: calc(var(--section-title-image-size) - 1em);
  }
}
</style>
