import { MdTextFields } from 'react-icons/md'

import { SmallDecorator } from '../../components'

export default {
  title: 'Small',
  value: 'small',
  icon: MdTextFields,
  component: SmallDecorator
}
