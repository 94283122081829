<template>
  <div class="shop-menu-section flex flex-col items-between h-full">
    <ul>
      <section-item
        v-for="item in shopMenuSectionItems"
        :id="item.id"
        :key="item._key"
        :scrim-id="scrimId"
        :section-item="item"
      />
    </ul>
    <hr />
  </div>
</template>

<script>
import SectionItem from './section-item.vue'
import getCBDFilter from '~/util/get-cbd-filter'

export default {
  name: 'ShopMenuSection',
  components: {
    SectionItem
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    scrimId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    isCBDSafe: getCBDFilter(process.env.nonCBDSite)
  }),
  computed: {
    shopMenuSectionItems() {
      return this.section.shopMenuSectionItems.filter(this.isCBDSafe)
    }
  }
}
</script>

<style scoped>
hr {
  background-color: currentColor;
  height: 2px;
  margin: 1.5em 0;
  width: 50px;
}

.shop-menu-section:last-child hr {
  display: none;
}
</style>
