function isNode() {
  return (
    typeof process === 'object' && process.versions && process.versions.node
  )
}

class Cache {
  async init() {
    if (this.isInitialized) {
      return
    }

    if (isNode()) {
      try {
        const module = await import('cache-manager')
        this.cache = module.default.caching({
          store: 'memory',
          max: 100
        })
      } catch (error) {
        // Handle error
        console.error(error)
      }
    }

    this.isInitialized = true
  }

  wrap(key, cb) {
    return this.cache ? this.cache.wrap(key, cb) : cb()
  }
}

const cache = new Cache()

export default cache

export async function withCache(key, cb) {
  await cache.init()
  return cache.wrap(key, cb)
}
