<template>
  <div
    :is="tag"
    v-observe-visibility="visibilityArgs"
    :class="classes"
    :style="style"
    v-bind="$attrs"
    class="color-section"
  >
    <gradient
      v-if="bounded"
      :colors="topGradientColors"
      :force-gradient="forceGradient"
      class="color-section__gradient color-section__gradient--top animation-patch"
    />
    <slot />
    <gradient
      v-if="bounded"
      :colors="bottomGradientColors"
      class="color-section__gradient color-section__gradient--bottom animation-patch"
      :force-gradient="forceGradient"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { colors } from '~/recess.json'

export default {
  name: 'ColorSection',

  props: {
    tag: {
      default: 'div',
      type: String
    },
    backgroundColors: {
      default: colors['default-background'],
      type: [Array, String]
    },
    textColor: {
      default: colors['default-text-color'],
      type: String
    },
    threshold: {
      default: 0,
      type: Number
    },
    colorMargin: {
      default: undefined,
      type: String
    },
    bounded: {
      default: false,
      type: Boolean
    },
    // if true, will use the backgroundColors on the div itself
    // in addition to changing the page background color
    opaque: {
      default: false,
      type: Boolean
    },
    forceGradient: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState('screen', {
      winHeight: state => state.height
    }),
    ...mapGetters('screen', {
      transparentColor: 'transparentColor'
    }),
    classes() {
      return {
        'color-section--bounded': this.bounded,
        'color-section--opaque': this.opaque
      }
    },
    style() {
      const bounded = this.bounded
      const style = {}

      if (this.opaque) {
        style.backgroundImage = `linear-gradient(${this.backgroundColors.join(
          ','
        )})`
      }

      if (!bounded) {
        return style
      }
      const colors = this.backgroundColorsArray
      const textColor = this.textColor
      style.backgroundImage = this.getGradientStyle(colors)
      style['--background-color'] = colors[0]
      style['--semiopaque-color'] = this.getSemiopaqueColor(colors[0])
      style['--transparent-color'] = this.getTransparentColor(colors[0])
      if (textColor) {
        style.color = textColor
        style['--text-color'] = textColor
      }

      return style
    },
    visibilityArgs() {
      const threshold = this.threshold
      const rootMargin = this.rootMargin
      return {
        callback: this.visibilityChange,
        intersection: { threshold, rootMargin },
        throttle: 200,
        leading: 'hidden'
      }
    },
    rootMargin() {
      let margin = this.colorMargin
      if (margin) {
        return margin
      }
      const win = this.winHeight
      const top = win === '100vh' ? '0px' : `-${win / 4}px`
      const bottom = win === '100vh' ? '-100%' : `-${win / 4}px`
      margin = `${top} 0px ${bottom} 0px`

      return margin.replace('--', '-')
    },
    backgroundColorsArray() {
      const colors = this.backgroundColors
      if (!colors) {
        return []
      }
      return Array.isArray(colors) ? colors : [colors]
    },
    topGradientColors() {
      const colors = this.backgroundColorsArray
      return [this.transparentColor, colors[0]]
    },
    bottomGradientColors() {
      const colors = this.backgroundColorsArray
      return [colors[colors.length - 1], this.transparentColor]
    },
    colors() {
      const id = this.$_uid
      const backgroundColors = this.backgroundColorsArray
      const textColor = this.textColor
      if (backgroundColors.length <= 0) {
        return null
      }
      return {
        id,
        backgroundColors,
        textColor
      }
    }
  },

  beforeDestroy() {
    this.unsetColors()
  },

  methods: {
    ...mapActions('screen', {
      addSectionColors: 'addSectionColors',
      removeSectionColors: 'removeSectionColors'
    }),
    visibilityChange(visible) {
      if (visible) {
        this.setColors()
      } else {
        this.unsetColors()
      }
    },
    setColors() {
      if (this.transitioning) {
        return
      }
      const colors = this.colors
      if (colors) {
        this.addSectionColors(colors)
      }
    },
    unsetColors() {
      const colors = this.colors
      if (colors) {
        this.removeSectionColors(colors)
      }
    }
  }
}
</script>

<style>
.color-section {
  content-visibility: auto;
}

.color-section--opaque {
  content-visibility: unset;
}

.color-section--bounded {
  position: relative;
}

.color-section__gradient {
  height: var(--header-height);
  left: 0;
  position: absolute;
  width: 100%;
}

.color-section__gradient--top {
  bottom: 100%;
}

.color-section__gradient--bottom {
  top: 100%;
}
</style>
