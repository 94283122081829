<template functional>
  <p class="heading">
    <slot />
  </p>
</template>

<script>
export default {
  name: 'SanityHeadingBlock',

  functional: true
}
</script>
