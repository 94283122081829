import React from 'react'
import { useGetImageUrl } from '../../utils'

export default function GalleryBlockPreview({ gallery }) {
  const imageUrlBuilder = useGetImageUrl()
  const getImgSrc = asset =>
    imageUrlBuilder.image(asset).width(200).height(200).fit('crop').url()

  return (
    <React.Fragment>
      <p style={{ margin: '0.5rem 0.5rem 0.75rem' }}>Gallery</p>
      {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
      <ul
        role="list"
        style={{
          display: 'grid',
          gap: '0.25rem',
          gridTemplateColumns: 'repeat(auto-fit, minmax(5rem, 1fr))',
          listStyle: 'none',
          margin: 0,
          padding: 0,
          width: '100%'
        }}
      >
        {gallery?.map(item => (
          <li
            key={item._key}
            style={{
              flex: '0 1 20%',
              margin: 0,
              maxWidth: '7.5rem'
            }}
          >
            {item._type === 'image' && (
              <img
                src={item.asset && getImgSrc(item.asset)}
                style={{ display: 'block', width: '100%', aspectRatio: 1 }}
                alt=""
              />
            )}
            {item._type === 'video' && 'Video'}
          </li>
        ))}
      </ul>
    </React.Fragment>
  )
}
