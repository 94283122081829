<script>
import { gsap } from 'gsap'
import imagesLoaded from 'imagesloaded'

export default {
  name: 'Fade',

  functional: true,

  render(createElement, ctx) {
    const props = {
      name: 'fade',
      css: false,
      tag: 'div',
      duration: 0.25,
      enterDelay: 0,
      leaveDelay: 0,
      enterOpacity: 0,
      opacity: 1,
      up: false,
      ...ctx.props
    }
    const data = {
      props,
      attrs: ctx.props,
      on: {
        beforeEnter(el) {
          el.style.opacity = props.enterOpacity
          el.style.visibility = 'hidden'
          if (props.up) {
            el.style.transform = 'translate(0, 2em)'
          }
        },
        async enter(el, done) {
          const autoAlphaStart = parseFloat(props.enterOpacity, 10)
          const autoAlphaEnd = parseFloat(props.opacity, 10)
          const duration = parseFloat(props.duration, 10)
          const delay = parseFloat(props.enterDelay, 10)
          const from = { autoAlpha: autoAlphaStart }
          const to = { autoAlpha: autoAlphaEnd, duration, delay }
          if (props.up) {
            from.transform = 'translate(0, 2em)'
            to.transform = 'translate(0, 0em)'
          }
          const tl = gsap
            .timeline({
              paused: true,
              onComplete: done
            })
            .set(el, from)
            .to(el, to)
          await new Promise(resolve => imagesLoaded(el, resolve))
          // force repaint to ensure correct sizes
          await new Promise(resolve => requestAnimationFrame(resolve))
          tl.play()
        },
        leave(el, done) {
          const duration = parseFloat(props.duration, 10)
          const delay = parseFloat(props.leaveDelay, 10)
          gsap.to(el, {
            autoAlpha: 0,
            duration,
            delay,
            onComplete: done
          })
        }
      }
    }
    return createElement('transition', data, ctx.children)
  }
}
</script>
