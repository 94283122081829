<template>
  <div
    v-observe-visibility="visibilityChange"
    :class="classes"
    @click="click"
    @mouseover.passive="mouseOver"
    @mousemove.passive="mouseMove"
    @mouseleave="mouseLeave"
  >
    <slot />
    <transition name="fade" appear>
      <float
        v-if="enabled && inBounds && !transitioning && showCursor"
        v-move-dom
        :style="cursorStyle"
        :stopped="moving"
        offset="-20%"
        aria-hidden="true"
        class="cursor-over__cur"
      >
        <fa :icon="cursorIcon" class="cursor-over__icon"
      /></float>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CursorOver',

  props: {
    enabled: {
      default: true,
      type: Boolean
    },
    icon: {
      default: 'hand-pointer',
      type: String
    },
    iconTop: {
      default: undefined,
      type: String
    },
    iconRight: {
      default: undefined,
      type: String
    },
    iconBottom: {
      default: undefined,
      type: String
    },
    iconLeft: {
      default: undefined,
      type: String
    },
    iconCenter: {
      default: undefined,
      type: String
    },
    iconOverride: {
      default: undefined,
      type: String
    },
    color: {
      default: null,
      type: String
    },
    centerAreaWidth: {
      default: 0,
      type: Number
    },
    hideCursorInCenter: {
      default: false,
      type: Boolean
    }
  },

  data: () => ({
    x: null,
    y: null,
    width: null,
    height: null,
    offsetX: null,
    offsetY: null,
    inBounds: false
  }),

  computed: {
    ...mapState('screen', {
      transitioning: state => state.transitioning,
      scroll: state => state.scroll,
      moving: state => state.cursorMoving,
      touch: state => state.touch
    }),
    offsetForCenterArea() {
      return this.centerAreaWidth / 2
    },
    sideX() {
      const x = this.x
      const w = this.width
      const offsetX = this.offsetX
      if (w === null || offsetX === null) {
        return null
      }

      const threshold = w / 2 + offsetX
      const thresholdLeft = threshold - 10 - this.offsetForCenterArea
      const thresholdRight = threshold + this.offsetForCenterArea

      if (x < thresholdLeft) {
        return 'left'
      } else if (x > thresholdRight) {
        return 'right'
      } else {
        return 'center'
      }
    },
    sideY() {
      const y = this.y
      const h = this.height
      const offsetY = this.offsetY
      if (y === null || offsetY === null) {
        return null
      }
      const threshold = h / 2 + offsetY
      return y < threshold ? 'top' : 'bottom'
    },
    cursorIcon() {
      if (this.iconOverride) {
        return this.iconOverride
      }
      const sideX = this.sideX
      const sideY = this.sideY
      let sideCur
      if (sideX !== null) {
        if (sideX === 'left') {
          sideCur = this.iconLeft
        } else if (sideX === 'right') {
          sideCur = this.iconRight
        } else if (sideX === 'center') {
          sideCur = this.iconCenter
        }
      } else if (sideY !== null) {
        sideCur = sideY === 'top' ? this.iconTop : this.iconBottom
      }

      return sideCur || this.icon
    },
    cursorStyle() {
      return {
        left: `${this.x}px`,
        top: `${this.y - this.scroll}px`,
        '--cursor-color': this.color
      }
    },
    classes() {
      if (!this.enabled) {
        return ''
      }
      const x = this.sideX
      const y = this.sideY

      return {
        'cursor-over': this.showCursor,
        [`is-${x}`]: this.showCursor && x !== null,
        [`is-${y}`]: this.showCursor && y !== null
      }
    },
    showCursor() {
      if (this.sideX === 'center' && this.hideCursorInCenter) {
        return false
      }
      return true
    }
  },

  mounted() {
    this.$nextTick(this.resize)
    this.$bus.$on('resize', this.resize)
  },

  beforeDestroy() {
    this.$bus.$off('resize', this.resize)
    this.unbindMouseMove()
  },

  methods: {
    visibilityChange(visible) {
      if (visible) {
        this.bindMouseMove()
      } else {
        this.unbindMouseMove()
      }
    },
    bindMouseMove() {
      window.addEventListener('mousemove', this.mouseMove, { passive: true })
    },
    unbindMouseMove() {
      window.removeEventListener('mousemove', this.mouseMove, { passive: true })
    },
    resize() {
      const rect = this.$el.getBoundingClientRect()
      this.width = rect.width
      this.height = rect.height
      this.offsetX = rect.left
      this.offsetY = rect.top
    },
    mouseMove(e) {
      this.x = e.pageX
      this.y = e.pageY
    },
    mouseOver(e) {
      if (this.touch) {
        return
      }
      if (e.target.closest('a')) {
        this.inBounds = false
        return
      }
      if (!this.inBounds) {
        this.inBounds = true
      }
    },
    click(e) {
      if ((this.touch && this.sideX !== 'center') || e.target.closest('a')) {
        return
      }

      this.$emit(`click-${this.sideX}`)
      this.$emit(`click-${this.sideY}`)
    },
    mouseLeave() {
      this.inBounds = false
    }
  }
}
</script>

<style>
.is-not-touch .cursor-over {
  cursor: none;
}

.cursor-over__cur {
  font-size: var(--cursor-size);
  pointer-events: none;
  position: fixed;
  z-index: theme('zIndex.galleryOverlay');
}

.cursor-over__icon {
  color: var(--cursor-color);
}
</style>
