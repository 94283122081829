import findKey from 'lodash/findKey'
import { screens } from '~/recess.json'
import {
  getHex,
  getSemiopaqueColor,
  getTransparentColor,
  getBackgroundColor,
  getShadowColor,
  getScheme
} from '~/util/color'

export default {
  breakpoint: state => {
    const width = state.width
    const height = state.height
    let breakpoints
    if (typeof width === 'number') {
      breakpoints = {}
      // eslint-disable-next-line array-callback-return
      Object.keys(screens).map(bp => {
        if (bp === 'tall') {
          breakpoints.tall = height >= screens.tall
          // eslint-disable-next-line array-callback-return
          return
        }
        breakpoints[bp] = width >= screens[bp]
      })
    }
    return breakpoints
  },
  currentBreakpoint(state, getters) {
    const breakpoints = getters.breakpoint
    const active = Object.keys(breakpoints)
      .filter(bp => bp !== 'tall' && breakpoints[bp] === true)
      .map(bp => screens[bp])
    let current
    if (active.length) {
      const widest = active.reduce((a, b) => {
        return Math.max(a, b)
      })
      current = findKey(screens, bp => bp === widest)
    }
    return current
  },
  colors(state) {
    const sectionColors = state.sectionColors
    const pageColors = state.pageColors
    const colors = { ...pageColors }
    if (sectionColors.length > 0) {
      const sectionColor = sectionColors[0]
      if (
        sectionColor.backgroundColors &&
        sectionColor.backgroundColors.length > 0
      ) {
        colors.backgroundColors = sectionColor.backgroundColors
      }
      if (sectionColor.textColor) {
        colors.textColor = sectionColor.textColor
      }
    }
    return colors
  },
  shadowColor(state, { colors }) {
    return getShadowColor(colors.textColor)
  },
  backgroundColor(state, { colors: { backgroundColors } }) {
    return getBackgroundColor(backgroundColors)
  },
  themeColor(state, { backgroundColor }) {
    return getHex(backgroundColor)
  },
  semiopaqueColor(state, { backgroundColor }) {
    return getSemiopaqueColor(backgroundColor)
  },
  transparentColor(state, { backgroundColor }) {
    return getTransparentColor(backgroundColor)
  },
  transparentColors(state, { transparentColor }) {
    const color = transparentColor
    return [color, color]
  },
  semiTransparentColors(state, { semiopaqueColor, transparentColor }) {
    return [semiopaqueColor, transparentColor]
  },
  scheme(state, { backgroundColor }) {
    return getScheme(backgroundColor)
  }
}
