import { IoMdColorWand } from 'react-icons/io'

export default {
  title: 'Featured Product',
  name: 'featuredProduct',
  type: 'document',
  icon: IoMdColorWand,
  __experimental_actions: ['update', 'publish'],
  fields: [
    {
      title: 'Product',
      name: 'product',
      type: 'reference',
      to: [{ type: 'product' }]
    }
  ]
}
