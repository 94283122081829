<template functional>
  <cta
    tag="recess-link"
    :sanity-link="{ url: props.url, external: props.external }"
    fill
    class="link--cta"
  >
    <slot />
  </cta>
</template>

<script>
export default {
  name: 'SanityCta',

  functional: true,

  props: {
    url: {
      default: undefined,
      type: String
    },
    external: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style scoped>
.link--cta {
  margin: 1em 0.5em;
}
</style>
