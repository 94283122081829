import { IoIosChatboxes } from 'react-icons/io'
import colorsField from './fields/colors'

export default {
  title: 'Testimonials',
  name: 'testimonialsPage',
  type: 'document',
  icon: IoIosChatboxes,
  __experimental_actions: ['update', 'publish'],
  fields: [
    {
      title: 'Testimonials',
      name: 'testimonials',
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [{ type: 'testimonial' }]
        }
      ]
    },
    colorsField
  ]
}
