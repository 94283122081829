import { GiBarefoot } from 'react-icons/gi'

export default {
  title: 'Site Footer',
  name: 'siteFooter',
  type: 'document',
  icon: GiBarefoot,
  __experimental_actions: ['update', 'publish'],
  fields: [
    {
      title: 'Newsletter Text',
      name: 'newsletterText',
      type: 'string'
    },
    {
      title: 'Disclaimer Text',
      name: 'disclaimer',
      type: 'text'
    },
    {
      title: 'FDA Disclaimer Text',
      name: 'fdaDisclaimer',
      description: 'Disclaimer that appears in box',
      type: 'text'
    },
    {
      title: 'Copyright Text',
      name: 'copyright',
      type: 'string'
    }
  ]
}
