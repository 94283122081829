<template>
  <div
    v-if="showBanner"
    id="scrolling-banner"
    v-observe-visibility="visibilityChanged"
    class="font-medium scrolling-banner"
    :style="{ backgroundColor: backgroundColor.hex, color: textColor.hex }"
  >
    <div v-if="!isScrolling && staticBanner" class="text-center">
      <scrolling-banner-text :banner="staticBanner" />
    </div>

    <div v-if="isScrolling && filteredBanners.length > 0" class="text-center">
      <marquee
        class="w-full scrolling-banner__marquee"
        :speed="scrollingSpeed"
        fixed-speed
      >
        <div class="scrolling-banner__container">
          <div
            v-for="(banner, index) in filteredBanners"
            :key="index"
            class="scrolling-banner__wrapper"
          >
            <scrolling-banner-text :banner="banner" />
            <span class="scrolling-banner__cloud">&nbsp;←&nbsp;</span>
          </div>
        </div>
      </marquee>
    </div>

    <div class="scrolling-banner__waves">
      <marquee
        class="absolute left-0 w-full -mb-px bottom-full"
        speed="16"
        fixed-speed
        reverse
      >
        <div class="scrolling-banner__wave-group">
          <wave
            v-for="waveIndex in waves"
            :key="waveIndex"
            :color="backgroundColor.hex"
          />
        </div>
      </marquee>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { times } from 'lodash'
import ScrollingBannerText from './scrolling-banner-text'
import Wave from './wave'
import { meta } from '~/recess.json'
import getCBDFilter from '~/util/get-cbd-filter'

const numberOfWaves = 6

export default {
  name: 'ScrollingBanner',

  components: {
    ScrollingBannerText,
    Wave
  },

  data: () => ({
    waves: times(numberOfWaves),
    cloudSrc: meta.icons[0].href,
    isCBDSafe: getCBDFilter(process.env.nonCBDSite)
  }),

  computed: {
    ...mapState({
      isActive: state => state.settings.globalBanner.isActive,
      isScrolling: state => state.settings.globalBanner.isScrolling,
      staticBanner: state => state.settings.globalBanner.staticBanner,
      banners: state => state.settings.globalBanner.banners,
      textColor: state => state.settings.globalBanner.textColor,
      backgroundColor: state => state.settings.globalBanner.backgroundColor
    }),
    scrollingSpeed() {
      if (this.$device.isMobile) {
        return 70
      }

      return 100
    },
    showBanner() {
      return (
        this.isActive &&
        ((!this.isScrolling && this.staticBanner) ||
          (this.isScrolling && this.filteredBanners.length > 0))
      )
    },
    filteredBanners() {
      if (!this.banners) {
        return []
      }

      return this.banners.filter(this.isCBDSafe)
    }
  },

  mounted() {
    this.$nextTick(function() {
      this.setup()
    })
  },

  methods: {
    ...mapActions('screen', {
      setGlobalBannerHeight: 'setGlobalBannerHeight',
      resetGlobalBannerHeight: 'resetGlobalBannerHeight'
    }),
    async setup() {
      await this.setGlobalBannerHeight()
    },
    async visibilityChanged(isVisible, entry) {
      if (isVisible) {
        await this.setGlobalBannerHeight()
      } else {
        await this.resetGlobalBannerHeight()
      }
    }
  }
}
</script>

<style>
.scrolling-banner {
  --scrolling-banner-wave-height: 12px;
  padding: 0.5em 0;
  position: relative;

  /* this matches the transfomr in the header
   otherwise, safari z indexes get mixed up */
  transform: translateZ(200px);

  @apply z-global-banner;
}

.scrolling-banner__container {
  display: flex;
  flex-direction: row;
  height: 16px;
}

.scrolling-banner__wrapper {
  display: flex;
  flex-direction: row;
}

.scrolling-banner__cloud {
  font-family: theme('fontFamily.antidote');
}

.scrolling-banner__wave-group {
  display: flex;
  flex-direction: row;
  height: var(--scrolling-banner-wave-height);
  width: 100vw;
}

.scrolling-banner__wave-group .wave--horizontal {
  flex: 1;
  min-width: auto;
}

.scrolling-banner__waves {
  bottom: 0;
  left: 0;
  position: absolute;
  transform: rotateZ(180deg);
  transform-origin: 50% 50%;
  width: 100%;
}

.is-overlay .scrolling-banner {
  @apply z-base;
}
</style>
