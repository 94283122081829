import cache from '~/apollo/cart-cache'

export default function({ env }) {
  return {
    httpEndpoint: env.shopifyEndpoint,
    httpLinkOptions: {
      headers: {
        'X-Shopify-Storefront-Access-Token': env.shopifyToken
      }
    },
    cache
  }
}
