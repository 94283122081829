import urlBuilder from '@sanity/image-url'
import { useRef } from 'react'

import useClient from './useClient'

export default function useGetImageUrl() {
  const client = useClient()
  const imageUrlBuilder = useRef(urlBuilder(client))
  return imageUrlBuilder.current
}
