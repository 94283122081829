import groq from 'groq'
import mediaField from './media'

export default groq`{
  title,
  url,
  media{
    ...${mediaField}
  }
}`
