import groq from 'groq'
import thumbField from './thumb'
import galleryThumbField from './gallery-thumb'

export default groq`{
  cbd,
  "category": category->slug.current,
  badge,
  hideFromNavigation,
  hideFromCollection,
  ${thumbField},
  ${galleryThumbField},
  shopify,
  ingredients[]-> {
    title
  }
}`
