<template functional>
  <div class="media-block container">
    <sanity-media
      :image="props.image"
      :video="props.video"
      class="text-center"
    />
  </div>
</template>

<script>
export default {
  name: 'SanityMediaBlock',

  functional: true,

  props: {
    image: {
      default: null,
      type: Object
    },
    video: {
      default: null,
      type: Object
    }
  }
}
</script>
