<template>
  <div
    v-if="normalSlides && normalSlides.length > 0"
    :class="classes"
    class="relative hero-gallery"
  >
    <gallery
      ref="gallery"
      :slides="normalSlides"
      :active-slide.sync="activeSlide"
      :options="galleryOptions"
      :color="getColor('white')"
      :magnifier-click="magnifierClick"
      :center-area-enabled="true"
      class="text-center hero-gallery__gallery"
    >
      <div
        v-for="(slide, i) in normalSlides"
        :key="`slide-${i}`"
        style="padding-bottom: 4%; padding-top: 4%"
        class="w-full swiper-slide"
      >
        <float offset="-3%" reverse class="relative w-full h-win-h-1/2">
          <sanity-media
            v-bind="slide"
            boxed
            contain
            swiper
            image-class="swiper-lazy"
          />
        </float>

        <h3 v-if="slide.caption" class="pt-6 font-medium leading-tight">
          {{ slide.caption }}
        </h3>
      </div>

      <template #bottom>
        <div class="relative inline-flex flex-no-wrap justify-center mt-3em">
          <magnifier
            class-prop="hero-gallery__magnifier link mr-1/2em w-8 xs:w-10"
            style="flex: 0 1 25%"
            @click.stop.prevent="magnifierClick"
          />

          <thumbnail
            v-for="(slide, i) in thumbnailsVisible"
            :key="`slide-thumb-${i}`"
            :index="i"
            :active-index="activeSlide"
            @click="thumbClick(i)"
          >
            <sanity-media
              v-bind="slide"
              width="64"
              height="64"
              image-class="block"
              crop
            />
          </thumbnail>

          <thumbnail
            v-if="thumbnailShowMore"
            :active-index="activeSlide"
            class-prop="hero-gallery__thumbnail--see-more"
            :background-color="computedBackgroundColor"
            :index="thumbnailsVisible.length"
            @click="showMoreClick()"
          >
            <span class="hidden md:block"> More </span>

            <span class="md:hidden"> + </span>
          </thumbnail>
        </div>
      </template>
    </gallery>

    <fade appear>
      <gallery-overlay
        v-if="magnified && largeSlides.length > 0"
        ref="galleryOverlay"
        :slides="largeSlides"
        :active-slide.sync="activeSlide"
        :options="overlayOptions"
        :center-area-enabled="true"
        :hide-cursor-in-center="true"
        :min-side-percent="0.1"
        :show-overlay-bottom-slot="activeSlideIsImage"
        @close="overlayClose"
      >
        <div
          v-for="(slide, i) in largeSlides"
          :key="`overlay-slide-${i}`"
          class="flex flex-col w-full h-full swiper-slide px-x-gutter-lg swiper-zoom-container"
        >
          <!-- I know this looks strange but if we use a dynamic class for the images
          to set h-5/6, the sizing logic in gallery.global.vue breaks. -->

          <sanity-media
            v-if="slide.video === undefined"
            v-bind="slide"
            class="h-5/6"
            swiper
            no-ratio
            image-class="w-auto h-full swiper-lazy"
          />

          <sanity-media
            v-if="slide.video !== undefined"
            v-bind="slide"
            :show-video-player="true"
            :ratio="
              slide.video.metadata.aspect_ratio
                .split(':')
                .reduce((a, b) => parseInt(a) / parseInt(b))
            "
            swiper
            image-class="w-auto h-full swiper-lazy"
          />

          <h3
            v-if="slide.caption"
            class="py-6 font-medium leading-tight text-center"
          >
            {{ slide.caption }}
          </h3>
        </div>
      </gallery-overlay>
    </fade>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Magnifier from '~/components/magnifier.vue'
import Thumbnail from '~/components/hero-gallery-thumbnail.vue'
import { colors } from '~/recess.json'

const MAX_VISIBLE_THUMBNAILS = 5

export default {
  name: 'HeroGallery',

  components: {
    Magnifier,
    Thumbnail
  },

  props: {
    backgroundColors: {
      default: () => [],
      type: Array
    },
    slides: {
      default: () => [],
      type: Array
    }
  },

  data: () => ({
    activeSlide: 0,
    magnified: false,
    maxVisibleThumbnails: MAX_VISIBLE_THUMBNAILS,
    normalSlides: [],
    largeSlides: [],
    galleryOptions: {
      pagination: false,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      }
    },
    overlayOptions: {
      simulateTouch: true,
      zoom: {
        maxRatio: 1.5,
        toggle: false
      }
    }
  }),

  async fetch() {
    const slides = this.slides
    const urls = []
    const largeUrls = []
    const getUrls = async images => {
      for (const { image } of images) {
        const url = await this.getImageUrl({
          image
        })
        urls.push(url)
      }
    }
    const getLargeUrls = async images => {
      for (const { image } of images) {
        const url = await this.getImageUrl({
          image,
          width: 2000,
          height: 2000
        })
        largeUrls.push(url)
      }
    }
    if (!slides || !slides.length) {
      return
    }
    await getUrls(slides)
    const normalSlides = slides.map((s, i) => ({
      ...s,
      imageSrc: urls[i]
    }))
    await getLargeUrls(slides)
    const largeSlides = slides.map((s, i) => ({
      ...s,
      imageSrc: largeUrls[i]
    }))
    this.normalSlides = normalSlides
    this.largeSlides = largeSlides
  },

  computed: {
    ...mapGetters('screen', {
      bp: 'breakpoint'
    }),
    classes() {
      return {
        'is-magnified': this.magnified
      }
    },
    computedBackgroundColor() {
      if (this.backgroundColors.length > 0) {
        return this.getBackgroundColor(this.backgroundColors)
      }

      return colors.peach
    },
    thumbnailsVisible() {
      return this.slides.length > this.maxVisibleThumbnails
        ? this.slides.slice(0, this.maxVisibleThumbnails - 1)
        : this.slides
    },
    thumbnailShowMore() {
      return this.thumbnailsVisible.length < this.slides.length
    },
    activeSlideIsImage() {
      return this.slides[this.activeSlide].video === undefined
    }
  },

  methods: {
    ...mapActions({
      getImageUrl: 'getImageUrl'
    }),
    overlayClose() {
      this.magnified = false
    },
    thumbClick(index) {
      this.activeSlide = index
    },
    magnifierClick() {
      this.magnified = !this.magnified
    },
    showMoreClick() {
      this.magnified = true
      this.activeSlide = this.maxVisibleThumbnails - 1
    }
  }
}
</script>

<style>
.hero-gallery__gallery {
  transition: opacity var(--fade-speed);
}

.hero-gallery.is-magnified .hero-gallery__gallery {
  opacity: 0;
}

.hero-gallery__zoom {
  --link-color: theme('colors.melrose');
  border: var(--input-border);
  border-radius: var(--input-border-radius);
  bottom: var(--input-gutter);
  color: theme('colors.white');
  font-size: theme('fontSize.lg');
  font-weight: theme('fontWeight.medium');
  left: 50%;
  line-height: 1;
  min-width: 150px;
  padding: var(--input-gutter);
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  z-index: 2;
}

.hero-gallery__thumbnail--see-more .cta__content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

@screen lg {
  .hero-gallery__magnifier {
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
