import groq from 'groq'
import linkFields from '../fields/link'

export default groq`
  *[_type == "menus"][0]{
    loginLink,
    "primaryMenu": {
      "title": primaryMenu.title,
      "links": primaryMenu.links[]{
        _key,
        ...${linkFields}
      }
    },
    "secondaryMenu": {
      "title": secondaryMenu.title,
      "links": secondaryMenu.links[]{
        _key,
        ...${linkFields}
      }
    },
    "legalPageMenu": {
      "links": legalPageMenu[]{
        _key,
        ...${linkFields}
      }
    }
  }
`
