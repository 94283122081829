import { GiButtonFinger } from 'react-icons/gi'

export default {
  name: 'cta',
  type: 'object',
  title: 'CTA',
  fields: [
    {
      title: 'URL',
      name: 'url',
      type: 'url',
      validation: Rule => Rule.uri({ allowRelative: true })
    },
    {
      title: 'Does this URL link to an outside page?',
      name: 'external',
      type: 'boolean'
    }
  ],
  icon: GiButtonFinger
}
