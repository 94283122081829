import Color from 'color'
import { colors, opacities } from '~/recess.json'

const defaultBackground = colors['default-background']
const defaultTextColor = colors['default-text-color']

/**
 * Returns a string with a properly formatted rgba
 * value from a Sanity color object
 * @param {object}
 * @returns {string}
 */
export const getSanityRgba = ({ rgb }) => {
  return rgb ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})` : ''
}

/**
 * Takes either a color string or a Sanity color object
 * and returns a properly formatted color string
 * @param {string, object} color
 * @returns {string}
 */
export const normalizeColor = color => {
  if (typeof color === 'object' && color !== null) {
    return getSanityRgba(color)
  }
  return color
}

/**
 * Takes an array of color strings or Sanity color
 * objects and converts each to a properly formatted
 * color string before returning the array
 * @param {array} colors
 * @returns {array}
 */
export const normalizeColors = colors => {
  return colors.map(c => normalizeColor(c))
}

/**
 * Converts a color of any type to a hex value.
 * Uses the first value of arrays of color strings
 * @param {string, array} color
 * @returns {string}
 */
export const getHex = (color = defaultBackground) => {
  if (Array.isArray(color)) {
    color = normalizeColors(color)[0]
  } else {
    color = normalizeColor(color)
  }
  if (color) {
    color = Color(color)
      .alpha(1)
      .hex()
  }
  return color
}

/**
 * Converts a color of any type to a semiopaque color.
 * Uses the first value of arrays of color strings
 * @param {string, array} color
 * @returns {string}
 */
export const getSemiopaqueColor = (color = defaultBackground) => {
  if (Array.isArray(color)) {
    color = normalizeColors(color)[0]
  } else {
    color = normalizeColor(color)
  }
  if (color) {
    color = Color(color)
      .alpha(opacities.semiopaque)
      .string()
  }
  return color
}

/**
 * Converts a color of any type to a transparent color.
 * Uses the first value of arrays of color strings
 * @param {string, array} color
 * @returns {string}
 */
export const getTransparentColor = (color = defaultBackground) => {
  if (Array.isArray(color)) {
    color = normalizeColors(color)[0]
  } else {
    color = normalizeColor(color)
  }
  if (color) {
    color = Color(color)
      .alpha(0)
      .string()
  }
  return color
}

/**
 * Converts a color of any type to a color suitable for shadows.
 * Uses the first value of arrays of color strings
 * @param {string, array} color
 * @returns {string}
 */
export const getShadowColor = (color = defaultBackground) => {
  if (Array.isArray(color)) {
    color = normalizeColors(color)[0]
  } else {
    color = normalizeColor(color)
  }
  if (color) {
    color = Color(color)
      .alpha(opacities.shadow)
      .string()
  }
  return color
}

/**
 * Expects a Sanity color object, color string or
 * array of color strings/objects and returns the
 * first normalized color string of these using
 * the default textColor as a fallback
 * @param {object, array, string} color
 * @returns {string}
 */
export const getTextColor = (color = defaultTextColor) => {
  if (Array.isArray(color)) {
    color = normalizeColors(color)[0]
  } else {
    color = normalizeColor(color)
  }
  return color
}

/**
 * Expects a Sanity color object, color string or
 * array of color strings/objects and returns the
 * first normalized color string of these using
 * the default background as a fallback
 * @param {object, array, string} color
 * @returns {string}
 */
export const getBackgroundColor = (color = defaultBackground) => {
  if (Array.isArray(color)) {
    color = normalizeColors(color)[0]
  } else {
    color = normalizeColor(color)
  }
  return color
}

/**
 * Expects a Sanity color object or array of color
 * strings/objects and returns an array of normalized
 * color strings
 * @param {object, array} colors
 * @returns {array}
 */
export const getBackgroundColors = (colors = [defaultBackground]) => {
  if (Array.isArray(colors)) {
    colors = normalizeColors(colors)
  } else {
    colors = [normalizeColor(colors)]
  }
  return colors
}

/**
 * Expects a Sanity color object, color string or
 * array of color strings/objects and returns the
 * first normalized color converted to a hex string
 * @param {object, array, string} color
 * @returns {string}
 */
export const getThemeColor = (color = defaultBackground) => {
  if (Array.isArray(color)) {
    color = normalizeColors(color)[0]
  } else {
    color = normalizeColor(color)
  }
  return getHex(color)
}

/**
 * Given 1-2 color strings, returns a properly
 * formatted linear-gradient style value
 * @param {array, string} color
 * @returns {string}
 */
export const getGradientStyle = (colors = [defaultBackground]) => {
  if (typeof colors === 'string') {
    colors = [colors]
  }
  if (colors.length === 1) {
    return `linear-gradient(${colors}, ${colors})`
  } else {
    return `linear-gradient(${colors.join(',')})`
  }
}

/**
 * Returns either 'light' or 'dark' depending on the
 * determined brightness of a given color array or string
 * @param {string, object} color
 * @returns {string}
 */
export const getScheme = (colors = defaultBackground) => {
  let color
  if (Array.isArray(colors)) {
    color = normalizeColors(colors)[0]
  } else {
    color = normalizeColor(colors)
  }
  let isDark
  if (color) {
    isDark = Color(color).isDark()
  }
  return isDark ? 'dark' : 'light'
}
