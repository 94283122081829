import mediaFields from './fields/media'
import inlineEditor from './blocks/inline-editor'

export default {
  title: 'Category Collection',
  name: 'productCategoryCollection',
  type: 'document',
  fields: [
    {
      title: 'CBD',
      name: 'cbd',
      type: 'boolean',
      description:
        'If CBD is enabled, this record will not be shown on the non-CBD storefront.'
    },
    {
      name: 'slug',
      title: 'Slug',
      type: 'slug',
      validation: Rule => Rule.required()
    },
    {
      name: 'introTitle',
      title: 'Intro Title',
      type: 'string',
      validation: Rule => Rule.required()
    },
    {
      ...inlineEditor,
      name: 'introText',
      title: 'Intro Text',
      validation: Rule => Rule.required()
    },
    {
      ...mediaFields,
      name: 'introMedia',
      title: 'Intro Media',
      validation: Rule => Rule.required()
    },
    {
      ...mediaFields,
      name: 'nonCBDIntroMedia',
      title: 'Non-CBD Intro Media'
    },
    {
      name: 'introBackgroundColor',
      title: 'Intro Background Color',
      type: 'color',
      validation: Rule => Rule.required()
    },
    {
      name: 'sections',
      title: 'Sections',
      type: 'array',
      of: [
        {
          name: 'section',
          title: 'Section',
          type: 'object',
          fields: [
            {
              name: 'productCategories',
              title: 'Product Categories',
              type: 'array',
              of: [
                {
                  type: 'object',
                  name: 'categoryItem',
                  title: 'Category Item',
                  fields: [
                    {
                      type: 'string',
                      name: 'header',
                      title: 'Product Category Header',
                      validation: Rule => Rule.required()
                    },
                    {
                      ...inlineEditor,
                      name: 'subheader',
                      title: 'Product Category SubHeader',
                      validation: Rule => Rule.required()
                    },
                    {
                      type: 'reference',
                      name: 'productCategory',
                      to: [{ type: 'productCategory' }],

                      validation: Rule => Rule.required()
                    },
                    {
                      type: 'string',
                      name: 'showMoreText',
                      title: 'Show More Button Text',
                      description:
                        'The text for the "show more flavors" button (if required)'
                    }
                  ]
                }
              ],
              validation: Rule => Rule.required()
            },
            {
              name: 'colors',
              title: 'Section Colors',
              type: 'array',
              of: [{ type: 'color' }]
            }
          ],
          preview: {
            select: {
              productCategories: 'productCategories'
            },
            prepare(data) {
              const { productCategories } = data
              return {
                title: productCategories
                  .map(category => category.header)
                  .join(', ')
              }
            }
          }
        }
      ]
    }
  ]
}
