<script>
import { gsap } from 'gsap'

export default {
  name: 'SlideGroup',

  functional: true,

  render(createElement, ctx) {
    const props = {
      name: 'slide-group',
      css: false,
      tag: 'div',
      direction: 'left',
      stagger: 0.25,
      ...ctx.props
    }
    const data = {
      props,
      attrs: ctx.props,
      on: {
        beforeEnter(el) {
          const display = el.dataset.display || 'block'
          el.style.display = display
        },
        enter(el, done) {
          const index = el.dataset.index || 0
          const delay = index * props.stagger
          const dir = props.direction
          const offset = dir === 'left' ? '100%' : '-100%'
          gsap
            .timeline({ onComplete: done })
            .set(el, { autoAlpha: 0, x: offset })
            .to(el, 1, { autoAlpha: 1, x: 0, delay })
        },
        leave(el, done) {
          const index = Math.max(el.dataset.index, 1)
          const total = Math.max(el.dataset.total, 2)
          const delay = (total - index) * props.stagger
          const dir = props.direction
          const offset = dir === 'left' ? '100%' : '-100%'
          gsap.to(el, 1, { autoAlpha: 0, x: offset, delay, onComplete: done })
        }
      }
    }
    return createElement('transition-group', data, ctx.children)
  }
}
</script>
