import groq from 'groq'
import galleryField from './gallery'

export default groq`
  defined(gallery) && defined(gallery.gallery) => {
    "galleryThumb": gallery.gallery[0]{
      ...${galleryField}
    }
  }
`
