<template>
  <cta
    :active="index === activeIndex"
    small
    frame
    fill
    class="w-8 max-w-20 mx-1/2em xs:w-10 sm:w-12 md:min-w-16 lg:w-auto"
    :background-color="backgroundColor"
    :class="classProp"
    style="flex: 1 1 25%;"
    @click.prevent="$emit('click', index)"
  >
    <slot></slot>
  </cta>
</template>

<script>
export default {
  name: 'HeroGalleryThumbnail',
  props: {
    index: {
      type: Number,
      required: true
    },
    activeIndex: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: false,
      default: undefined
    },
    classProp: {
      type: String,
      required: false,
      default: undefined
    }
  }
}
</script>
