import { defineConfig } from 'sanity'
import { deskTool } from 'sanity/desk'
import { dashboardTool, projectUsersWidget } from '@sanity/dashboard'
import { visionTool } from '@sanity/vision'
import { colorInput } from '@sanity/color-input'
import { media } from 'sanity-plugin-media'
import { muxInput } from 'sanity-plugin-mux-input'
import { netlifyWidget } from 'sanity-plugin-dashboard-widget-netlify'

import { CloudIcon, Logo } from './src/components'
import { getProductionUrl } from './src/lib'
import types from './src/schemas'
import structure from './src/structure'

export default function loadConfig(env) {
  return defineConfig([
    getConfig({
      dataset: env.dataset,
      env
    })
  ])
}

/**
 * @param {Partial<import('sanity').Config>} config
 * @returns {import('sanity').Config}
 */
function getConfig({ dataset, env, ...rest }) {
  return {
    basePath: `/admin/${dataset}`,
    dataset,
    document: { productionUrl: getProductionUrl(env) },
    icon: CloudIcon,
    name: `${dataset}-workspace`,
    plugins: [
      deskTool({ structure }),
      media(),
      dashboardTool({
        widgets: [
          netlifyWidget({
            title: 'Netlify Deployment',
            sites: env.netlify.sites
          }),
          projectUsersWidget({ layout: 'medium' })
        ]
      }),
      colorInput(),
      muxInput(),
      visionTool({
        defaultApiVersion: env.apiVersion,
        defaultDataset: dataset
      })
    ],
    projectId: env.projectId,
    schema: { types },
    studio: {
      components: {
        logo: Logo
      }
    },
    title: `Recess [${dataset}]`,
    ...rest
  }
}
