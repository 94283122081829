<template>
  <div v-if="showDiscountBanner" id="discount-banner">
    <GlobalBanner
      :text="text"
      :text-color="textColor"
      :background-color="backgroundColor"
    >
    </GlobalBanner>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GlobalBanner from './global-banner'

export default {
  name: 'DiscountBanner',

  components: {
    GlobalBanner
  },

  computed: {
    ...mapState({
      backgroundColor: state => state.settings.discountBanner.backgroundColor,
      discountCode: state => state.discountCode,
      isActive: state => state.settings.globalBanner.isActive,
      rawText: state => state.settings.discountBanner.text,
      textColor: state => state.settings.discountBanner.textColor
    }),

    showDiscountBanner() {
      return !this.isActive && this.discountCode
    },

    text() {
      return (
        this.rawText &&
        this.rawText.replace(/{{\s*code\s*}}/, this.discountCode)
      )
    }
  }
}
</script>
