import { MdShoppingCart } from 'react-icons/md'

export default {
  title: 'Product link',
  name: 'productLink',
  type: 'object',
  icon: MdShoppingCart,
  fields: [
    {
      title: 'Page',
      name: 'reference',
      type: 'reference',
      to: [{ type: 'product' }, { type: 'productCategory' }]
    }
  ]
}
