import Vue from 'vue'
import Hotjar from 'vue-hotjar'

const id = process.env.hotjarId
const isProduction = !process.env.isDev

if (id) {
  Vue.use(Hotjar, {
    id,
    isProduction,
    snippetVersion: 6
  })
}
