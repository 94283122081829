export default {
  title: 'Form Field',
  name: 'form-field',
  type: 'object',
  fields: [
    {
      title: 'Label',
      name: 'label',
      type: 'string',
      validation: Rule => Rule.required()
    },
    {
      title: 'Name',
      name: 'name',
      type: 'string',
      validation: Rule => Rule.required()
    },
    {
      title: 'Required',
      name: 'required',
      type: 'boolean',
      initialValue: false
    },
    {
      title: 'Type',
      name: 'type',
      type: 'string',
      initialValue: 'text',
      options: {
        list: [
          { value: 'text', title: 'Single-line text' },
          { value: 'textarea', title: 'Multi-line text' },
          { value: 'email', title: 'Email address' },
          { value: 'file', title: 'File upload' },
          { value: 'url', title: 'URL' },
          { value: 'select', title: 'Select (dropdown)' }
        ]
      },
      validation: Rule => Rule.required()
    },
    {
      title: 'Options',
      name: 'options',
      type: 'array',
      description: 'Used by the select and radio fields',
      of: [
        {
          type: 'object',
          fields: [
            {
              title: 'Label',
              name: 'key',
              type: 'string',
              validation: Rule => Rule.required()
            },
            {
              title: 'Value',
              name: 'value',
              type: 'string',
              validation: Rule => Rule.required()
            }
          ]
        }
      ]
    },
    {
      title: 'Accepted file types',
      name: 'accept',
      type: 'text',
      description:
        'Used by the file upload field.\nSpecify types by their file extension (.jpg) or MIME type (image/jpeg). Separate values with a comma or new line.',
      initialValue: 'image/*'
    }
  ],
  preview: {
    select: {
      title: 'label',
      type: 'type',
      required: 'required'
    },
    prepare({ required, title, type }) {
      return {
        title: required ? `${title}*` : title,
        subtitle: type
      }
    }
  }
}
