import { colors } from '~/recess.json'
import {
  getHex,
  getSemiopaqueColor,
  getTransparentColor,
  getSanityRgba,
  getTextColor,
  getBackgroundColors,
  getBackgroundColor,
  getShadowColor,
  getThemeColor,
  getGradientStyle,
  getScheme
} from '~/util/color'

export default {
  methods: {
    getHex,
    getSemiopaqueColor,
    getTransparentColor,
    getSanityRgba,
    getTextColor,
    getBackgroundColors,
    getBackgroundColor,
    getShadowColor,
    getThemeColor,
    getGradientStyle,
    getScheme,
    getColor(name) {
      return colors[name]
    }
  }
}
