import groq from 'groq'
import pageFields from '../fields/page'
import productFields from '../fields/product'
import linkField from '../fields/link'
import mediaField from '../fields/media'
import imageField from '../fields/image'
import pressField from '../fields/press'
import testimonialField from '../fields/testimonial'
import socialMediaField from '../fields/social-media'
import ingredientsWindowField from '../fields/ingredients-window'

export default alt => {
  const page = alt || 'home'
  return groq`
    *[_id == "${page}"][0]{
      _id,
      _type,
      splashTitle,
      splashTitleImage{
        ...${imageField},
      },
      splashText,
      splashVideoWebm {
        ...,
        asset-> {
          ...
        }
      },
      splashVideoMov {
        ...,
        asset-> {
          ...
        }
      },
      floatingCta,
      defined(splashCta.page) || defined(splashCta.url) => {
        splashCta{
          ...${linkField}
        }
      },
      splashHero {
        ...,
        ...${imageField}
      },
      splashIsVerticalLayout,
      introTitle,
      introText,
      waterIngredientsWindow {
        ...${ingredientsWindowField}
      },
      waterTitle,
      waterSubtitle,
      moodProductCategory->{
        backgroundColors[] {
          ...
        },
        backgroundImages[] {
          ...,
          asset-> {
            ...
          }
        }
      },
      "featuredWater": featuredWater[]->{
        ...${pageFields},
        ...${productFields}
      },
      samplerTitle,
      samplerSubtitle,
      "featuredSampler": featuredSampler[]->{
        ...${pageFields},
        ...${productFields}
      },
      zeroproofTitle,
      zeroproofSubtitle,
      "featuredZeroproof": featuredZeroproof[]->{
        ...${pageFields},
        ...${productFields}
      },
      moodWaterTitle,
      moodWaterSubtitle,
      "moodWaterProducts": moodWaterProducts[]->{
        ...${pageFields},
        ...${productFields}
      },
      moodWaterIngredientsWindow {
        ...${ingredientsWindowField}
      },
      moodPowderTitle,
      moodPowderSubtitle,
      "moodPowderProducts": moodPowderProducts[]->{
        ...${pageFields},
        ...${productFields}
      },
      moodPowderIngredientsWindow {
        ...${ingredientsWindowField}
      },
      realitywearTitle,
      realitywearText,
      "featuredRealitywear": featuredRealitywear[]->{
        ...${pageFields},
        ...${productFields}
      },
      collabTitle,
      collabText,
      defined(collabCta.page) || defined(collabCta.url) => {
        collabCta{
          ...${linkField}
        }
      },
      collabHero{
        ...${mediaField}
      },
      locatorTitle,
      locatorText,
      defined(locatorCta.page) || defined(locatorCta.url) => {
        locatorCta{
          ...${linkField}
        }
      },
      locatorHero{
        ...${mediaField}
      },
      pressTitle,
      testimonials[]->{
        ...${testimonialField}
      },
      featuredPress[]->{
        ...${pressField}
      },
      socialMedia[]{
        ...${socialMediaField}
      },
      signupTitle,
      signupText,
      featuredProductEnabled,
      featuredProductTitle,
      featuredProductText,
      featuredProductCta{
        ...${linkField}
      },
      featuredProductCtaTextColor,
      featuredProductCtaButtonTextColor,
      featuredProductCtaButtonBackgroundColor,
      featuredProductHeaderTopGradientColor,
      featuredProductImage {
        ...${imageField}
      },
      featuredProductBackgroundImage {
        ...${imageField}
      }
    }
  `
}
