import { colors } from '~/recess.json'

const backgroundColors = [colors['default-background']]
const textColor = colors['default-text-color']

export default () => ({
  touch: false,
  webmSupport: undefined,
  webpSupport: undefined,
  width: '100vw',
  height: '100vh',
  scroll: 0,
  scrolling: false,
  scrollTimer: null,
  cursorX: 0,
  cursorY: 0,
  cursorMoving: false,
  cursorTimer: null,
  gutter: 0,
  globalBannerHeight: 0,
  headerHeight: 0,
  overlay: null,
  pageColors: {
    backgroundColors,
    textColor
  },
  backgroundImages: [],
  sectionColors: [],
  transitioning: false,
  shopMenuSubmenuOpenedKey: undefined
})
