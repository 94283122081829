import { IoMdImages } from 'react-icons/io'
import { MediaBlockPreview } from '../../components'

import mediaField from '../fields/captionable-media'

export default {
  ...mediaField,
  fields: [
    ...mediaField.fields,
    {
      title: 'Position',
      name: 'position',
      type: 'string',
      options: {
        list: [
          { title: 'Left', value: 'left' },
          { title: 'Center (default)', value: 'center' },
          { title: 'Right', value: 'right' }
        ],
        layout: 'radio'
      }
    }
  ],
  title: 'Media',
  name: 'media',
  preview: {
    select: {
      caption: 'caption',
      image: 'image',
      imageFilename: 'image.asset.originalFilename'
    },
    prepare: ({ caption, image, imageFilename }) => ({
      title: caption || imageFilename || 'New Media',
      media: image
    })
  },
  components: {
    preview: MediaBlockPreview
  },
  icon: IoMdImages
}
