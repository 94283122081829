import decodeId from '~/util/api/decode-id'

export default sanityProduct => {
  if (!sanityProduct?.shopify?.product?.id) {
    return sanityProduct
  }

  return {
    ...sanityProduct,
    shopify: {
      ...sanityProduct.shopify,
      product: {
        ...sanityProduct.shopify.product,
        id: decodeId(sanityProduct.shopify.product.id)
      }
    }
  }
}
