import React from 'react'

const icons = {
  cloud: {
    props: {
      viewBox: '0 0 16 16'
    },
    children: [
      <path
        key="path"
        d="m2.886,12.684c-3.421-1.454-2.379-6.661,1.361-6.616-.695-4.57,5.96-5.673,6.774-1.146,2.054-.635,3.859,1.854,2.491,3.553,2.556.677,2.09,4.498-.581,4.483,0,0-8.716,0-8.716,0-.465,0-.908-.091-1.328-.274Z"
        fill="currentcolor"
      />
    ]
  }
}

export default function Icon({ icon, ...rest }) {
  if (icon in icons) {
    const { props, children } = icons[icon]
    return (
      <svg {...props} {...rest}>
        {children}
      </svg>
    )
  }
  return null
}
