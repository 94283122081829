export default {
  start(state) {
    state.started = true
  },
  end(state) {
    state.started = false
  },
  exit(state) {
    state.exited = true
  },
  setCloudsPopped(state, count) {
    state.cloudsPopped = count
  },
  incrementCloudsPopped(state) {
    state.cloudsPopped++
  }
}
