<template>
  <button :class="classes" class="cart-button link" @click.stop.prevent="click">
    <div v-if="close" class="close">
      <fa :icon="icon" class="close__icon" /><slot />
    </div>
    <template v-else>
      <flipper
        class="count text-right font-bold"
        :value="cartCount"
        :max="99"
      />
      <div class="relative">
        <svg width="16" height="26" viewBox="0 0 16 26" class="block">
          <path
            fill="currentColor"
            d="M0 13V5.9C0 2.7 1.4.8 4.5.3c2.3-.4 4.9-.3 7.2.1 3 .5 4.3 2.4 4.3 5.3v14.1c0 3.1-1.4 5.1-4.5 5.7-3.5.7-7.2 1.1-10-1.8-.8-.8-1.3-2-1.4-3.1-.2-2.5-.1-5.1-.1-7.6zm8.3-5.8c0-.2 0-.5-.1-.7 1.7-.4 3.4-.7 5.1-1.2.5-.2.9-1 1.3-1.5-.6-.1-1.3-.5-1.9-.3-2.9.8-5.9 1-8.8.2-2.4-.5-2.8-.3-2.8 2.2V20c0 2.3 1.1 4 3.3 4.4 2.4.4 5 .4 7.4 0 1.8-.3 3.2-1.7 3.2-3.8V8.1c0-1.4-.6-1.8-2-1.5-1.5.3-3.1.4-4.7.6zm4.4-4.6V2C10.3.3 5.1.4 3.1 2.2c1.1.9 2.7 1.6 3.6.7 1.4-1.4 2.5-.8 3.8-.5.8.1 1.5.1 2.2.2z"
          />
        </svg>
        <div class="fizz">
          <span v-for="i in fizzCount" :key="`fizz-${i}`" class="fizz__pop" />
        </div>
      </div>
    </template>
  </button>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

const fizzCount = 3

export default {
  name: 'CartButton',

  props: {
    close: {
      default: false,
      type: Boolean
    },
    icon: {
      default: 'times',
      type: [String, Object]
    }
  },

  data: () => ({
    fizzCount
  }),

  computed: {
    ...mapState('shop', {
      cart: state => state.cart
    }),
    ...mapState('screen', {
      overlay: state => state.overlay
    }),
    ...mapGetters('shop', {
      allPromotionSKUs: 'allPromotionSKUs'
    }),
    cartCount() {
      const cart = this.cart
      let count = 0
      if (cart && cart.lines) {
        cart.lines.edges.forEach(({ node }) => {
          if (!this.allPromotionSKUs.includes(node.merchandise.sku)) {
            count += node.quantity
          }
        })
      }
      return count
    },
    cartOpen() {
      return this.overlay === 'cart'
    },
    classes() {
      const close = this.close
      const hasItems = this.cartCount > 0
      return {
        'is-closer': close,
        'is-opener': !close,
        'has-items': hasItems
      }
    }
  },

  methods: {
    ...mapMutations('screen', {
      setOverlay: 'setOverlay'
    }),
    openCart() {
      if (!this.cartOpen) {
        this.setOverlay('cart')
      }
    },
    closeCart() {
      if (this.cartOpen) {
        this.setOverlay()
      }
    },
    click() {
      if (this.close) {
        this.closeCart()
      } else {
        this.openCart()
      }
    }
  }
}
</script>

<style>
.cart-button {
  --cart-button-gutter: 0.5em;
  --fizz-size: 3px;
  align-items: flex-end;
  display: inline-flex;
  flex-wrap: nowrap;
}

[data-js-focus-visible] .cart-button[data-focus-visible-added] {
  outline: 0;
}

.count {
  display: inline-block;
  line-height: 1;
  margin-right: var(--cart-button-gutter);
  vertical-align: bottom;
}

.close__icon {
  @apply text-xl;
}

.close__icon[class*='left'] {
  transition: transform var(--hover-speed);
}

.cart-button:hover .close__icon[class*='left'],
[data-js-focus-visible]
  .cart-button[data-focus-visible-added]
  .close__icon[class*='left'] {
  transform: translateX(-50%);
}

@keyframes fizz {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-125%);
  }
}

.fizz {
  bottom: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin-bottom: 2px;
  opacity: 0;
  position: absolute;
  right: 2px;
  transition: opacity 0.5s linear 0.25s;
  width: 60%;
}

.has-items .fizz,
.cart-button:hover .fizz,
[data-js-focus-visible] .cart-button[data-focus-visible-added] .fizz {
  opacity: 1;
}

.fizz__pop {
  animation: fizz 0.99s ease-in-out infinite forwards;
  background-color: currentColor;
  border-radius: 100%;
  display: block;
  flex: 0 0 auto;
  height: var(--fizz-size);
  position: relative;
  width: var(--fizz-size);
}

.fizz__pop:nth-child(1) {
  --fizz-size: 2px;
}

.fizz__pop:nth-child(2) {
  animation-delay: 0.33s;
  animation-duration: 0.66s;
  bottom: 6px;
}

.fizz__pop:nth-child(3) {
  animation-delay: 0.66s;
  animation-duration: 0.66s;
}
</style>
