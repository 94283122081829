import { TiHome } from 'react-icons/ti'
import basicEditor from './blocks/basic-editor'
import contentEditor from './blocks/content-editor'
import linkField from './fields/link'
import mediaField from './fields/media'
import socialMedia from './fields/social-media'

export default {
  title: 'Home',
  name: 'home',
  type: 'document',
  icon: TiHome,
  __experimental_actions: ['update', 'publish'],
  fieldsets: [
    {
      name: 'featuredProduct',
      title: 'Featured Product',
      options: {
        collapsible: true
      }
    },
    {
      name: 'splash',
      title: 'Splash',
      options: {
        collapsible: true
      }
    },
    {
      name: 'intro',
      title: 'Introduction',
      options: {
        collapsible: true
      }
    },
    {
      name: 'sampler',
      title: 'Sampler',
      options: {
        collapsible: true
      }
    },
    {
      name: 'zeroproof',
      title: 'Zero Proof',
      options: {
        collapsible: true
      }
    },
    {
      name: 'mood',
      title: 'Mood Section',
      options: {
        collapsible: true
      }
    },
    {
      name: 'water',
      title: 'Sparkling Water',
      options: {
        collapsible: true
      }
    },
    {
      name: 'realitywear',
      title: 'Realitywear',
      options: {
        collapsible: true
      }
    },
    {
      name: 'collab',
      title: 'Featured Collab',
      options: {
        collapsible: true
      }
    },
    {
      name: 'locator',
      title: 'Store Locator',
      options: {
        collapsible: true
      }
    },
    {
      name: 'press',
      title: 'Press',
      options: {
        collapsible: true
      }
    },
    {
      name: 'testimonials',
      title: 'Testimonials',
      options: {
        collapsible: true
      }
    },
    {
      name: 'social',
      title: 'Social Media',
      options: {
        collapsible: true
      }
    },
    {
      name: 'signup',
      title: 'Newsletter',
      options: {
        collapsible: true
      }
    }
  ],
  fields: [
    // featured product
    {
      title: 'Enable Featured Product',
      name: 'featuredProductEnabled',
      type: 'boolean',
      fieldset: 'featuredProduct',
      description:
        'Determines whether the featured product should be displayed on the site'
    },
    {
      title: 'Featured Product Title',
      name: 'featuredProductTitle',
      type: 'string',
      fieldset: 'featuredProduct'
    },
    {
      ...basicEditor,
      title: 'Featured Product Text',
      name: 'featuredProductText',
      fieldset: 'featuredProduct'
    },
    {
      ...linkField,
      title: 'Featured Product CTA',
      name: 'featuredProductCta',
      fieldset: 'featuredProduct'
    },
    {
      title: 'Featured Product CTA Text Color',
      name: 'featuredProductCtaTextColor',
      type: 'color',
      fieldset: 'featuredProduct'
    },
    {
      title: 'Featured Product CTA Button Text Color',
      name: 'featuredProductCtaButtonTextColor',
      type: 'color',
      fieldset: 'featuredProduct'
    },
    {
      title: 'Featured Product CTA Button Background Color',
      name: 'featuredProductCtaButtonBackgroundColor',
      type: 'color',
      fieldset: 'featuredProduct'
    },
    {
      title: 'Featured Product Header Top Gradient Color',
      name: 'featuredProductHeaderTopGradientColor',
      description:
        'If no color is defined here, the default value will be used (#A2B0FF). This gradient overwrite will only work on the home page.',
      type: 'color',
      fieldset: 'featuredProduct'
    },
    {
      title: 'Featured Product Image',
      name: 'featuredProductImage',
      type: 'image',
      fieldset: 'featuredProduct'
    },
    {
      title: 'Featured Product Section Background Image',
      name: 'featuredProductBackgroundImage',
      type: 'image',
      fieldset: 'featuredProduct'
    },
    // splash
    {
      title: 'Splash Title',
      name: 'splashTitle',
      type: 'string',
      fieldset: 'splash'
    },
    {
      ...basicEditor,
      title: 'Splash Text',
      name: 'splashText',
      fieldset: 'splash'
    },
    {
      ...linkField,
      title: 'Splash CTA',
      name: 'splashCta',
      fieldset: 'splash',
      description: 'Defaults to "Shop," which leads to Sparkling Water page'
    },
    {
      title: 'Splash Hero',
      name: 'splashHero',
      type: 'image',
      fieldset: 'splash'
    },
    {
      ...linkField,
      title: 'Floating CTA',
      name: 'floatingCta',
      fieldset: 'splash',
      description: 'the link for the floating CTA'
    },
    {
      title: 'Vertical Layout',
      name: 'splashIsVerticalLayout',
      type: 'boolean',
      fieldset: 'splash',
      description: 'Determines whether the layout should always be vertical'
    },
    {
      title: 'Splash Video (webm)',
      name: 'splashVideoWebm',
      type: 'file',
      fieldset: 'splash',
      options: {
        accept: 'video/webm'
      }
    },
    {
      title: 'Splash Video (mov)',
      name: 'splashVideoMov',
      type: 'file',
      fieldset: 'splash',
      options: {
        accept: 'video/quicktime'
      }
    },
    // intro
    {
      title: 'Intro Title',
      name: 'introTitle',
      type: 'string',
      fieldset: 'intro'
    },
    {
      ...contentEditor,
      title: 'Intro Text',
      name: 'introText',
      fieldset: 'intro'
    },
    // sampler
    {
      title: 'Sampler Title',
      name: 'samplerTitle',
      type: 'string',
      fieldset: 'sampler'
    },
    {
      title: 'Sampler Subtitle',
      name: 'samplerSubtitle',
      type: 'text',
      fieldset: 'sampler'
    },
    {
      title: 'Featured Sampler',
      name: 'featuredSampler',
      type: 'array',
      of: [
        {
          title: 'Product',
          name: 'product',
          type: 'reference',
          to: [{ type: 'product' }]
        }
      ],
      validation: Rule => Rule.max(8),
      fieldset: 'sampler'
    },
    // zero proof
    {
      title: 'Zero Proof Title',
      name: 'zeroproofTitle',
      type: 'string',
      fieldset: 'zeroproof'
    },
    {
      title: 'Zero Proof Subtitle',
      name: 'zeroproofSubtitle',
      type: 'text',
      fieldset: 'zeroproof'
    },
    {
      title: 'Featured Zero Proof',
      name: 'featuredZeroproof',
      type: 'array',
      of: [
        {
          title: 'Product',
          name: 'product',
          type: 'reference',
          to: [{ type: 'product' }]
        }
      ],
      validation: Rule => Rule.max(8),
      fieldset: 'zeroproof'
    },
    // mood section
    {
      title: 'Mood Product Category',
      name: 'moodProductCategory',
      description: 'Used to pull the background color gradients',
      type: 'reference',
      fieldset: 'mood',
      validation: Rule => Rule.required(),
      to: [{ type: 'productCategory' }]
    },
    {
      title: 'Mood Water Section Title',
      name: 'moodWaterTitle',
      type: 'string',
      fieldset: 'mood'
    },
    {
      title: 'Mood Water Section Subtitle',
      name: 'moodWaterSubtitle',
      type: 'text',
      fieldset: 'mood'
    },
    {
      title: 'Featured Mood Water Products',
      name: 'moodWaterProducts',
      type: 'array',
      of: [
        {
          title: 'Product',
          name: 'product',
          type: 'reference',
          to: [{ type: 'product' }]
        }
      ],
      validation: Rule => Rule.max(8),
      fieldset: 'mood'
    },
    {
      title: 'Mood Water Ingredients Window',
      name: 'moodWaterIngredientsWindow',
      type: 'ingredientsWindow',
      fieldset: 'mood'
    },
    {
      title: 'Mood Powders Section Title',
      name: 'moodPowderTitle',
      type: 'string',
      fieldset: 'mood'
    },
    {
      title: 'Mood Powders Section Subtitle',
      name: 'moodPowderSubtitle',
      type: 'text',
      fieldset: 'mood'
    },
    {
      title: 'Featured Mood Powder Products',
      name: 'moodPowderProducts',
      type: 'array',
      of: [
        {
          title: 'Product',
          name: 'product',
          type: 'reference',
          to: [{ type: 'product' }]
        }
      ],
      validation: Rule => Rule.max(8),
      fieldset: 'mood'
    },
    {
      title: 'Mood Powder Ingredients Window',
      name: 'moodPowderIngredientsWindow',
      type: 'ingredientsWindow',
      fieldset: 'mood'
    },
    // sparkling water
    {
      title: 'Sparkling Water Title',
      name: 'waterTitle',
      type: 'string',
      fieldset: 'water'
    },
    {
      title: 'Sparkling Water Subtitle',
      name: 'waterSubtitle',
      type: 'text',
      fieldset: 'water'
    },
    {
      title: 'Featured Sparkling Water',
      name: 'featuredWater',
      type: 'array',
      of: [
        {
          title: 'Product',
          name: 'product',
          type: 'reference',
          to: [{ type: 'product' }]
        }
      ],
      validation: Rule => Rule.max(8),
      fieldset: 'water'
    },
    {
      title: 'Sparkling Water Ingredients Window',
      name: 'waterIngredientsWindow',
      type: 'ingredientsWindow',
      fieldset: 'water'
    },
    // realitywear
    {
      title: 'Realitywear Title',
      name: 'realitywearTitle',
      type: 'string',
      fieldset: 'realitywear'
    },
    {
      ...basicEditor,
      title: 'Realitywear Text',
      name: 'realitywearText',
      fieldset: 'realitywear'
    },
    {
      title: 'Featured Realitywear',
      name: 'featuredRealitywear',
      type: 'array',
      of: [
        {
          title: 'Product',
          name: 'product',
          type: 'reference',
          to: [{ type: 'product' }]
        }
      ],
      fieldset: 'realitywear'
    },
    // featured collab
    {
      ...mediaField,
      title: 'Featured Collab Hero',
      name: 'collabHero',
      fieldset: 'collab'
    },
    {
      title: 'Featured Collab Title',
      name: 'collabTitle',
      type: 'string',
      fieldset: 'collab'
    },
    {
      ...basicEditor,
      title: 'Featured Collab Text',
      name: 'collabText',
      fieldset: 'collab'
    },
    {
      ...linkField,
      title: 'Featured Collab CTA',
      name: 'collabCta',
      fieldset: 'collab'
    },
    // locator
    {
      title: 'Store Finder Title',
      name: 'locatorTitle',
      type: 'string',
      fieldset: 'locator'
    },
    {
      ...basicEditor,
      title: 'Store Finder Text',
      name: 'locatorText',
      fieldset: 'locator'
    },
    {
      ...linkField,
      title: 'Store Finder CTA',
      name: 'locatorCta',
      fieldset: 'locator'
    },
    {
      ...mediaField,
      title: 'Store Finder Hero',
      name: 'locatorHero',
      fieldset: 'locator'
    },
    // press
    {
      title: 'Press Title',
      name: 'pressTitle',
      type: 'string',
      fieldset: 'press'
    },
    {
      title: 'Featured Press',
      name: 'featuredPress',
      type: 'array',
      of: [
        {
          title: 'Press',
          name: 'press',
          type: 'reference',
          to: [{ type: 'press' }]
        }
      ],
      validation: Rule => Rule.max(4),
      fieldset: 'press'
    },
    {
      ...socialMedia,
      fieldset: 'social'
    },
    // testimonials
    {
      title: 'Testimonials',
      name: 'testimonials',
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [{ type: 'testimonial' }]
        }
      ],
      validation: Rule => Rule.max(8),
      fieldset: 'testimonials'
    },
    // signup
    {
      title: 'Newsletter Title',
      name: 'signupTitle',
      type: 'string',
      fieldset: 'signup'
    },
    {
      ...basicEditor,
      title: 'Newsletter Text',
      name: 'signupText',
      fieldset: 'signup'
    }
  ]
}
