// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../images/bullet.svg?data");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ellipsis-loading{display:inline-block;vertical-align:bottom}@keyframes ellipsis-loading{0%{opacity:0}50%{opacity:1}to{opacity:0}}.ellipsis-loading__dot{animation:ellipsis-loading 1s infinite;opacity:0}.ellipsis-loading__dot:first-child{animation-delay:0s}.ellipsis-loading__dot:nth-child(2){animation-delay:.1s}.ellipsis-loading__dot:nth-child(3){animation-delay:.2s}.ellipsis-bg{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:0 100%;background-repeat:repeat-x;background-size:6px 18px;image-rendering:crisp-edges;min-height:1em;vertical-align:middle}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
