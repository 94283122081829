<template functional>
  <span
    :ref="data.ref"
    :style="[data.style, data.staticStyle]"
    :class="[data.class, data.staticClass]"
    class="badge bg-blue text-white"
    ><slot
  /></span>
</template>

<script>
export default {
  functional: true
}
</script>

<style>
.badge {
  --badge-size: 5em;
  align-items: center;
  border-radius: 100%;
  box-sizing: content-box;
  display: inline-flex;
  font-weight: theme('fontWeight.medium');
  height: var(--badge-size);
  justify-content: center;
  line-height: 1.1;
  padding: 0.5em;
  position: absolute;
  width: var(--badge-size);
}
</style>
