<template>
  <marquee
    ref="marquee"
    v-slot="{ visible, dummy }"
    :paused="dismissed"
    :speed="speed"
    :fixed-speed="fixedSpeed"
    :visibility="visibility"
    static
    class="drifting-cloud"
  >
    <div :style="{ width }" class="drifting-cloud__width">
      <div :style="style" class="drifting-cloud__cloud">
        <cloud
          v-if="visible || visibility === false"
          :image="cloudImage"
          :distance="distance"
          :visible="visible"
          :sky-id="skyId || $_uid"
          :restart-after="restartAfter"
          :dismissed="cloudDismissed"
          :dismissable="!dummy && dismissable"
          @dismiss="debouncedDismiss"
          @dismiss-complete="debouncedDismissComplete"
        />
      </div>
    </div>
  </marquee>
</template>

<script>
import debounce from 'lodash/debounce'
import { randomCloudImage } from '~/util/clouds'

export default {
  name: 'DriftingCloud',

  props: {
    skyId: {
      default: undefined,
      type: Number
    },
    column: {
      default: undefined,
      type: [String, Number]
    },
    image: {
      default: undefined,
      type: [Number, String]
    },
    distance: {
      default: undefined,
      type: [Number, String]
    },
    speed: {
      default: undefined,
      type: [Number, String]
    },
    dismissed: {
      default: false,
      type: Boolean
    },
    dismissable: {
      default: true,
      type: Boolean
    },
    fixedSpeed: {
      default: false,
      type: Boolean
    },
    width: {
      default: 'var(--win-width)',
      type: String
    },
    visibility: {
      default: undefined,
      type: [Boolean, Object]
    },
    restartAfter: {
      default: undefined,
      type: [String, Number]
    }
  },

  data() {
    return {
      cloudImage: this.image || randomCloudImage(),
      cloudDismissed: this.dismissed
    }
  },

  computed: {
    style() {
      return {
        webkitGridColumn: this.column,
        gridColumn: this.column
      }
    }
  },

  watch: {
    image(image) {
      if (image) {
        this.cloudImage = image
      }
    },
    dismissed(dismissed) {
      this.cloudDismissed = dismissed
    }
  },

  created() {
    this.debouncedDismiss = debounce(this.dismiss, 50)
    this.debouncedDismissComplete = debounce(this.dismissComplete, 50)
  },

  methods: {
    restart() {
      this.cloudDismissed = false
    },
    dismiss(dismissed) {
      this.cloudDismissed = dismissed
      this.$emit('dismiss', dismissed)
    },
    dismissComplete() {
      const marquee = this.$refs.marquee
      if (marquee) {
        marquee.refresh()
      }
      this.$emit('dismiss-complete')
    }
  }
}
</script>

<style lang="scss">
/*! purgecss start ignore */

.drifting-cloud {
  --column-count: 4;
}

.drifting-cloud__width,
.drifting-cloud__cloud {
  height: 100%;
  min-height: 2em;
}

.drifting-cloud .marquee,
.drifting-cloud .marquee__wrap,
.drifting-cloud .marquee__scroll {
  height: 100%;
  overflow: visible;
}

.drifting-cloud .marquee__wrap {
  align-items: center;
}

.drifting-cloud__width {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-template-columns: repeat(var(--column-count), 1fr);
  grid-template-rows: 100%;
}

.drifting-cloud__cloud {
  align-items: center;
  display: flex !important;
  justify-content: center;
}

.drifting-cloud__cloud .cloud .cloud__wrap {
  align-items: center;
  display: flex;
}

.drifting-cloud__cloud .cloud .cloud__primary {
  flex: 0 1 auto;
}

@screen lg {
  .drifting-cloud {
    --column-count: 6;
  }
}

/*! purgecss end ignore */
</style>
