<template functional>
  <button
    :ref="data.ref"
    :class="[data.class, data.staticClass, { 'is-active': props.active }]"
    :style="[data.style, data.staticStyle]"
    class="burger"
    v-on="listeners"
  >
    <span class="burger__lines" />
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Burger',

  functional: true,

  props: {
    active: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style>
.burger {
  --line-spacing: 0.5em; /* 8px */
  --line-height: 0.125em; /* 2px */
  --line-width: 1.5em; /* 20px */
  --line-speed: 0.3s;
  --line-color: var(--scheme-color);
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: var(--line-width);
  padding: 0.125em;
  transition: var(--line-speed) ease;
  width: var(--line-width);
}

.burger:focus {
  outline: 0;
}

.burger__lines,
.burger__lines::before,
.burger__lines::after {
  background-color: var(--line-color);
  border-radius: var(--line-height);
  display: block;
  transition: all var(--line-speed);
}

.burger__lines {
  height: var(--line-height);
  position: relative;
  width: var(--line-width);
}

.burger__lines::before,
.burger__lines::after {
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}

.burger__lines::before {
  transform: translateY(calc(-1 * var(--line-spacing)));
}

.burger__lines::after {
  transform: translateY(var(--line-spacing));
}

.is-active .burger__lines {
  background-color: transparent;
}

.is-active .burger__lines::before {
  transform: rotate(45deg);
}

.is-active .burger__lines::after {
  transform: rotate(-45deg);
}
</style>
