/**
 * Formats line items to Storefront API
 * CartLineUpdateInput schema:
 * {
 *   id
 *   quantity
 *   attributes
 * }
 */

export default edges =>
  edges.map(({ node }) => ({
    id: node.id,
    merchandiseId: node.merchandise.id,
    quantity: node.quantity,
    attributes: node.attributes.map(a => ({
      key: a.key,
      value: a.value
    })),
    sellingPlanId: node.sellingPlanAllocation?.sellingPlan?.id
  }))
