<template>
  <div :is="tag" :class="classes" class="hover" @click="toggle">
    <div class="hover__default">
      <slot />
    </div>
    <div class="hover__hover">
      <slot name="hover" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hover',

  props: {
    tag: {
      default: 'div',
      type: String
    },
    hasHover: {
      default: false,
      type: Boolean
    },
    smooth: {
      default: false,
      type: Boolean
    }
  },

  data: () => ({
    hover: false
  }),

  computed: {
    classes() {
      return {
        'hover--smooth': this.smooth,
        'hover--not-smooth': !this.smooth,
        'has-hover': this.hasHover,
        'is-hover': this.hover
      }
    }
  },

  methods: {
    toggle() {
      this.hover = !this.hover
    }
  }
}
</script>

<style>
.hover {
  position: relative;
}

.hover.has-hover {
  cursor: pointer;
}

.hover__hover {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.hover--smooth .hover__default,
.hover--smooth .hover__hover {
  transition: opacity var(--hover-speed) linear;
}

.hover--not-smooth .hover__default,
.hover--not-smooth .hover__hover {
  transition: none !important;
}

.hover--smooth.is-hover .hover__default {
  opacity: 1 !important;
}

.hover.has-hover.is-hover .hover__default {
  opacity: 0;
}

.hover.is-hover .hover__hover {
  opacity: 1;
}

@media (hover) {
  .hover.has-hover .hover__hover,
  .hover.has-hover:hover .hover__default {
    opacity: 0;
  }

  .hover--smooth.has-hover:hover .hover__default {
    opacity: 1;
  }

  .hover:hover .hover__hover {
    opacity: 1;
  }
}
</style>
