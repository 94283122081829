import React from 'react'
import { useGetImageUrl } from '../../utils'

export default function MediaBlockPreview({ media, position, video }) {
  const imageUrlBuilder = useGetImageUrl()
  if (media || video) {
    return (
      <figure style={{ margin: 0, textAlign: `${position || 'center'}` }}>
        {media?.asset && (
          <img
            alt=""
            height="150"
            src={imageUrlBuilder
              .image(media.asset)
              .width(300)
              .height(300)
              .url()}
            style={{
              display: 'inline-block',
              maxWidth: '100%',
              height: 'auto'
            }}
            width="150"
          />
        )}
        {video && 'Video'}
      </figure>
    )
  }
  return null
}
