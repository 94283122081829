<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="name"
    tag="div"
    :rules="rules"
    class="flex-auto lowercase whitespace-no-wrap"
    :style="styles"
  >
    <div class="relative">
      <fade>
        <label
          v-if="!focused && !value"
          :for="`${name}-${$_uid}-input`"
          class="absolute left-0 font-medium transform -translate-y-1/2"
          >{{ label || name }}</label
        >
      </fade>
      <input
        :id="`${name}-${$_uid}-input`"
        :type="type"
        class="block w-full"
        :name="name"
        :value="value"
        :required="required"
        v-on="$listeners"
        @focus="focus"
        @blur="blur"
      />
      <fade appear>
        <ul
          v-if="errors && Object.values(errors).flat().length"
          class="absolute left-0 w-full"
        >
          <li
            v-for="(error, i) in Object.values(errors).flat()"
            :key="`error-${i}`"
            class="text-xs opacity-75 pt-1/4em"
          >
            {{ error }}
          </li>
        </ul>
      </fade>
    </div>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'FormInput',

  props: {
    value: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: undefined,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    textColor: {
      type: String,
      default: undefined
    }
  },

  data: () => ({
    state: 'initial',
    focused: false
  }),

  computed: {
    rules() {
      const rules = [
        this.required && 'required',
        ['email', 'url'].includes(this.type) && this.type
      ]
      return rules.filter(Boolean).join('|')
    },
    styles() {
      const textColor = this.textColor
      const style = {}
      if (textColor) {
        style['--field-text-color'] = textColor
      }
      return style
    }
  },

  methods: {
    focus() {
      this.focused = true
    },
    blur() {
      this.focused = false
    }
  }
}
</script>

<style scoped>
label,
input,
ul {
  color: var(--field-text-color, var(--color-waterloo));
}

label,
input {
  @apply py-1/2em;
}

label {
  pointer-events: none;
}

input {
  border-bottom: 1px solid currentColor;
}

input:focus {
  outline: 0;
}
</style>
