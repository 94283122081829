import { MdPercent } from 'react-icons/md'

export default {
  name: 'promotionalProduct',
  title: 'Promotional Product',
  type: 'document',
  icon: MdPercent,
  fields: [
    {
      title: 'Size',
      name: 'size',
      type: 'string',
      validation: Rule => Rule.required().error('A size is required.')
    },
    {
      title: 'Target Product SKUs',
      name: 'targetProductSkus',
      type: 'array',
      of: [{ type: 'string' }],
      description:
        'The list of promotional SKUs that will be added to the order. Always included once if one item in the cart matches the pack size.'
    },
    {
      title: 'Limited Target Product SKUs',
      name: 'limitedTargetProductSkus',
      type: 'array',
      of: [{ type: 'string' }],
      description:
        'The list of promotional SKUs that will be added to the order. Included if only exactly one product of the pack size is in the cart.'
    }
  ],
  preview: {
    select: {
      title: 'size'
    },
    prepare: ({ title = 'New Promotion' }) => ({ title })
  }
}
