import { MdPushPin } from 'react-icons/md'

export default {
  title: 'Global Banner',
  icon: MdPushPin,
  name: 'globalBanner',
  type: 'document',
  __experimental_actions: ['update', 'publish'],
  fields: [
    {
      title: 'Active',
      name: 'isActive',
      type: 'boolean',
      description:
        'Warning: Only one banner can be displayed at a time. Activating the global banner will override the discount banner.'
    },
    {
      title: 'Background Color',
      name: 'backgroundColor',
      type: 'color'
    },
    {
      title: 'Text Color',
      name: 'textColor',
      type: 'color'
    },
    {
      title: 'Use Scrolling Banners',
      name: 'isScrolling',
      type: 'boolean',
      description:
        'Scrolling banners are defined in the Scrolling Banners section below. If not activated, the banner defined in the Static Banner section will be used.'
    },
    {
      title: 'Scrolling Banners',
      name: 'banners',
      type: 'array',
      of: [
        {
          title: 'Banner',
          name: 'banner',
          type: 'object',
          fields: [
            {
              title: 'CBD',
              name: 'cbd',
              type: 'boolean',
              description:
                'If CBD is enabled, this record will not be shown on the non-CBD storefront.'
            },
            {
              title: 'Title',
              name: 'title',
              type: 'string',
              description:
                'By default, the whole text will be turned into a link (if a page or URL is defined below). Use double accolades to only turn a specific part into a link. Example: "To check this out, {{click here}}!".',
              validation: Rule => Rule.required()
            },
            {
              title: 'Page',
              name: 'page',
              type: 'reference',
              to: [{ type: 'page' }]
            },
            {
              title: 'Banner URL',
              name: 'url',
              type: 'url',
              validation: Rule => Rule.uri({ allowRelative: true })
            },
            {
              title: 'Open this link in a new window',
              name: 'external',
              type: 'boolean'
            }
          ]
        }
      ],
      // validation: Rule =>
      fieldSet: 'banners'
    },
    {
      title: 'Static Banner',
      name: 'staticBanner',
      type: 'object',
      fields: [
        {
          title: 'Title',
          name: 'title',
          type: 'string',
          description:
            'By default, the whole text will be turned into a link (if a page or URL is defined below). Use double accolades to only turn a specific part into a link. Example: "To check this out, {{click here}}!".',
          validation: Rule => Rule.required()
        },
        {
          title: 'Page',
          name: 'page',
          type: 'reference',
          to: [{ type: 'page' }]
        },
        {
          title: 'Banner URL',
          name: 'url',
          type: 'url',
          validation: Rule => Rule.uri({ allowRelative: true })
        },
        {
          title: 'Open this link in a new window',
          name: 'external',
          type: 'boolean'
        }
      ]
    }
  ],
  initialValue: {
    isActive: false,
    backgroundColor: {
      _type: 'color',
      alpha: 1,
      hex: '#000000',
      hsl: {
        _type: 'hslaColor',
        h: 200.00000000000006,
        s: 0,
        l: 0,
        a: 1
      },
      hsv: {
        _type: 'hsvaColor',
        h: 200.00000000000006,
        s: 0,
        v: 0,
        a: 1
      },
      rgb: {
        _type: 'rgbaColor',
        r: 0,
        g: 0,
        b: 0,
        a: 1
      }
    },
    textColor: {
      _type: 'color',
      hex: '#ffffff',
      alpha: 1,
      hsl: {
        _type: 'hslaColor',
        h: 0,
        s: 0,
        l: 1,
        a: 1
      },
      hsv: {
        _type: 'hsvaColor',
        h: 0,
        s: 0,
        v: 1,
        a: 1
      },
      rgb: {
        _type: 'rgbaColor',
        r: 255,
        g: 255,
        b: 255,
        a: 1
      }
    }
  }
}
