<template>
  <div
    :style="style"
    class="inset-0 wave-background--vertical"
    :class="{ 'wave-background--is-animating': isAnimating }"
  />
</template>

<script>
const WAVE_LENGTH = '640px'

export default {
  name: 'WaveBackground',
  props: {
    colors: {
      required: true,
      type: Array
    },
    backgroundImage: {
      default: undefined,
      type: String
    },
    waveWidth: {
      type: Number,
      default: 40
    },
    isAnimating: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    gradientColors() {
      return this.colors.join(', ')
    },
    style() {
      return {
        'background-color': this.colors[0],
        'background-image': `url(${this.backgroundImage}), linear-gradient(${this.gradientColors})`,
        '--wave-width': this.waveWidth + 'px',
        '--wave-length': WAVE_LENGTH
      }
    }
  }
}
</script>

<style scoped>
@keyframes move-vertical {
  0% {
    mask-position: 0% 0%, 100% 0%;
  }

  100% {
    mask-position: 0% 0%, 100% var(--wave-length);
  }
}

/* we don't want the waves mask to skew as the box gets wider/taller so we add
a solid black box that sits next to the svg */
.wave-background--vertical {
  --wave-width: 40px;
  animation: move-vertical linear 12s 0s reverse infinite;
  animation-play-state: paused;
  background-position: top center;
  background-repeat: repeat;
  background-size: 100% auto;
  height: 100%;
  mask-clip: border-box;
  mask-image: linear-gradient(black, black),
    url('~assets/images/wave-vertical.svg');
  mask-position: 0% 0%, 100% 0%;
  mask-repeat: no-repeat no-repeat, no-repeat repeat;
  mask-size: calc(100% - var(--wave-width)) 100%,
    var(--wave-width) var(--wave-length);
  position: absolute;

  /* this extra pixel is required in mobile safari for some reason
  otherwise there is a one pixel horizontal gap for the wave */
  width: calc(100% + 1px);
}

.wave-background--is-animating {
  animation-play-state: running;
}
</style>
