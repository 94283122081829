<template functional>
  <cta
    :ref="data.ref"
    :class="[data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    tag="a"
    href="#main"
    fill
    class="skip-main fixed"
    @click="$options.methods.click"
  >
    Skip to main content
  </cta>
</template>

<script>
export default {
  name: 'SkipMain',

  functional: true,

  methods: {
    click(e) {
      const main = document.querySelector('#main')
      if (typeof window.scrollTo !== 'undefined') {
        scrollTo({
          top: main.offsetTop,
          left: main.offsetLeft,
          behavior: 'smooth'
        })
        e.preventDefault()
      }
    }
  }
}
</script>

<style>
.cta.skip-main {
  left: 2em;
  opacity: 0;
  pointer-events: none;
  top: 0;
  transform: translateY(-100%);
  transition: opacity var(--fade-speed), transform var(--fade-speed);
  z-index: theme('zIndex.skip-main');
}

.cta.skip-main:focus,
.cta.skip-main:active {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(1rem);
}
</style>
