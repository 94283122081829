/**
 * Extracts the ID from the Shopify GID
 */
export default gid => {
  if (gid) {
    return gid.split('/').pop()
  }

  return gid
}
