import React, { useEffect, useState } from 'react'
import { Card, Stack, Text } from '@sanity/ui'

import { toString, useClient } from '../../utils'

export default function TestimonialsPreview({ testimonials }) {
  const client = useClient()
  const [items, setItems] = useState([])

  useEffect(() => {
    if (client && testimonials) {
      client
        .getDocuments(testimonials.map(o => o._ref))
        .then(data => setItems(data))
    }
  }, [setItems, client, testimonials])

  return (
    <React.Fragment>
      <p style={{ margin: '0.5rem 0.5rem 0.75rem' }}>Testimonials</p>
      {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
      <ul
        role="list"
        style={{
          display: 'grid',
          gap: '0.25rem',
          gridTemplateColumns: 'repeat(auto-fit, minmax(7.5rem, 1fr))',
          listStyle: 'none',
          margin: 0,
          padding: 0,
          width: '100%'
        }}
      >
        {items?.map(({ _id, attribution, quote }) => (
          <Card as="li" key={_id} padding={3} radius={2} shadow={1}>
            <Stack as="blockquote" space={4}>
              <Text size={1} textOverflow="ellipsis">
                {toString(quote)}
              </Text>
              <Text align="right" muted size={1}>
                &ndash; {attribution}
              </Text>
            </Stack>
          </Card>
        ))}
      </ul>
    </React.Fragment>
  )
}
