import { gsap } from 'gsap'

const scroll = (y, resolve) =>
  gsap.to(window, {
    scrollTo: {
      y,
      autoKill: true,
      onAutoKill() {
        return resolve({ x: 0, y })
      }
    },
    duration: 1,
    ease: 'expo.out',
    onComplete() {
      return resolve({ x: 0, y })
    }
  })

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'
    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })
    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}

export default function(to, from) {
  let scrollTo = 0
  if (to.hash) {
    let hash = to.hash

    // Fixes an issue where query params are wrapped with
    // the anchor hash
    if (hash.includes('?')) {
      hash = hash.split('?')[0]
    }

    const headerHeight = this.app.$store.state.screen.headerHeight
    const el = document.querySelector(hash)
    scrollTo = Math.max(el.offsetTop - headerHeight, 0)
  }
  // triggerScroll is only fired when a new component is loaded
  if (to.path === from.path && to.hash !== from.hash) {
    window.$nuxt.$nextTick(() => window.$nuxt.$emit('triggerScroll'))
  }
  this.app.$store.commit('screen/setTransitioning', true)
  return new Promise(resolve => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => scroll(scrollTo, resolve))
  })
}
