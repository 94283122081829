import groq from 'groq'
import pageFields from '../fields/page'
import promotionalProductFields from '../fields/promotional-product'

export default groq`
  *[_type == "promotionalProduct"]{
    ...${pageFields},
    ...${promotionalProductFields}
  }
`
