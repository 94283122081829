import React, { useId, useState } from 'react'
import { gql } from '@apollo/client'
import { Autocomplete, Avatar, Box, Card, Flex, Text } from '@sanity/ui'
import { set, unset } from 'sanity'

import { escapeRegExp, useShopifyQuery } from '../../utils'

const query = gql`
  query {
    products(first: 250) {
      edges {
        node {
          title
          variants(first: 250) {
            edges {
              node {
                id
                title
                image {
                  url(transform: { maxWidth: 150 })
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function ShopifyProductVariantInput({
  elementProps,
  onChange,
  value: controlledValue
}) {
  const id = useId()
  const [value, setValue] = useState(controlledValue)
  const { data, error, loading } = useShopifyQuery(query)

  const options = data?.products.edges.flatMap(({ node: product }) => {
    return product.variants.edges.map(({ node: variant }) => ({
      value: variant.id,
      image: variant.image?.url,
      payload: {
        id: variant.id,
        title: `${product.title} ${variant.title}`,
        productTitle: product.title,
        variantTitle: variant.title
      }
    }))
  })

  const handleChange = next => {
    const { payload } = options?.find(o => o.value === next) || {}
    onChange(payload ? set(payload) : unset())
    setValue(payload)
  }

  if (error) {
    console.error("Couldn't complete Shopify API request.", error)
    return (
      <Card padding={[3, 3, 4]} radius={2} shadow={1} tone="critical">
        <Text align="center" size={[1, 2]}>
          Something went wrong! See console for error details.
        </Text>
      </Card>
    )
  }

  return (
    <Autocomplete
      {...elementProps}
      id={id}
      filterOption={(str, option) => {
        const re = new RegExp(escapeRegExp(str), 'ig')
        return re.test(option.payload.title)
      }}
      fontSize={[2, 2, 3]}
      loading={loading}
      options={options}
      openButton
      onChange={handleChange}
      padding={[3, 3, 4]}
      renderOption={({ image, payload }) => (
        <Card as="button">
          <Flex align="center">
            {image && (
              <Box paddingLeft={3} paddingY={2}>
                <Avatar color="gray" size={[0, 0, 1]} src={image} />
              </Box>
            )}
            <Box flex={1} padding={3}>
              <Text size={[2, 2, 3]}>{payload.title}</Text>
            </Box>
          </Flex>
        </Card>
      )}
      renderValue={(val, option) =>
        option?.payload.title || options?.find(o => o.val === val) || val
      }
      value={value?.id}
    />
  )
}
