export default {
  title: 'Ingredients Window',
  name: 'ingredientsWindow',
  type: 'object',
  fields: [
    {
      title: 'Ingredients Title',
      name: 'title',
      type: 'string',
      validation: Rule => Rule.required()
    },
    {
      title: 'Ingredients Background Color',
      name: 'backgroundColor',
      type: 'color'
    },
    {
      title: 'Ingredients',
      name: 'ingredients',
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [{ type: 'ingredient' }]
        }
      ],
      validation: Rule =>
        Rule.max(4).error(
          "Homepage can't have more than 4 highlighted ingredients"
        )
    }
  ]
}
