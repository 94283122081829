import Vue from 'vue'
import {
  Swiper as SwiperClass,
  Pagination,
  Lazy,
  EffectFade,
  Autoplay,
  A11y,
  Zoom
} from 'swiper/core'

import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

SwiperClass.use([Pagination, Lazy, EffectFade, Autoplay, A11y, Zoom])

Vue.use(getAwesomeSwiper(SwiperClass))
