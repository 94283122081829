<template>
  <div
    :is="tag"
    v-observe-visibility="visibilityChange"
    v-resize="handleResize"
    :class="classes"
    class="scroller"
  >
    <template>
      <slot />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Scroller',

  props: {
    tag: {
      default: 'div',
      type: String
    },
    target: {
      default: 1,
      type: Number
    }
  },

  data: () => ({
    ratioVisible: 0,
    height: 0,
    visible: false,
    top: undefined
  }),

  computed: {
    ...mapState('screen', {
      winHeight: state => state.height
    }),
    classes() {
      return {
        'is-visible': this.visible,
        'is-scrolled': this.ratioVisible >= this.target
      }
    }
  },

  mounted() {
    this.handleResize()

    // we need this because the elements on the page above
    // this might change height after we render this initially
    this.$bus.$on('documentMutated', this.handleResize)
  },

  beforeDestroy() {
    this.scrollListenerDestroy()
    this.$bus.$off('documentMutated', this.handleResize)
  },

  methods: {
    handleResize() {
      const rect = this.$el.getBoundingClientRect()
      this.height = rect.height
      this.top = rect.top + window.pageYOffset
    },

    visibilityChange(visible, entry) {
      this.visible = visible
      if (visible) {
        this.scrollListenerCreate()
      } else {
        this.scrollListenerDestroy()
      }
      this.$emit('visible', visible)
    },

    scrollListenerCreate() {
      window.addEventListener('scroll', this.scrollHandle, { passive: true })
    },

    scrollListenerDestroy() {
      window.removeEventListener('scroll', this.scrollHandle, { passive: true })
    },

    scrollHandle() {
      const { scrollY } = window
      const { top, winHeight, height } = this
      const scrollWinBottom = scrollY + winHeight
      const pixelsVisible = scrollWinBottom - top
      const ratioVisible = Math.min(pixelsVisible / height, 1)
      this.ratioVisible = ratioVisible
      this.$emit('update', ratioVisible)
    }
  }
}
</script>

<style>
.scroller {
  opacity: 0;
  transition: opacity var(--fade-speed) 0.1s;
  visibility: hidden;
}

.scroller.is-visible {
  opacity: 1;
  visibility: visible;
}
</style>
