import documentPath from '~/util/sanity/get-document-path'
import {
  getDocumentTextColor as documentTextColor,
  getDocumentBackgroundColors as documentBackgroundColors,
  getDocumentBackgroundColor as documentColor,
  getDocumentColors as documentColors
} from '~/util/sanity/document-color'

export default {
  methods: {
    documentPath,
    documentTextColor,
    documentBackgroundColors,
    documentColor,
    documentColors
  }
}
