export default {
  title: 'Video',
  name: 'video',
  type: 'object',
  fields: [
    {
      title: 'Video File',
      name: 'mux',
      type: 'mux.video'
    },
    {
      title: 'Thumbnail',
      name: 'thumbnail',
      type: 'image'
    },
    {
      title: 'Loop',
      name: 'loop',
      type: 'boolean'
    },
    {
      title: 'Caption',
      name: 'caption',
      type: 'string'
    },
    {
      title: 'Attribution',
      name: 'attribution',
      type: 'string'
    }
  ],
  preview: {
    select: {
      caption: 'caption'
    },
    prepare: ({ caption }) => ({ title: caption || 'Video' })
  }
}
