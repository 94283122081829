import { IoIosDocument } from 'react-icons/io'
import colorsField from './fields/colors'
import contentEditor from './blocks/content-editor'

export default {
  title: 'Pages',
  name: 'page',
  type: 'document',
  icon: IoIosDocument,
  fields: [
    {
      title: 'Title',
      name: 'title',
      type: 'string',
      validation: Rule => Rule.required().error('A title is required.')
    },
    {
      title: 'Slug',
      name: 'slug',
      type: 'slug',
      description: 'The URL path this page will exist at',
      options: {
        source: 'title',
        maxLength: 96
      },
      validation: Rule => Rule.required().error('A slug is required.')
    },
    {
      title: 'Short Description',
      name: 'description',
      type: 'text',
      rows: 2
    },
    {
      title: 'Poster',
      name: 'poster',
      type: 'image',
      description:
        'Representative image used in page sharing and search engine results'
    },
    {
      ...contentEditor,
      title: 'Content',
      name: 'content'
    },
    {
      ...contentEditor,
      title: 'Non-CBD Content',
      name: 'nonCBDContent',
      description:
        'If present, the content from this field will be used on the non-CBD storefront instead of the default content value.'
    },
    colorsField
  ],
  preview: {
    select: {
      title: 'title',
      media: 'poster'
    }
  }
}
