import { IoMdImages } from 'react-icons/io'

import galleryField from '../fields/gallery'
import { GalleryBlockPreview } from '../../components'

export default {
  title: '2-Up/Gallery',
  name: 'gallery',
  type: 'object',
  fields: [galleryField],
  preview: {
    select: {
      gallery: 'gallery'
    }
  },
  components: {
    preview: GalleryBlockPreview
  },
  icon: IoMdImages,
  description: 'Displays a special 2-up variant if less than 3 media are used'
}
