import groq from 'groq'

export default groq`{
  _id,
  _type,
  cbd,
  title,
  "slug": slug.current,
  colors,
  poster
}`
