<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="name"
    tag="div"
    :rules="required ? 'required' : ''"
    class="flex-auto lowercase whitespace-no-wrap"
    :style="styles"
  >
    <div class="relative">
      <fade>
        <label
          v-if="!focused && !value"
          :for="`${name}-${$_uid}-input`"
          class="absolute top-0 left-0 font-medium transform"
          >{{ label || name }}</label
        >
      </fade>
      <textarea
        :id="`${name}-${$_uid}-input`"
        :name="name"
        :required="required"
        :rows="rows"
        :value="value"
        class="block w-full"
        @blur="blur"
        @focus="focus"
        v-on="$listeners"
      />
      <fade appear>
        <ul
          v-if="errors && Object.values(errors).flat().length"
          class="absolute left-0 w-full"
        >
          <li
            v-for="(error, i) in Object.values(errors).flat()"
            :key="`error-${i}`"
            class="text-xs opacity-75 mb-1/2em"
          >
            {{ error }}
          </li>
        </ul>
      </fade>
    </div>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'FormTextarea',

  props: {
    value: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 5
    },
    label: {
      type: String,
      default: undefined,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    textColor: {
      type: String,
      default: undefined
    }
  },

  data: () => ({
    state: 'initial',
    focused: false
  }),

  computed: {
    styles() {
      const textColor = this.textColor
      const style = {}
      if (textColor) {
        style['--field-text-color'] = textColor
      }
      return style
    }
  },

  methods: {
    focus() {
      this.focused = true
    },
    blur() {
      this.focused = false
    }
  }
}
</script>

<style scoped>
label,
textarea,
ul {
  @apply py-1/2em;
  color: var(--field-text-color, var(--color-waterloo));
}

label {
  pointer-events: none;
}

textarea {
  border-bottom: 1px solid currentColor;
}

textarea:focus {
  outline: 0;
}
</style>
