<template functional>
  <div
    :ref="data.ref"
    :class="[data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    class="pill flex"
    v-on="listeners"
  >
    <div class="pill__content">
      <slot />
    </div>
    <div class="pill__content pill__content--notch"><slot name="notch" /></div>
  </div>
</template>

<script>
export default {
  name: 'Pill',

  functional: true,

  props: {
    tag: {
      default: 'div',
      type: String
    }
  },

  render(h, { props, data, children }) {
    const el = props.tag
    return h(el, data, children)
  }
}
</script>

<style>
.pill {
  border: var(--pill-border);
  border-radius: calc(var(--pill-gutter-x) * 1.5);
  line-height: theme('lineHeight.snug');
  overflow: hidden;
  user-select: none;
}

.pill__content {
  background-color: var(--pill-bg);
  box-sizing: content-box;
  display: block;
  flex: 1 1 auto;
  min-height: var(--pill-height);
  padding: var(--pill-gutter-y) var(--pill-gutter-x);
}

.pill__content--notch {
  background-color: var(--pill-notch);
  border-left: var(--pill-border);
  flex: 0 1 0;
}

.pill__content--notch:empty {
  display: none;
}
</style>
