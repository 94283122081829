export default {
  title: 'Colors',
  name: 'colors',
  type: 'object',
  options: {
    collapsible: true
  },
  fields: [
    {
      title: 'Text Color',
      name: 'textColor',
      type: 'color',
      description: 'Text and UI color'
    },
    {
      title: 'Background Colors',
      name: 'backgroundColors',
      type: 'array',
      description: "Colors that make up the page's background",
      of: [
        {
          title: 'Color',
          name: 'color',
          type: 'color'
        }
      ],
      validation: Rule => Rule.min(1).max(4)
    }
  ]
}
