import groq from 'groq'
import pageFields from './page'

export default groq`{
  cbd,
  title,
  defined(page) => {
    "page": page->{ ...${pageFields} }
  },
  url,
  external
}`
