<script>
import { gsap } from 'gsap'

export default {
  name: 'Slide',

  functional: true,

  render(createElement, ctx) {
    const props = {
      name: 'slide',
      css: false,
      tag: 'div',
      fade: false,
      direction: 'left',
      speed: 1, // seconds
      ...ctx.props
    }
    const speed =
      typeof props.speed === 'string' ? parseFloat(props.speed) : props.speed
    const start = props.direction === 'left' ? '100%' : '-100%'
    const end = '0%'
    const data = {
      props,
      attrs: ctx.props,
      on: {
        beforeEnter(el) {
          const display = el.dataset.display || 'block'
          el.style.display = display
          if (props.fade) {
            el.style.opacity = '0'
            el.style.visibility = 'hidden'
          }
          el.style.transform = `translate3d(${start}, 0, 0)`
        },
        enter(el, done) {
          if (props.fade) {
            gsap
              .timeline({ onComplete: done })
              .set(el, {
                autoAlpha: 0,
                transform: `translate3d(${start}, 0, 0)`
              })
              .to(el, {
                autoAlpha: 1,
                transform: `translate3d(${end}, 0, 0)`,
                duration: speed,
                delay: 0.25,
                overwrite: true
              })
          } else {
            gsap
              .timeline({ onComplete: done })
              .set(el, {
                transform: `translate3d(${start}, 0, 0)`
              })
              .to(el, {
                transform: `translate3d(${end}, 0, 0)`,
                duration: speed,
                delay: 0.25,
                overwrite: true
              })
          }
        },
        afterEnter(el) {
          el.style.transform = 'translate3d(0, 0, 0)'
        },
        beforeLeave(el) {
          const display = el.dataset.display || 'block'
          el.style.display = display
          if (props.fade) {
            el.style.opacity = '1'
            el.style.visibility = 'visible'
          }
          el.style.transform = 'translate3d(0, 0, 0)'
        },
        leave(el, done) {
          if (props.fade) {
            gsap.to(el, {
              autoAlpha: 0,
              transform: `translate3d(${start}, 0, 0)`,
              duration: speed,
              onComplete: done
            })
          } else {
            gsap.to(el, {
              transform: `translate3d(${start}, 0, 0)`,
              duration: speed,
              onComplete: done
            })
          }
        }
      }
    }
    return createElement('transition', data, ctx.children)
  }
}
</script>
