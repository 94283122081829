<template>
  <portal to="shopMenuDrawers">
    <drawer
      :open="isOpen"
      :colors="colors"
      :scrim-id="scrimId"
      :class="['submenu', isOpen && 'submenu--opened']"
      :background-image="backgroundImage"
      @update:open="drawerOpenedChanged"
      @close-drawers="closeAll"
    >
      <fade enter-delay="1.0">
        <button
          v-if="touch && isOpen"
          class="submenu__closer"
          @click.prevent="closeMenu"
        >
          &larr;
        </button>
      </fade>

      <slide
        :fade="true"
        :speed="0.5"
        :css="false"
        mode="out-in"
        direction="right"
        appear
      >
        <ul>
          <li
            v-for="productCategory in submenu.shopSubmenuProductCategories"
            :key="productCategory._key"
          >
            <list-item v-if="showCategoryLink">
              <recess-link
                class="submenu__category-header link link--always-active"
                :to="documentPath(productCategory)"
              >
                {{ productCategory.title }}
              </recess-link>
            </list-item>
            <ul
              class="submenu__product-list"
              :class="{
                'submenu__product-list--with-header': showCategoryLink
              }"
            >
              <list-item
                v-if="showShopAll"
                class="submenu__product"
                :class="{ 'product-list__item': showCategoryLink }"
              >
                <recess-link
                  class="submenu__category-header link--always-active"
                  :to="documentPath(productCategory)"
                >
                  shop all
                </recess-link>
              </list-item>

              <list-item
                v-for="product in filterVisibleProducts(
                  productCategory.products
                )"
                :key="product._key"
                class="submenu__product"
                :class="{ 'product-list__item': showCategoryLink }"
              >
                <thumb
                  tag="recess-link"
                  class="link link--always-active"
                  :to="documentPath(product)"
                  :thumb="product.thumb"
                  :colors="documentColors(product)"
                  inline
                  hover-images
                  @focus.native="!touch && productHover(product)"
                  @blur.native="!touch && productHover()"
                  @mouseover.native="!touch && productHover(product)"
                  @mouseleave.native="!touch && productHover()"
                >
                  {{ product.title }}
                </thumb>
              </list-item>
            </ul>
          </li>
        </ul>
      </slide>
    </drawer>
  </portal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ListItem from './list-item'
import getCBDFilter from '~/util/get-cbd-filter'

export default {
  name: 'ShopMenuSubmenu',
  components: {
    ListItem
  },
  props: {
    submenu: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    scrimId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      productHovered: undefined,
      isCBDSafe: getCBDFilter(process.env.nonCBDSite)
    }
  },
  computed: {
    ...mapState({
      openedKey: state => state.screen.shopMenuSubmenuOpenedKey,
      touch: state => state.screen.touch
    }),

    isOpen() {
      return this.openedKey === this.id
    },

    showCategoryLink() {
      return this.submenu.shopSubmenuProductCategories.length > 1
    },

    showShopAll() {
      return (
        this.submenu.shopSubmenuProductCategories.length === 1 && this.touch
      )
    },

    productHoveredColor() {
      return this.productHovered?.colors.backgroundColors[0].hex
    },

    submenuBackgroundColorOverride() {
      return this.submenu.shopSubmenuBackgroundColor?.hex
    },

    backgroundImage() {
      const assetId = this.submenu.shopSubmenuProductCategories[0]
        ?.backgroundImage?.asset._id
      return assetId && this.$sanityImage(assetId).url()
    },

    colors() {
      return (
        this.submenu.shopSubmenuProductCategories[0].backgroundColors?.map(
          ({ hex }) => hex
        ) ??
        (this.productHoveredColor && [this.productHoveredColor]) ??
        (this.submenuBackgroundColorOverride && [
          this.submenuBackgroundColorOverride
        ]) ?? ['white']
      )
    }
  },
  methods: {
    ...mapMutations({
      setSubmenuOpenedKey: 'screen/setShopMenuSubmenuOpenedKey',
      setOverlay: 'screen/setOverlay'
    }),
    productHover(product) {
      this.productHovered = product
    },
    drawerOpenedChanged(opened) {
      if (opened) {
        return
      }
      this.closeMenu()
    },
    closeMenu() {
      this.productHovered = undefined
      this.setSubmenuOpenedKey(null)
    },
    closeAll() {
      this.closeMenu()
      this.setOverlay()
    },
    filterVisibleProducts(products) {
      return products.filter(
        product => !product.hideFromNavigation && this.isCBDSafe(product)
      )
    }
  }
}
</script>

<style scoped>
.submenu {
  --closer-width: 1em;

  @apply leading-tight;
  display: flex;
  /* grid positioning allows the submenus to stack on top of each other */
  grid-column: 1;
  grid-row: 1;
  pointer-events: none;
}

.submenu--opened {
  pointer-events: auto;
}

.submenu__product-list--with-header {
  margin-bottom: var(--menu-gutter);
  margin-left: 1em;
}

.submenu__product-list {
  @screen md {
    --menu-size: theme('fontSize.lg');
  }

  @screen lg {
    --menu-size: theme('fontSize.2xl');
  }
}

.submenu__product a {
  padding: 0;
}

.submenu__closer {
  left: calc(var(--x-gutter) + var(--drawer-gutter));
  line-height: 1em;
  position: absolute;
  top: calc(var(--header-height) + var(--menu-gutter));
  width: 1em;
}
</style>

<style>
.submenu .drawer__content {
  padding-left: calc(
    var(--drawer-gutter) + var(--drawer-wave-size) + var(--closer-width)
  );
}

.submenu .drawer__container {
  width: 100%;
}
</style>
