import {
  getTextColor,
  getBackgroundColors,
  getBackgroundColor
} from '~/util/color'

/**
 * Expects a Sanity document object, returns a color
 * string based on the object's colors.textColor property
 * @param {object} document
 * @returns {string}
 */
export const getDocumentTextColor = document => {
  if (!document || !document.colors) {
    return undefined
  }
  return getTextColor(document.colors.textColor)
}

/**
 * Expects a Sanity document object, returns an
 * array of color strings based on the object's
 * colors.backgroundColors property
 * @param {object} document
 * @returns {string}
 */
export const getDocumentBackgroundColors = document => {
  if (!document || !document.colors) {
    return undefined
  }
  return getBackgroundColors(document.colors.backgroundColors)
}

/**
 * Expects a Sanity document object, returns a color
 * string based on the first value of the object's
 * colors.backgroundColors property
 * @param {object} document
 * @returns {string}
 */
export const getDocumentBackgroundColor = document => {
  if (!document || !document.colors) {
    return undefined
  }
  return getBackgroundColor(document.colors.backgroundColors)
}

/**
 * Expects a Sanity document object, returns an object
 * containing backgroundColors and textColor properties
 * @param {object} document
 * @returns {object}
 */
export const getDocumentColors = document => {
  if (!document || !document.colors) {
    return undefined
  }
  return {
    backgroundColors: getBackgroundColors(document.colors.backgroundColors),
    textColor: getTextColor(document.colors.textColor)
  }
}
