import { SiMinutemailer } from 'react-icons/si'
import basicEditor from './blocks/basic-editor'
import mediaField from './fields/media'

export default {
  title: 'Sign Up Page',
  name: 'signUp',
  type: 'document',
  icon: SiMinutemailer,
  fieldsets: [
    {
      name: 'whatYouGet',
      title: 'What You Get'
    }
  ],
  fields: [
    {
      title: 'CBD',
      name: 'cbd',
      type: 'boolean',
      description:
        'If CBD is enabled, this record will not be shown on the non-CBD storefront.'
    },
    {
      title: 'Title',
      name: 'title',
      type: 'string',
      validation: Rule => Rule.required().error('A title is required.')
    },
    {
      title: 'Slug',
      name: 'slug',
      type: 'slug',
      description: 'The URL path this page will exist at',
      options: {
        source: 'title',
        maxLength: 96
      },
      validation: Rule => Rule.required().error('A slug is required.')
    },
    {
      title: 'Short Description',
      name: 'description',
      type: 'text',
      rows: 2
    },
    {
      title: 'Poster',
      name: 'poster',
      type: 'image',
      description:
        'Representative image used in page sharing and search engine results'
    },
    {
      title: 'Signup List ID',
      name: 'listId',
      type: 'string'
    },
    {
      title: 'Intro',
      name: 'intro',
      type: 'object',
      fields: [
        {
          title: 'Intro Title',
          name: 'title',
          type: 'string',
          description: 'For ex. "Recess x Moonpod giveaway"'
        },
        basicEditor,
        {
          title: 'Intro CTA',
          name: 'cta',
          type: 'string',
          description: 'For ex. "enter to win"'
        },
        {
          ...basicEditor,
          title: 'Intro Disclaimer',
          name: 'disclaimer'
        }
      ]
    },
    {
      title: 'What You Get Title',
      name: 'whatYouGetTitle',
      type: 'string',
      description: 'Defaults to, you guessed it, "What You Get"',
      fieldset: 'whatYouGet'
    },
    {
      title: 'What You Get Offers',
      name: 'whatYouGet',
      type: 'array',
      of: [
        {
          title: 'Offer',
          name: 'offer',
          type: 'object',
          fields: [
            {
              title: 'Thumbnail',
              name: 'thumbnail',
              type: 'image'
            },
            {
              title: 'Heading 1',
              name: 'heading1',
              type: 'string'
            },
            {
              title: 'Heading 2',
              name: 'heading2',
              type: 'string'
            }
          ]
        }
      ],
      validation: Rule =>
        Rule.max(6).error("What You Get can't have more than 4 offers"),
      fieldset: 'whatYouGet'
    },
    {
      ...mediaField,
      title: 'Optional Splash',
      name: 'splash'
    }
  ],
  preview: {
    select: {
      title: 'title',
      media: 'poster'
    }
  }
}
