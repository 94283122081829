<template>
  <div
    v-resize="resize"
    class="marquee-background"
    :style="{
      '--marquee-background-width': `${width}px`,
      '--marquee-background-height': `${height}px`
    }"
  >
    <marquee
      v-for="(backgroundImage, index) in isReady ? backgroundImages : []"
      :key="backgroundImage._key"
      :speed="12 + 12 * (index * -0.5)"
    >
      <marquee-background-image :background-image="backgroundImage" />
    </marquee>
  </div>
</template>

<script>
import marqueeBackgroundImage from './marquee-background-image.vue'
export default {
  name: 'MarqueeBackground',
  components: { marqueeBackgroundImage },
  props: {
    backgroundImages: {
      type: Array,
      required: true,
      default: undefined
    }
  },

  data() {
    return {
      width: undefined,
      height: undefined
    }
  },

  computed: {
    isReady() {
      return this.height && this.width
    }
  },

  mounted() {
    // we need everything else rendered before we take this measurement
    // and nextTick triggers too early. Resize observer won't trigger
    // before this happens either, so we use timeout of 0
    setTimeout(() => {
      this.resize()
    }, 0)
  },

  methods: {
    resize() {
      const { offsetWidth, offsetHeight } = this.$el
      this.width = offsetWidth
      this.height = offsetHeight
    }
  }
}
</script>

<style scoped>
.marquee-background {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>

<style>
.marquee-background .marquee {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
