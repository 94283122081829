import { render, staticRenderFns } from "./burger.global.vue?vue&type=template&id=0d1c5480&functional=true&"
import script from "./burger.global.vue?vue&type=script&lang=js&"
export * from "./burger.global.vue?vue&type=script&lang=js&"
import style0 from "./burger.global.vue?vue&type=style&index=0&id=0d1c5480&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports