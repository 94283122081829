<template>
  <list-item class="section-item">
    <recess-link
      v-hover-intent:200="linkHover"
      class="link link--always-active"
      :sanity-link="sectionItem.shopMenuItemLink"
      @[touchstartEvent].native.prevent="openCategory"
      @click.native="handleClick"
    >
      <sanity-media
        v-if="sectionItem.shopMenuItemIcon"
        :image="sectionItem.shopMenuItemIcon"
        width="48"
        height="48"
        contain
      />
      <div class="flex flex-col">
        {{ sectionItem.shopMenuItemLink.title }}
        <div
          v-if="sectionItem.shopMenuItemSubtext"
          class="section-item__subtext"
        >
          {{ sectionItem.shopMenuItemSubtext }}
        </div>
      </div>
    </recess-link>

    <submenu
      v-if="hasSubmenu"
      :id="sectionItem._key"
      :submenu="sectionItem.shopMenuItemSubmenu"
      :scrim-id="scrimId"
    />
  </list-item>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Submenu from './submenu'
import ListItem from './list-item'

export default {
  name: 'ShopMenuSectionItem',
  components: {
    Submenu,
    ListItem
  },
  props: {
    sectionItem: {
      type: Object,
      required: true
    },
    scrimId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      touch: state => state.screen.touch
    }),
    hasSubmenu() {
      return (
        this.sectionItem.shopMenuItemSubmenu?.shopSubmenuProductCategories
          ?.length > 0
      )
    },
    touchstartEvent() {
      // only capture this event if there is a submenu that needs to open
      return this.hasSubmenu ? 'touchstart' : null
    }
  },
  methods: {
    ...mapMutations({
      setSubmenuOpenedKey: 'screen/setShopMenuSubmenuOpenedKey',
      setOverlay: 'screen/setOverlay'
    }),
    openCategory() {
      const submenuKey = this.hasSubmenu ? this.sectionItem._key : null
      this.setSubmenuOpenedKey(submenuKey)
    },
    linkHover() {
      // we use hover intent here because it's easy to hover a different menu
      // when moving your mouse to the newly opened respective submenu
      this.openCategory()
    },
    handleClick() {
      // when clicking on a non-touch device, we should always close
      // the overlay
      this.setOverlay()
    }
  }
}
</script>

<style>
.section-item a {
  align-items: top;
  display: flex;
}

.section-item__subtext {
  font-size: theme('fontSize.sm');
  font-weight: normal;
  margin-top: 0.25em;
}

.section-item img {
  --dim: calc(var(--menu-size) * 0.8);
  height: var(--dim);
  margin-right: 0.5em;
  margin-top: calc(var(--dim) * 0.3);
  width: var(--dim);
}
</style>
