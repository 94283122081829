<template functional>
  <recess-link
    v-if="props.url && props.external"
    :href="props.url"
    :blank-target="props.external"
    class="link"
  >
    <slot />
  </recess-link>
  <recess-link v-else-if="props.url" :to="props.url" class="link">
    <slot />
  </recess-link>
</template>

<script>
export default {
  name: 'SanityLink',

  functional: true,

  props: {
    url: {
      default: undefined,
      type: String
    },
    external: {
      default: false,
      type: Boolean
    }
  }
}
</script>
