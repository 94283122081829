// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/wave-vertical.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes move-vertical-eea9e434{0%{-webkit-mask-position:0 0,100% 0;mask-position:0 0,100% 0}to{-webkit-mask-position:0 0,100% var(--wave-length);mask-position:0 0,100% var(--wave-length)}}.wave-background--vertical[data-v-eea9e434]{--wave-width:40px;animation:move-vertical-eea9e434 12s linear 0s infinite reverse;animation-play-state:paused;background-position:top;background-repeat:repeat;background-size:100% auto;height:100%;-webkit-mask-clip:border-box;mask-clip:border-box;-webkit-mask-image:linear-gradient(#000,#000),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");mask-image:linear-gradient(#000,#000),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");-webkit-mask-position:0 0,100% 0;mask-position:0 0,100% 0;-webkit-mask-repeat:no-repeat,repeat-y;mask-repeat:no-repeat,repeat-y;-webkit-mask-size:calc(100% - 40px) 100%,40px var(--wave-length);mask-size:calc(100% - 40px) 100%,40px var(--wave-length);-webkit-mask-size:calc(100% - var(--wave-width)) 100%,var(--wave-width) var(--wave-length);mask-size:calc(100% - var(--wave-width)) 100%,var(--wave-width) var(--wave-length);position:absolute;width:calc(100% + 1px)}.wave-background--is-animating[data-v-eea9e434]{animation-play-state:running}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
