import groq from 'groq'
import imageField from './image'

export default groq`{
  _type == 'image' => {
    "image": {
      ...${imageField}
    }
  },
  _type == 'video' => {
    "video": {
      "playbackId": mux.asset->playbackId,
      "metadata": mux.asset->data
    },
    "image": thumbnail
  },
  caption
}`
