<template>
  <div
    :style="{
      '--marquee-background-image-width': `${backgroundImageWidth}px`
    }"
    v-lazy-background :lazy-background="backgroundImage.asset.url"
    class="marquee-background__instance"
  >
    &nbsp;
  </div>
</template>

<script>
export default {
  name: 'MarqueeBackgroundImage',
  props: {
    backgroundImage: {
      type: Object,
      required: true,
      default: undefined
    }
  },
  computed: {
    backgroundImageWidth() {
      return this.backgroundImage.asset.metadata.dimensions.width
    }
  }
}
</script>

<style scoped>
.marquee-background__instance {
  background-image: var(--marquee-background-image);
  background-repeat: repeat repeat;
  background-size: auto;
  height: var(--marquee-background-height);
  width: var(--marquee-background-image-width);
}
</style>
