function transformResponse({ data }) {
  if (!data) {
    // handle error
    // unexpected api response
    return []
  }

  const products = data.map(product => {
    const variants = product.variants
      .filter(variant => {
        if (
          variant.inventory_policy === 'deny' &&
          variant.inventory_quantity < 1
        ) {
          return false
        }

        return true
      })
      .map(variant => {
        const image =
          product.images.find(el => el.id === variant.image_id) || product.image

        if (image) {
          image.transformedSrc = image.src
          image.__typename = 'Image'
        }

        const shopifyId = `gid://shopify/ProductVariant/${variant.id}`

        const selectedOptions = Array.from(
          { length: 3 },
          (_, i) =>
            product.options?.[i] && {
              name: product.options[i].name,
              value: variant[`option${i + 1}`],
              __typename: 'SelectedOption'
            }
        ).filter(Boolean)

        return {
          ...variant,
          id: shopifyId,
          image,
          selectedOptions,
          compareAtPrice: variant.compareAtPrice || null,
          availableForSale: variant.availableForSale || null,
          quantityAvailable: variant.quantityAvailable || null
        }
      })

    const options = product.options
      .map(option => ({
        ...option,
        values: option.values.filter(value =>
          variants.some(variant =>
            variant.selectedOptions.some(
              o => o.name === option.name && o.value === value
            )
          )
        )
      }))
      .filter(({ values }) => values.length > 0)

    return {
      id: `gid://shopify/Product/${product.id}`,
      handle: product.handle,
      title: product.title,
      options,
      variants,
      defaultVariant: variants[0]
    }
  })

  return products.filter(product => {
    // filter out products with no available variant
    if (!product.defaultVariant) {
      return false
    }
    return true
  })
}

export default transformResponse
