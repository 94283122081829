import { FaWarehouse } from 'react-icons/fa'
import colorsField from './fields/colors'
import contentEditor from './blocks/content-editor'

export default {
  title: 'Wholesale',
  name: 'wholesale',
  type: 'document',
  icon: FaWarehouse,
  __experimental_actions: ['update', 'publish'],
  fields: [
    {
      title: 'Title',
      name: 'title',
      type: 'string',
      validation: Rule => Rule.required().error('A title is required.')
    },
    {
      ...contentEditor,
      title: 'Content',
      name: 'content'
    },
    colorsField
  ]
}
