import { IoIosInformationCircle } from 'react-icons/io'

export default {
  title: 'Site Meta',
  name: 'siteMeta',
  type: 'document',
  icon: IoIosInformationCircle,
  __experimental_actions: ['update', 'publish'],
  fields: [
    {
      title: 'Site description',
      name: 'siteDescription',
      type: 'string'
    },
    {
      title: 'Site Image',
      name: 'image',
      type: 'image'
    }
  ]
}
