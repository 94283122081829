<template>
  <ValidationProvider
    v-slot="{ errors }"
    ref="provider"
    :name="name"
    :rules="required ? 'required' : ''"
    :style="styles"
    class="relative flex flex-col items-start flex-auto lowercase whitespace-no-wrap input input--stacked"
    tag="div"
  >
    <p :id="`${name}-${$_uid}-label`" class="label file__label">
      {{ label || name }}
    </p>
    <input
      :id="`${name}-${$_uid}-input`"
      :accept="accept"
      :aria-describedby="`${name}-${$_uid}-label`"
      :name="name"
      :required="required"
      aria-hidden
      class="sr-only"
      type="file"
      @change="handleChange"
      v-on="$listeners"
    />
    <div class="flex flex-wrap items-center gap-4">
      <cta :for="`${name}-${$_uid}-input`" fill small tag="label">
        choose file
      </cta>
      <ul v-if="files.length > 0" class="flex flex-wrap items-center gap-4 p-0">
        <li v-for="file of files" :key="file" class="p-0">{{ file }}</li>
      </ul>
      <span v-else>No file selected</span>
    </div>
    <fade appear>
      <ul
        v-if="errors && Object.values(errors).flat().length"
        class="absolute left-0 w-full top-full"
      >
        <li
          v-for="(error, i) in Object.values(errors).flat()"
          :key="`error-${i}`"
          class="text-xs opacity-75 pt-1/4em"
        >
          {{ error }}
        </li>
      </ul>
    </fade>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'FormFileInput',

  props: {
    accept: {
      type: String,
      default: 'image/*'
    },
    required: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    textColor: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      files: []
    }
  },

  computed: {
    styles() {
      const textColor = this.textColor
      const style = {}
      if (textColor) {
        style['--field-text-color'] = textColor
      }
      return style
    }
  },

  methods: {
    async handleChange(event) {
      const valid = await this.$refs.provider.validate(event)
      if (valid) {
        const { files } = event.currentTarget
        if (files && files.length > 0) {
          this.files = Array.from(files).map(file => file.name)
        }
      }
    }
  }
}
</script>

<style scoped>
p,
ul {
  @apply py-1/2em;
  color: var(--field-text-color, currentColor);
}

label {
  cursor: pointer;
  padding: 0;
}
</style>
