// @ts-check

import { MdShoppingCart } from 'react-icons/md'
import { defineType } from 'sanity'

import linkField from '../fields/link'
import imageField from '../fields/image'
import socialMedia from '../fields/social-media'
import galleryField from '../fields/gallery'

import { ShopifyProductInput } from '../../components'

export default defineType({
  name: 'product',
  title: 'Product',
  type: 'document',
  icon: MdShoppingCart,
  groups: [
    {
      default: true,
      name: 'info',
      title: 'Product info'
    },
    {
      name: 'display',
      title: 'Media & display settings'
    },
    {
      name: 'pdp-content',
      title: 'Product page content'
    }
  ],
  fieldsets: [
    {
      name: 'visibility',
      title: 'Visibility'
    },
    {
      name: 'amazon',
      title: 'Amazon product link',
      description:
        'An optional amazon product link that will be rendered below the add-to-cart button',
      options: { collapsible: true, collapsed: true }
    },
    {
      name: 'page-section-related-products',
      title: 'Page section: Related products',
      description: 'A list of other products and links to their detail pages',
      options: { collapsible: true, collapsed: true }
    }
  ],
  fields: [
    {
      title: 'Title',
      name: 'title',
      type: 'string',
      group: 'info',
      description: 'The product title',
      validation: Rule =>
        Rule.required().error('Please provide a product title')
    },
    {
      title: 'Slug',
      name: 'slug',
      type: 'slug',
      group: 'info',
      description: 'The product handle (used in storefront URLs)',
      options: {
        source: 'title',
        maxLength: 96
      },
      validation: Rule => Rule.required().error('Please provide a product slug')
    },
    {
      title: 'Product Category',
      name: 'category',
      type: 'reference',
      to: [{ type: 'productCategory' }],
      group: 'info',
      description: 'The collection to which this product belongs',
      options: {
        filter: '!(defined(parentCategories))'
      },
      validation: Rule =>
        Rule.required().error('Please specify a product category')
    },
    {
      title: 'Contains CBD',
      name: 'cbd',
      type: 'boolean',
      group: 'info',
      description:
        'This product should be excluded from "scrubbed" or CBD-free storefronts',
      fieldset: 'visibility',
      options: { layout: 'checkbox' },
      initialValue: false
    },
    {
      title: 'Hide from main menu',
      name: 'hideFromNavigation',
      type: 'boolean',
      group: 'info',
      description:
        "This product should be excluded from the storefront's primary navigation",
      fieldset: 'visibility',
      options: { layout: 'checkbox' },
      initialValue: false
    },
    {
      title: 'Hide from product category pages',
      name: 'hideFromCollection',
      type: 'boolean',
      group: 'info',
      description:
        'This product should be excluded from product grids and lists in the storefront',
      fieldset: 'visibility',
      options: { layout: 'checkbox' },
      initialValue: false
    },
    {
      title: 'Shopify',
      name: 'shopify',
      type: 'object',
      group: 'info',
      components: {
        field: ({ children }) => children
      },
      fieldsets: [
        {
          name: 'shopify',
          title: 'Shopify product details'
        }
      ],
      fields: [
        {
          // This field is only meant to be used in the studio for handling
          // conditional field visibility and validation
          title: 'Available as a subscription',
          name: 'isSubscription',
          type: 'boolean',
          description: 'This product may be purchased as a subscription',
          fieldset: 'shopify',
          options: { layout: 'checkbox' },
          initialValue: true
        },
        {
          title: 'Product reference',
          name: 'product',
          type: 'object',
          description:
            'The Shopify product that corresponds to this product document',
          fieldset: 'shopify',
          fields: [
            {
              title: 'Shopify ID',
              name: 'id',
              type: 'string'
            },
            {
              title: 'Product Title',
              name: 'title',
              type: 'string'
            },
            {
              title: 'Product Image',
              name: 'image',
              type: 'string',
              hidden: true
            }
          ],
          components: {
            input: ShopifyProductInput
          },
          validation: Rule => Rule.required().error('Please select a product')
        },
        {
          title: 'Product reference (subscription)',
          name: 'subscription',
          type: 'object',
          description:
            'A second corresponding Shopify product that includes subscription selling plans',
          fieldset: 'shopify',
          components: {
            input: ShopifyProductInput
          },
          fields: [
            {
              title: 'Shopify ID',
              name: 'id',
              type: 'string'
            },
            {
              title: 'Product Title',
              name: 'title',
              type: 'string'
            }
          ],
          hidden: ({ document }) => document?.shopify?.isSubscription === false,
          validation: rule =>
            rule.custom((value, { document }) => {
              if (!value && document?.shopify?.isSubscription !== false) {
                return 'Please select a subscription product'
              }
              return true
            })
        },
        {
          title: 'Default SKU',
          name: 'defaultSku',
          type: 'object',
          fieldset: 'shopify',
          components: {
            field: ({ children }) => children
          },
          fields: [
            {
              title: 'Default product variant (SKU)',
              name: 'sku',
              type: 'string',
              description:
                'The product variant that is initially selected in the add-to-cart form'
            }
          ]
        },
        {
          title: 'Subscription-only product variants (SKU)',
          name: 'subscriptionSkus',
          type: 'array',
          of: [{ type: 'string' }],
          description:
            'Subscription product variants that should not be selectable for one-time pruchase',
          fieldset: 'shopify',
          options: { layout: 'tags' }
        },

        // TODO This field should be separated from the `shopify` object and moved to the 'display' group, or removed entirely
        {
          title: 'Non-subscription option caption',
          name: 'singlePurchaseNotice',
          type: 'string',
          description:
            'Text that accompanies the one-time-purchase option in the add-to-cart form'
        },

        // TODO This field should be separated from the `shopify` object and moved to the 'display' group, or removed entirely
        {
          title: 'Subscription option caption',
          name: 'subscriptionNotice',
          type: 'string',
          description:
            'Text that accompanies the subscription option in the add-to-cart form'
        },

        // TODO This field should be separated from the `shopify` object and moved to the 'display' group, or removed entirely
        {
          title: 'Add-to-cart callout',
          name: 'shippingNotice',
          type: 'string',
          description:
            'Text that appears below the add-to-cart button on the product detail page'
        }
      ]
    },

    /**
     * Media and display settings fields
     */

    {
      title: 'Product description',
      name: 'description',
      type: 'text',
      group: 'display',
      description:
        "A brief description of the product that's used throughout the storefront",
      rows: 2
    },
    {
      title: 'Product badge text',
      name: 'badge',
      type: 'string',
      group: 'display',
      description:
        'Text shown as a badge placed atop product thumbnails in collections and product lists (e.g. "new item")'
    },
    {
      title: 'Product URL',
      name: 'amazonLink',
      type: 'url',
      group: 'display',
      fieldset: 'amazon',
      validation: Rule => Rule.uri({ scheme: 'https', allowRelative: false })
    },
    {
      title: 'Call to action text',
      name: 'amazonCta',
      type: 'string',
      group: 'display',
      fieldset: 'amazon'
    },
    {
      title: 'Thumbnail',
      name: 'thumb',
      type: 'object',
      group: 'display',
      components: {
        field: ({ children }) => children
      },
      fields: [
        {
          title: 'Thumbnail image',
          name: 'image',
          type: 'image',
          description:
            'The primary product image (should be tightly cropped with a transparent background)',
          validation: Rule => Rule.required()
        },
        {
          title: 'Thumbnail "hover" images',
          name: 'hovers',
          type: 'array',
          description:
            'Optional images that may animate into view when users interact with the product thumbnail',
          of: [imageField],
          options: { layout: 'grid' },
          validation: Rule =>
            Rule.max(6).error("A thumbnail can't have more than 6 hover images")
        }
      ],
      validation: Rule => Rule.required()
    },
    {
      ...galleryField,
      title: 'Gallery images',
      name: 'heroGallery',
      group: 'display',
      description:
        'A set of images displayed beside the add-to-cart form on the product detail page',
      options: { layout: 'grid' }
    },
    {
      title: 'Favicon',
      name: 'favicon',
      type: 'image',
      group: 'display',
      description:
        'An optional, unique favicon for the product detail page (should be square in aspect and at least 192x192 px)'
    },
    {
      title: 'Colors',
      name: 'colors',
      type: 'object',
      group: 'display',
      components: {
        field: ({ children }) => children
      },
      fields: [
        {
          title: 'Text & UI Color',
          name: 'textColor',
          type: 'color',
          description:
            'The color used by text, buttons and other elements associated with this product'
        },
        {
          title: 'Background Colors',
          name: 'backgroundColors',
          type: 'array',
          description:
            'The color(s) used for page and section backgrounds (if multiple colors are specified, they will be used as gradient stops in some cases)',
          of: [
            {
              title: 'Color',
              name: 'color',
              type: 'color'
            }
          ],
          validation: Rule => Rule.min(1).max(4)
        }
      ]
    },
    {
      title: 'Product page background color',
      name: 'colorSectionBackground',
      type: 'color',
      group: 'display',
      description:
        'If specified, this color will be used to override the default product page background color'
    },

    /**
     * Product detail page content fields
     */

    {
      title: 'Page section: Ingredients',
      name: 'ingredients',
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'ingredient' }] }],
      group: 'pdp-content',
      description:
        'A list of highlighted ingredients displayed on the product detail page',
      options: { collapsible: true, collapsed: true },
      validation: Rule =>
        Rule.max(4).error(
          "A product can't have more than 4 highlighted ingredients"
        )
    },

    {
      title: 'Page section: Feature list',
      name: 'features',
      type: 'object',
      group: 'pdp-content',
      description: 'A styled list of product features',
      options: { collapsible: true, collapsed: true },
      fields: [
        {
          title: 'Heading',
          name: 'title',
          type: 'string'
        },
        {
          title: 'Features',
          name: 'list',
          type: 'array',
          of: [
            {
              title: 'Item',
              name: 'item',
              type: 'string'
            }
          ]
        },
        {
          ...linkField,
          title: 'Call to action',
          options: { collapsible: true, collapsed: true }
        }
      ]
    },
    {
      ...linkField,
      title: 'Section heading',
      name: 'relatedProductsTitleLink',
      group: 'pdp-content',
      fieldset: 'page-section-related-products',
      options: { collapsible: true, collapsed: true }
    },
    {
      title: 'Products',
      name: 'relatedProducts',
      type: 'object',
      group: 'pdp-content',
      fieldset: 'page-section-related-products',
      components: {
        field: ({ children }) => children
      },
      fields: [
        {
          title: 'Products',
          name: 'products',
          type: 'array',
          of: [{ type: 'reference', to: [{ type: 'product' }] }]
        },
        {
          title: 'Featured Product',
          name: 'featured',
          type: 'reference',
          description:
            'Rendered as its own full-width section with a large image and CTA',
          to: [{ type: 'product' }]
        }
      ]
    },
    {
      title: 'Page section: Social media images',
      name: 'relatedSocialMedia',
      type: 'object',
      group: 'pdp-content',
      description: 'A collection of social media images and links',
      options: { collapsible: true, collapsed: true },
      fields: [
        {
          title: 'Heading',
          name: 'title',
          type: 'string'
        },
        {
          ...socialMedia,
          options: { layout: 'grid' }
        }
      ]
    },
    {
      title: 'Page section: Lookbook images',
      name: 'gallery',
      type: 'object',
      group: 'pdp-content',
      description:
        'A collection of lookbook images rendered in an animated marquee (only used by Realitywear products)',
      options: { collapsible: true, collapsed: true },
      fields: [
        {
          ...galleryField,
          title: 'Images',
          options: { layout: 'grid' }
        },
        {
          ...linkField,
          title: 'Call to action',
          options: { collapsible: true, collapsed: true }
        }
      ]
    }
  ],

  preview: {
    select: {
      title: 'title',
      image: 'thumb.image',
      shopifyImage: 'shopify.image'
    },
    prepare: ({ title = 'New Product', image, shopifyImage }) => ({
      media: image || shopifyImage,
      title
    })
  }
})
