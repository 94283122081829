import { MdTextFields } from 'react-icons/md'

import { LargeTextDecorator } from '../../components'

export default {
  title: 'Large Text',
  value: 'large',
  icon: MdTextFields,
  component: LargeTextDecorator
}
