<template functional>
  <div class="spinner">
    <span class="font-blank">Loading...</span>
    <fa icon="circle-notch" />
  </div>
</template>

<script>
export default {
  name: 'Spinner',

  functional: true
}
</script>

<style>
@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner,
.spinner svg {
  transform-origin: center center;
}

.spinner {
  color: currentColor;
  font-size: theme('fontSize.4xl');
  left: 50%;
  line-height: 1;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.spinner.z-2above {
  z-index: 3;
}

.spinner.z-3above {
  z-index: 4;
}

.spinner.z-4above {
  z-index: 5;
}

.spinner svg {
  animation: spin 1s linear infinite forwards;
  transform: rotate(0);
}
</style>
