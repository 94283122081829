<template>
  <a
    v-if="samplerSplash"
    :href="externalUrl"
    :class="classes"
    class="recess-link recess-link--external"
  >
    <slot>{{ title }}</slot>
    <slot v-if="tooltip" name="tooltip">
      <div class="inline-block recess-link__tooltip tooltip" x-placement="top">
        <span class="inline-block tooltip-inner">{{ tooltip }}</span>
        <span class="inline-block tooltip-arrow" />
      </div>
    </slot>
  </a>
  <a
    :is="tag"
    v-else
    :to="computedTo"
    :href="computedHref"
    :target="target"
    :rel="rel"
    :class="classes"
    class="recess-link"
  >
    <slot>{{ title }}</slot>
    <slot v-if="tooltip" name="tooltip">
      <div class="inline-block recess-link__tooltip tooltip" x-placement="top">
        <span class="inline-block tooltip-inner">{{ tooltip }}</span>
        <span class="inline-block tooltip-arrow" />
      </div>
    </slot>
  </a>
</template>

<script>
import { mapState } from 'vuex'
import isAbsoluteUrl from 'is-absolute-url'

export default {
  name: 'RecessLink',

  props: {
    sanityLink: {
      default: () => ({}),
      type: Object
    },
    to: {
      default: null,
      type: [String, Object]
    },
    href: {
      default: null,
      type: String
    },
    color: {
      default: null,
      type: String
    },
    tooltip: {
      default: null,
      type: String
    },
    noHoverColor: {
      default: false,
      type: Boolean
    },
    blankTarget: {
      default: false,
      type: Boolean
    }
  },

  data: () => ({
    baseUrl: process.env.baseUrl,
    samplerSplash: process.env.samplerSplash
  }),

  computed: {
    ...mapState({
      googleClientId: state => state.googleClientId,
      discountCode: state => state.discountCode,
      refersionCode: state => state.refersionCode
    }),
    tag() {
      if (this.to || this.computedHref === this.to) {
        return 'n-link'
      }
      return 'a'
    },
    computedTo() {
      const sanity = this.sanityLink
      let to = this.to
      if (sanity) {
        if (sanity.url && !isAbsoluteUrl(sanity.url)) {
          to = sanity.url
        } else if (sanity.page) {
          to = this.documentPath(sanity.page)
        }
      }
      return to || ''
    },
    computedHref() {
      const sanity = this.sanityLink
      let href = this.href
      if (sanity) {
        if (isAbsoluteUrl(sanity.url || '')) {
          href = sanity.url
        } else if (sanity.external) {
          const pathname = sanity.page
            ? this.documentPath(sanity.page)
            : sanity.url
          if (pathname) {
            href = new URL(pathname, this.baseUrl).toString()
          }
        }
      }
      return href || this.to
    },
    externalUrl() {
      const to = this.computedTo
      const href = this.computedHref
      const base = process.env.canonicalUrl
      const params = {
        ga: this.googleClientId,
        rfsn: this.refersionCode,
        [process.env.discountParam]: this.discountCode
      }
      try {
        const url = to ? new URL(to, base) : new URL(href)
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            url.searchParams.set(key, value)
          }
        })
        return url.toString()
      } catch (error) {
        // couldn't create URL
        return undefined
      }
    },
    target() {
      if (this.blankTarget) {
        return '_blank'
      }

      if (this.sanityLink) {
        return this.sanityLink.external ? '_blank' : false
      }

      return this.tag === 'a' ? '_blank' : false
    },
    rel() {
      if (this.tag !== 'a') {
        return false
      }

      if (this.sanityLink) {
        return this.computedHref.startsWith(this.baseUrl) && 'noopener'
      }

      return 'noopener'
    },
    title() {
      const sanity = this.sanityLink
      const title = sanity.title
      const pageTitle = sanity.page ? sanity.page.title : ''
      return title || pageTitle
    },
    classes() {
      return {
        link: !this.noHoverColor
      }
    }
  },

  mounted() {
    this.$nextTick(this.setColor)
  },

  methods: {
    setColor() {
      const color = this.color
      if (color) {
        this.$el.style.setProperty('--link-color', color)
      }
    }
  }
}
</script>

<style>
.recess-link__tooltip {
  bottom: 100%;
  left: 50%;
  position: absolute;
  text-align: center;
  text-transform: lowercase;
  transform: translateX(-50%);
  visibility: hidden;
}

.recess-link .recess-link__tooltip {
  opacity: 0;
}

.recess-link.is-active .recess-link__tooltip,
[data-js-focus-visible]
  .recess-link:not(.link--no-focus):not(.is-exact-active)[data-focus-visible-added]
  .recess-link__tooltip,
[data-js-focus-visible]
  .recess-link.link--always-active:not(.link--no-focus)[data-focus-visible-added]
  .recess-link__tooltip {
  opacity: 1;
  visibility: visible;
}

@media (hover) {
  .recess-link__tooltip {
    transition: opacity 0.15s, visibility 0.15s;
  }

  .recess-link:hover .recess-link__tooltip {
    opacity: 1;
    visibility: visible;
  }
}
</style>
