import { MdViewList } from 'react-icons/md'
import linkField from './fields/link'

export default {
  title: 'Menus',
  name: 'menus',
  type: 'document',
  icon: MdViewList,
  __experimental_actions: ['update', 'publish'],
  fieldsets: [
    {
      name: 'globalLinks',
      title: 'Global Links'
    }
  ],
  fields: [
    {
      ...linkField,
      title: 'Login Link',
      name: 'loginLink',
      fieldset: 'globalLinks'
    },
    {
      title: 'Product Menu',
      name: 'productMenu',
      type: 'array',
      of: [
        {
          title: 'Product Pages',
          name: 'page',
          type: 'reference',
          to: [
            { type: 'productCategory' },
            { type: 'product' },
            { type: 'buyNow' }
          ]
        }
      ]
    },
    {
      title: 'Primary Menu',
      name: 'primaryMenu',
      type: 'object',
      fields: [
        {
          title: 'Title',
          name: 'title',
          type: 'string',
          description: 'for ex. "Information"'
        },
        {
          title: 'Links',
          name: 'links',
          type: 'array',
          of: [linkField],
          description: 'Informational pages (for ex. "What\'s Inside")'
        }
      ]
    },
    {
      title: 'Secondary Menu',
      name: 'secondaryMenu',
      type: 'object',
      fields: [
        {
          title: 'Title',
          name: 'title',
          type: 'string',
          description: 'for ex. "The Antidote"'
        },
        {
          title: 'Links',
          name: 'links',
          type: 'array',
          of: [linkField],
          description: 'Can be used to link to pages on The Antidote, etc.'
        }
      ]
    },
    {
      title: 'Legal Menu',
      name: 'legalPageMenu',
      type: 'array',
      of: [linkField],
      description: 'for ex. "Privacy Policy"',
      validation: Rule => Rule.max(4)
    }
  ]
}
