<template>
  <div
    class="flex flex-col py-4 mb-5 border-t-2 border-b-2 border-black border-solid"
  >
    <div class="flex items-center">
      <img src="/baby-present.png" width="48px" height="48px" />

      <div :class="['font-bold', hasGiftNote && 'flex-1']">
        is this a gift?
      </div>

      <div class="flex justify-end items-center">
        <template v-if="hasGiftNote">
          <form-button class="mr-1/2em" is-link @click="openEditor"
            >edit</form-button
          >
          <form-button is-link @click="remove">remove</form-button>
        </template>
        <template v-else>
          <form-button is-link @click="openEditor">
            add a digital note
          </form-button>
        </template>
      </div>
    </div>

    <p v-if="text" class="text-center mt-2em">
      {{ text }}
    </p>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FormButton from '~/components/form/button'

export default {
  name: 'GiftNoteStatus',
  components: {
    FormButton
  },
  computed: {
    ...mapState({
      text: state => state.shop.giftNote.text
    }),
    hasGiftNote() {
      return !!this.text
    }
  },
  methods: {
    ...mapActions({
      openEditor: 'shop/giftNoteEditorOpen',
      remove: 'shop/giftNoteRemove'
    })
  }
}
</script>
