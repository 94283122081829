<template>
  <div id="app">
    <nuxt />
  </div>
</template>

<script>
/**
 * Minimal layout meant for pages invisibly embedded on other sites
 */
export default {
  head() {
    return {
      meta: [{ hid: 'robots', name: 'robots', content: 'noindex, nofollow' }]
    }
  }
}
</script>
