<template>
  <div
    :is="tag"
    v-observe-visibility="visibilityChange"
    :class="classes"
    :style="initialStyle"
    class="gradient"
  >
    <slot />
  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  name: 'Gradient',

  props: {
    tag: {
      default: 'div',
      type: String
    },
    colors: {
      default: () => [],
      type: Array,
      required: true
    },
    forceGradient: {
      default: false,
      type: Boolean
    }
  },

  data() {
    const colors = this.colors
    return {
      visible: false,
      initialStyle: {
        backgroundImage: colors ? this.getGradientStyle(colors) : ''
      }
    }
  },

  computed: {
    classes() {
      return {
        'gradient--color-only': this.colorOnly,
        'is-visible': this.visible
      }
    },
    colorOnly() {
      return this.$device.isMobileOrTablet && !this.forceGradient
    }
  },

  watch: {
    colors(colors) {
      this.animate()
    }
  },

  methods: {
    visibilityChange(visible) {
      this.visible = visible
    },
    animate() {
      const colors = this.colors
      if (!colors || !colors.length) {
        return
      }
      if (this.colorOnly) {
        this.animateColor(colors[0])
      } else {
        this.animateGradient(colors)
      }
    },
    animateGradient(colors) {
      const style = this.getGradientStyle(colors)
      if (!this.visible) {
        this.$el.style.backgroundImage = style
      }
      if (colors) {
        const backgroundImage = style
        if (this.$device.isMobileOrTablet) {
          this.$el.style.backgroundImage = backgroundImage
        } else {
          gsap.to(this.$el, { backgroundImage })
        }
      }
    },
    animateColor(color) {
      this.$el.style.backgroundColor = color
    }
  }
}
</script>

<style>
.gradient--color-only {
  background-image: none !important;
}

.gradient--color-only.is-visible {
  transition: background-color var(--fade-speed);
}
</style>
