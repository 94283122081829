/**
 * Constructs a production URL for previews or other purposes.
 *
 * @link https://www.sanity.io/docs/document-api-reference#productionUrl-04c384879910
 * @link https://www.sanity.io/docs/migrating-production-url-resolver
 *
 * @type {import('sanity').DocumentPluginOptions['productionUrl']}
 */
export default function getProductionUrl(env) {
  const productionUrl = (prev, { dataset, document }) => {
    const current = typeof window !== 'undefined' && new URL(window.location)

    let href

    if (current?.hostname === 'localhost') {
      href = env.preview.local.url
    } else if (dataset in env.preview) {
      href = env.preview[dataset].url
    }

    if (href) {
      const url = new URL(href)
      url.pathname += `/${document._id}`
      url.searchParams.set('dataset', dataset)
      return url.toString()
    }

    return prev
  }

  return productionUrl
}
