// import React from 'react'
import imageField from './image'
import videoField from './video'

export default {
  title: 'Media',
  name: 'media',
  type: 'object',
  fields: [imageField, videoField],
  validation: Rule =>
    Rule.custom(({ image, video } = {}) => {
      if (image && video) {
        return 'Image and video are mutually exclusive'
      }
      // if (!image && !video) {
      //   return 'An image or video is required';
      // }
      return true
    }),
  preview: {
    select: {
      image: 'image',
      imageFilename: 'image.asset.originalFilename'
    },
    prepare: ({ image, imageFilename }) => ({
      title: imageFilename || 'New Media',
      media: image
    })
  }
}
