import { IoIosLeaf } from 'react-icons/io'

export default {
  title: 'Ingredient',
  name: 'ingredient',
  type: 'document',
  icon: IoIosLeaf,
  fieldsets: [
    {
      name: 'alt',
      title: 'Scrubbed Version',
      options: {
        collapsible: true
      }
    }
  ],
  fields: [
    {
      title: 'CBD',
      name: 'cbd',
      type: 'boolean',
      description:
        'If CBD is enabled, this record will not be shown on the non-CBD storefront.'
    },
    {
      title: 'Title',
      name: 'title',
      type: 'string',
      validation: Rule => Rule.required().error('A title is required.')
    },
    {
      title: 'Image',
      name: 'image',
      type: 'image',
      description: 'Transparent, closely cropped image',
      validation: Rule => Rule.required().error('An image is required.')
    },
    {
      title: 'Short Description',
      name: 'description',
      type: 'text',
      rows: 2
    },
    {
      title: 'Long Description',
      name: 'longDescription',
      type: 'text',
      rows: 6
    },
    // scrubbed
    {
      title: 'Scrubbed Title',
      name: 'titleAlt',
      type: 'string',
      fieldset: 'alt'
    },
    {
      title: 'Scrubbed Image',
      name: 'imageAlt',
      type: 'image',
      description: 'Transparent, closely cropped image',
      fieldset: 'alt'
    },
    {
      title: 'Scrubbed Short Description',
      name: 'descriptionAlt',
      type: 'text',
      rows: 2,
      fieldset: 'alt'
    },
    {
      title: 'Scrubbed Long Description',
      name: 'longDescriptionAlt',
      type: 'text',
      rows: 6,
      fieldset: 'alt'
    }
  ],
  preview: {
    select: {
      title: 'title',
      media: 'image'
    }
  }
}
