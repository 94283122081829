<template>
  <div id="app" v-resize="debouncedMutated" class="flex flex-col">
    <skip-main />
    <scrolling-banner />
    <discount-banner />
    <site-header />
    <client-only>
      <cart />
    </client-only>
    <shop-menu />
    <main id="main" class="relative flex-auto">
      <nuxt class="page" />
      <transition name="fade">
        <div v-if="$route.name !== 'index'" class="top-sky">
          <sky
            v-if="bp && !hasBackground"
            :rows="bp.lg ? 6 : 4"
            :columns="bp.lg ? 6 : 4"
            class="top-0"
          />
          <marquee-background
            v-else-if="bp && hasBackground"
            :background-images="backgroundImages"
          />
        </div>
      </transition>
    </main>
    <div
      class="absolute bottom-0 left-0 h-0 overflow-hidden pointer-events-none z-behind"
      aria-hidden="true"
    >
      <div id="container-ref" class="container h-px" />
    </div>
    <LazyHydrate when-idle>
      <site-footer class="flex-initial" />
    </LazyHydrate>
    <scrim :active="!!overlay" />
    <div
      v-if="skyHigh && !hasBackground"
      class="absolute left-0 w-full h-full top-px z-sky"
    >
      <LazyHydrate when-idle>
        <sky
          v-if="bp"
          :rows="bp.lg ? 6 : 4"
          :columns="bp.lg ? 6 : 4"
          class="sticky top-0 h-0 page-sky"
          :class="{
            'page-sky--with-feature': featuredProductEnabled
          }"
        />
      </LazyHydrate>
    </div>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import MarqueeBackground from '../components/marquee-background.vue'
import layout from '~/mixins/layout'
import SkipMain from '~/components/skip-main.vue'
import Cart from '~/components/shop/cart.vue'
import SiteHeader from '~/components/site/header.vue'
import ShopMenu from '~/components/site/shop-menu/index.vue'
import SiteFooter from '~/components/site/footer.vue'
import Scrim from '~/components/scrim.vue'
import ScrollingBanner from '~/components/scrolling-banner.vue'
import DiscountBanner from '~/components/discount-banner.vue'

export default {
  components: {
    ScrollingBanner,
    DiscountBanner,
    SkipMain,
    Cart,
    SiteHeader,
    ShopMenu,
    SiteFooter,
    Scrim,
    LazyHydrate,
    MarqueeBackground
  },

  mixins: [layout],

  data() {
    return {
      featuredProductEnabled: false
    }
  },

  created() {
    this.$nuxt.$on('featuredProductEnabled', enabled => {
      this.featuredProductEnabled = enabled
    })
  }
}
</script>
