<template>
  <div
    :is="tag"
    v-observe-visibility="{
      callback: visibilityChange,
      intersection: {
        rootMargin: '20%'
      }
    }"
    :class="classes"
    class="parallax"
  >
    <fade appear @before-leave="beforeLeave" @after-leave="afterLeave">
      <div v-if="always || visible" :style="style" class="parallax__content">
        <slot />
      </div>
    </fade>
  </div>
</template>

<script>
import imagesLoaded from 'imagesloaded'
import { mapState } from 'vuex'

export default {
  name: 'Parallax',

  props: {
    tag: {
      default: 'div',
      type: String
    },
    speed: {
      default: 1,
      type: [Number, String]
    },
    always: {
      default: false,
      type: Boolean
    },
    reverse: {
      default: false,
      type: Boolean
    }
  },

  data: () => ({
    position: 0,
    loaded: false,
    visible: false,
    animating: false
  }),

  computed: {
    ...mapState('screen', {
      winHeight: state => state.height
    }),
    style() {
      return {
        transform: `translate3d(0, ${this.position}%, 0)`
      }
    },
    classes() {
      return {
        'is-visible': this.visible,
        'is-loaded': this.loaded
      }
    }
  },

  mounted() {
    if (this.always) {
      this.visible = true
      this.$nextTick(this.load)
      // this.$bus.$on('scroll', this.scroll)
      window.addEventListener('scroll', this.scroll, { passive: true })
    }
    this.$bus.$on('resize', this.resize)
  },

  beforeDestroy() {
    this.$bus.$off('resize', this.resize)
    // this.$bus.$off('scroll', this.scroll)
    window.removeEventListener('scroll', this.scroll, { passive: true })
  },

  methods: {
    resize({ sameWidth = false }) {
      if (sameWidth || !this.loaded) {
        return
      }
      this.$nextTick(this.scroll)
    },
    async visibilityChange(visible) {
      if (this.animating) {
        return
      }
      this.visible = visible
      if (!this.loaded) {
        await this.load()
      }
      if (visible) {
        window.addEventListener('scroll', this.scroll, { passive: true })
        // this.$bus.$on('scroll', this.scroll)
      } else {
        window.removeEventListener('scroll', this.scroll, { passive: true })
        // this.$bus.$off('scroll', this.scroll)
      }
    },
    scroll() {
      const height = this.winHeight
      const reverse = this.reverse
      const rect = this.$el.getBoundingClientRect()
      const offset = reverse ? rect.top : rect.top + rect.height
      const percent = (offset / height) * (100 * this.speed)
      this.position = reverse ? percent * -1 : percent
    },
    async load() {
      await new Promise(resolve => imagesLoaded(this.$el, resolve))
      this.scroll()
      // force repaint to ensure correct size and position
      await new Promise(resolve => requestAnimationFrame(resolve))
      this.loaded = true
    },
    beforeLeave() {
      this.animating = true
    },
    async afterLeave() {
      // force repaint to ensure correct position
      await new Promise(resolve => requestAnimationFrame(resolve))
      this.animating = false
    }
  }
}
</script>

<style>
.parallax {
  pointer-events: none;
}

.parallax__content {
  opacity: 0;
  transition: transform 0.6s ease-out;
  visibility: hidden;
}
</style>
