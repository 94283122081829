import { render, staticRenderFns } from "./wave-background.vue?vue&type=template&id=eea9e434&scoped=true&"
import script from "./wave-background.vue?vue&type=script&lang=js&"
export * from "./wave-background.vue?vue&type=script&lang=js&"
import style0 from "./wave-background.vue?vue&type=style&index=0&id=eea9e434&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eea9e434",
  null
  
)

export default component.exports