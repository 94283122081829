import groq from 'groq'

export default groq`
  defined(video) => {
    "video": {
      "src": "https://stream.mux.com/" + video.mux.asset->playbackId + ".m3u8",
      "metadata": video.mux.asset->data,
      "loop": video.loop
    }
  }
`
