import groq from 'groq'
import imageField from './image'

export default groq`{
  title,
  quote,
  image{
    ...${imageField}
  },
  url
}`
