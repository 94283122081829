export default {
  title: 'Video',
  name: 'video',
  type: 'object',
  fields: [
    {
      title: 'Video File',
      name: 'mux',
      type: 'mux.video'
    },
    {
      title: 'Loop',
      name: 'loop',
      type: 'boolean'
    }
  ],
  preview: {
    prepare: () => ({ title: 'Video' })
  }
}
