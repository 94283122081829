const scrollDebounce = 300
const cursorMoveDebounce = 2000

export default {
  setTouch({ commit }) {
    const isTouch = 'ontouchstart' in window || 'ontouch' in window
    commit('setTouch', isTouch)
  },
  setWebmSupport({ commit }) {
    let video = document.createElement('video')
    const supports = video.canPlayType && !!video.canPlayType('video/webm')
    commit('setWebmSupport', supports)
    video = null // gorbage
  },
  /**
   * based on https://developers.google.com/speed/webp/faq#in_your_own_javascript
   */
  setWebpSupport({ commit }) {
    const image = new Image()
    image.onload = function() {
      const supports = image.width > 0 && image.height > 0
      commit('setWebpSupport', supports)
    }
    image.onerror = function() {
      commit('setWebpSupport', false)
    }
    image.src =
      'data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA=='
  },
  async setScreenSize({ dispatch, state, commit }) {
    const width = Math.floor(window.innerWidth)
    const height = Math.floor(window.innerHeight)
    const sameWidth = this.$device.isMobileOrTablet && state.width === width
    let size
    if (!sameWidth) {
      size = { width, height, sameWidth }
      commit('setScreenSize', size)
      await dispatch('setGlobalBannerHeight')
      await dispatch('setGutter')
    }
    return size
  },
  setScroll({ state, commit }, pos) {
    commit('setScroll', pos)
    commit('setScrolling', true)
    if (state.scrollTimer) {
      clearTimeout(state.scrollTimer)
      commit('setScrollTimer', null)
    }
    const timerId = setTimeout(() => {
      commit('setScrolling', false)
    }, scrollDebounce)
    commit('setScrollTimer', timerId)
  },
  setCursor({ state, commit }, { x, y }) {
    commit('setCursor', { x, y })
    commit('setCursorMoving', true)
    if (state.cursorTimer) {
      clearTimeout(state.cursorTimer)
      commit('setCursorTimer', null)
    }
    const timerId = setTimeout(() => {
      commit('setCursorMoving', false)
    }, cursorMoveDebounce)
    commit('setCursorTimer', timerId)
  },
  setGutter({ commit }) {
    const el = document.querySelector('#container-ref')
    if (!el) {
      this.$sentry.captureException(
        new Error('element with id #container-ref is missing')
      )
      return
    }
    const style = window.getComputedStyle(el)
    const size = parseInt(style.getPropertyValue('margin-left'), 10)
    commit('setGutter', size)
    return size
  },
  setGlobalBannerHeight({ commit }) {
    const el = document.querySelector('#global-banner')
    if (!el) {
      // global-banner isn't neccessarily always present
      return 0
    }
    const height = el.offsetHeight
    commit('setGlobalBannerHeight', height)
    return height
  },
  resetGlobalBannerHeight({ commit }) {
    commit('setGlobalBannerHeight', 0)
  },
  setHeaderHeight({ commit }) {
    const el = document.querySelector('#site-header')
    const style = window.getComputedStyle(el)
    const height = parseInt(style.getPropertyValue('height'), 10)
    commit('setHeaderHeight', height)
    return height
  },
  addSectionColors({ state, commit }, colors) {
    const exists = state.sectionColors.find(s => s.id === colors.id)
    if (!exists) {
      commit('addSectionColors', colors)
    }
  },
  removeSectionColors({ commit }, colors) {
    commit('removeSectionColors', colors)
  }
}
