import { render, staticRenderFns } from "./window-3d.global.vue?vue&type=template&id=46f74594&functional=true&"
import script from "./window-3d.global.vue?vue&type=script&lang=js&"
export * from "./window-3d.global.vue?vue&type=script&lang=js&"
import style0 from "./window-3d.global.vue?vue&type=style&index=0&id=46f74594&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports