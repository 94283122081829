export default () => ({
  cart: null,
  products: [],
  rebuyProducts: [],
  promotionalProducts: [],
  promoShopifyProducts: [],
  subscriptions: [],
  featuredProduct: null,
  ingredients: [],
  productMenu: [],
  giftNote: {
    editorIsOpen: false,
    text: '',
    email: '',
    available: false
  }
})
