<template>
  <div ref="container" class="container container--narrow">
    <expand-height>
      <div v-if="error">{{ errorMessage }}</div>
      <form
        v-else
        class="flex flex-col contact__form gap-9"
        @submit.prevent="handleSubmit"
      >
        <div v-for="field in fields" :key="field.name">
          <form-input
            v-if="['email', 'text', 'url'].includes(field.type)"
            :label="field.label"
            :name="field.name"
            :required="field.required"
            :type="field.type"
            :value="formData[field.name] || ''"
            text-color="currentColor"
            @input="handleInput"
          />
          <form-textarea
            v-if="'textarea' === field.type"
            :label="field.label"
            :name="field.name"
            :required="field.required"
            :value="formData[field.name] || ''"
            text-color="currentColor"
            @input="handleInput"
          />
          <select-dropdown
            v-if="'select' === field.type"
            :name="field.name"
            :label="field.label"
            :required="field.required"
            :options="field.options"
            :value="formData[field.name] || ''"
            @input="
              handleInput({
                currentTarget: {
                  name: field.name,
                  value: $event
                }
              })
            "
          />
          <form-file-input
            v-if="'file' === field.type"
            :accept="field.accept"
            :label="field.label"
            :name="field.name"
            :required="field.required"
          />
        </div>
        <cta
          fill
          :background-color="buttonBackgroundColor"
          :disabled="submitting"
        >
          {{ submitValue }}
        </cta>
      </form>
    </expand-height>
  </div>
</template>

<script>
import { colors } from '~/recess.json'
import FormFileInput from '~/components/form/file'
import FormInput from '~/components/form/input'
import FormTextarea from '~/components/form/textarea'
import SelectDropdown from '~/components/form/select-dropdown'

export default {
  name: 'ContactForm',

  components: {
    FormFileInput,
    FormInput,
    FormTextarea,
    SelectDropdown
  },

  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    submitValue: {
      type: String,
      default: 'Submit'
    },
    successMessage: {
      type: String,
      default: ''
    },
    notificationRecipients: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      buttonBackgroundColor: colors.lavender,
      formData: {},
      submitting: false,
      error: false,
      errorMessage: 'Something went wrong. Please try again later.'
    }
  },

  computed: {
    action() {
      const url = new URL(process.env.formsEndpoint)
      url.pathname = `${url.pathname}/${this.id}`
      return url.toString()
    }
  },

  methods: {
    handleInput(event) {
      const { name, value } = event.currentTarget
      this.$set(this.formData, name, value)
    },

    async handleSubmit(event) {
      if (this.submitting) {
        return
      }

      this.submitting = true

      try {
        const formData = new FormData(event.currentTarget)
        const response = await fetch(this.action, {
          method: 'POST',
          body: formData
        })
        if (response.ok) {
          this.submitting = false
          this.submitted = true
          this.$store.commit('page/setContactFormSubmitted', {
            id: this.id,
            message: this.successMessage,
            route: this.$route
          })
          // Scroll to top
          requestAnimationFrame(() => {
            document.documentElement.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
          })
        } else {
          throw new Error('Unexpected response', response)
        }
      } catch (error) {
        this.$sentry.captureException(error)
        this.submitting = false
        this.error = true
        // Scroll to form container
        requestAnimationFrame(() => {
          if (this.$refs.container) {
            this.$refs.container.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.contact__form {
  .dropdown__label {
    padding-left: 0 !important;
  }

  .file__label {
    padding-left: 0 !important;
  }
}
</style>
