<template>
  <block-content
    :blocks="blocks"
    :serializers="serializers"
    :image-options="{
      containerClass: containerClass,
      containersAreWide: containersAreWide
    }"
    render-container-on-single-child
  />
</template>

<script>
import BlockContent from 'sanity-blocks-vue-component'
import SanityContainer from '~/components/sanity/container.vue'
import SanityStandardBlock from '~/components/sanity/standard-container.vue'
import SanityBlock from '~/components/sanity/blocks/block.vue'
import SanityBlockquoteBlock from '~/components/sanity/blocks/blockquote.vue'
import SanityMediaBlock from '~/components/sanity/blocks/media.vue'
import SanityGalleryBlock from '~/components/sanity/blocks/gallery.vue'
import SanityTestimonialsBlock from '~/components/sanity/blocks/testimonials.vue'
import SanityContactForm from '~/components/sanity/blocks/contact-form.vue'
import SanityBulletedList from '~/components/sanity/lists/bulleted-list.vue'
import SanityOrderedList from '~/components/sanity/lists/ordered-list.vue'
import SanityLink from '~/components/sanity/marks/link.vue'
import SanityProductLink from '~/components/sanity/marks/product-link.vue'
import SanitySmall from '~/components/sanity/marks/small.vue'
import SanityLargeText from '~/components/sanity/marks/large-text.vue'
import SanityCta from '~/components/sanity/annotations/cta.vue'

export default {
  name: 'SanityBlocks',

  components: {
    BlockContent
  },

  props: {
    blocks: {
      default: () => [],
      type: Array
    },
    richText: {
      default: false,
      type: Boolean
    },
    standard: {
      default: false,
      type: Boolean
    },
    /* 
      there is a limitation with the vue 2.0 version of
      sanity-blocks-vue-component that makes it so we cannot
      pass arbitrary props to blocks so we have to use this
     */
    containerClass: {
      type: Boolean,
      default: true
    },
    containersAreWide: {
      type: Boolean,
      default: false
    }
  },

  data() {
    const serializers = {
      container: SanityContainer,
      marks: {
        cta: SanityCta,
        link: SanityLink,
        productLink: SanityProductLink,
        small: SanitySmall,
        large: SanityLargeText
      },
      types: {
        media: SanityMediaBlock,
        gallery: SanityGalleryBlock,
        blockquote: SanityBlockquoteBlock,
        testimonials: SanityTestimonialsBlock,
        form: SanityContactForm
      }
    }
    if (this.richText) {
      if (this.standard) {
        serializers.container = SanityStandardBlock
      }
      serializers.types.block = SanityBlock
      serializers.list = ({ type, children }) =>
        type === 'bullet' ? (
          <SanityBulletedList>{children}</SanityBulletedList>
        ) : (
          <SanityOrderedList>{children}</SanityOrderedList>
        )
    }
    return {
      serializers
    }
  }
}
</script>
