<template>
  <div :is="tag" :class="classes" class="drawers">
    <slot :scrim-id="`drawers-scrim-${$_uid}`" />
    <div
      :id="`drawers-scrim-${$_uid}`"
      class="absolute inset-0"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
export default {
  name: 'Drawers',

  props: {
    tag: {
      default: 'div',
      type: String
    },
    right: {
      default: false,
      type: Boolean
    }
  },

  computed: {
    classes() {
      return {
        'is-right': this.right
      }
    }
  }
}
</script>

<style>
.drawers {
  align-items: stretch;
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  min-height: 100%;
  padding-right: calc(var(--x-gutter) + var(--x-gutter-sm));
  position: relative;
  transform-style: preserve-3d;
}

.drawers.is-right {
  justify-content: flex-end;
  padding-left: calc(var(--x-gutter) + var(--x-gutter-sm));
  padding-right: 0;
}
</style>
