import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory'
import introspectionResult from '../generated/storefront-fragment-types.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResult
})

export default new InMemoryCache({ fragmentMatcher })
