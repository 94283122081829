<template>
  <div class="gallery" :class="classes">
    <slot name="top" />
    <cursor-over
      :enabled="cursorOver"
      :icon-left="leftIcon"
      :icon-center="centerIcon"
      :icon-right="rightIcon"
      :icon-override="zoomIcon"
      :color="color"
      :center-area-enabled="centerAreaEnabled"
      :center-area-width="currentMediaWidth"
      :hide-cursor-in-center="hideCursorInCenter"
      class="relative w-full h-full"
      @click-left="previous"
      @click-right="next"
      @click-center="openLightbox"
    >
      <div
        v-swiper:swiper="{
          initialSlide,
          lazy: {
            loadPrevNext: true
          },
          centeredSlides: true,
          observer: true,
          observeParents: true,
          slidePrevClass: 'is-prev',
          slideActiveClass: 'is-active',
          slideNextClass: 'is-next',
          pagination: {
            el: `.swiper-pagination-${$_uid}`,
            clickable: true
          },
          virtual: virtual,
          on: {
            zoomChange: zoomChange
          },
          ...options
        }"
        class="gallery__swiper swiper"
        @slide-change="slideChange"
        @transition-start="$emit('update:transitioning', true)"
        @transition-end="$emit('update:transitioning', false)"
        @zoom-change="zoomChange"
      >
        <div ref="slidesContainer" class="gallery__wrapper swiper-wrapper">
          <!-- expects children with swiper-slide class -->
          <slot />
        </div>
      </div>
      <slot v-if="isShowingArrows" name="arrows">
        <nav>
          <button
            v-if="!atStart"
            class="gallery__button gallery__button--left link"
            @click.prevent="previous"
          >
            <span class="font-blank">Go to previous slide</span>
            <fa :icon="leftIcon" class="gallery__icon" />
          </button>
          <button
            v-if="!atEnd"
            class="gallery__button gallery__button--right link"
            @click.prevent="next"
          >
            <span class="font-blank">Go to next slide</span>
            <fa :icon="rightIcon" class="gallery__icon" />
          </button>
        </nav>
      </slot>
    </cursor-over>
    <slot name="bottom">
      <fade>
        <div
          v-show="!zoomedIn"
          :class="`swiper-pagination-${$_uid}`"
          class="gallery__pagination swiper-pagination"
        />
      </fade>
    </slot>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Gallery',

  props: {
    slides: {
      default: () => [],
      type: Array
    },
    options: {
      default: () => {},
      type: Object
    },
    activeSlide: {
      default: 0,
      type: Number
    },
    transitioning: {
      default: false,
      type: Boolean
    },
    arrows: {
      default: false,
      type: Boolean
    },
    color: {
      default: undefined,
      type: String
    },
    cursorOver: {
      default: true,
      type: Boolean
    },
    centerAreaEnabled: {
      default: false,
      type: Boolean
    },
    magnifierClick: {
      default: () => {},
      type: Function
    },
    minSidePercent: {
      default: 0.2,
      type: Number
    },
    hideCursorInCenter: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      vm: this,
      virtualSlides: [],
      initialSlide: this.activeSlide,
      slideIndex: this.activeSlide,
      slideCount: this.slides.length,
      zoomedIn: false,
      init: false,
      slidesContainerRef: null,
      currentMediaWidth: 0
    }
  },

  computed: {
    ...mapState('screen', {
      touch: state => state.touch
    }),
    classes() {
      return {
        'is-transitioning': this.transitioning
      }
    },
    atStart() {
      return this.slideIndex === 0
    },
    atEnd() {
      return this.slideIndex === this.slideCount - 1
    },

    // somehow these icons changing and causing cursor-over to re-render
    // results in the swiper getting stuck on the last slide of a a looped list
    leftIcon() {
      return this.loop
        ? ''
        : this.atStart
        ? 'arrow-alt-to-left'
        : 'arrow-alt-left'
    },

    // see above note
    rightIcon() {
      return this.loop
        ? ''
        : this.atEnd
        ? 'arrow-alt-to-right'
        : 'arrow-alt-right'
    },

    centerIcon() {
      return this.centerAreaEnabled ? 'hand-pointer' : null
    },

    zoomIcon() {
      return this.zoomedIn ? 'expand-arrows-alt' : undefined
    },
    loop() {
      return this.options.loop === true
    },
    isShowingArrows() {
      return !this.loop && this.slideCount > 1 && (this.touch || this.arrows)
    },
    virtual() {
      return this.loop
        ? false
        : {
            slide: this.slides,
            renderExternal(data) {
              this.virtualSlides = data
            }
          }
    }
  },

  watch: {
    activeSlide(index) {
      this.goto(index)
    }
  },

  mounted() {
    if (this.centerAreaEnabled) {
      this.setCurrentMediaWidth()
      this.$nextTick(this.resize)
      this.$bus.$on('resize', this.resize)
    }
  },

  beforeDestroy() {
    if (this.centerAreaEnabled) {
      this.$bus.$off('resize', this.resize)
    }
  },

  methods: {
    slideChange({ activeIndex, realIndex }) {
      this.slideIndex = realIndex
      this.$emit('update:activeSlide', activeIndex)
      this.$emit('slide-change', realIndex)
    },
    getActiveIndex() {
      let index = -1
      if (this.swiper) {
        index = this.swiper.activeIndex
      }
      return index
    },
    getAnimating() {
      let animating = false
      if (this.swiper) {
        animating = this.swiper.animating
      }
      return animating
    },
    next() {
      if (this.zoomedIn) {
        return
      }
      this.swiper.slideNext()
      this.setCurrentMediaWidth()
    },
    previous() {
      if (this.zoomedIn) {
        return
      }
      this.swiper.slidePrev()
      this.setCurrentMediaWidth()
    },
    goto(index) {
      this.swiper.slideTo(index)
      this.setCurrentMediaWidth()
    },
    toggleZoom() {
      this.swiper.zoom.toggle()
    },
    zoomChange(swiper, scale) {
      this.zoomedIn = scale > 1
      this.$emit('update:zoom', scale)
    },
    openLightbox(slide) {
      this.magnifierClick()
      this.setCurrentMediaWidth()
    },
    resize() {
      this.setCurrentMediaWidth()
    },
    setCurrentMediaWidth() {
      if (!this.centerAreaEnabled) {
        return
      }

      const currentSlideIndex = this.getActiveIndex()
      const container = this.$refs.slidesContainer
      const slideElement = container.children[currentSlideIndex]
      const imageElement = slideElement.querySelector('img')

      if (imageElement) {
        this.setCurrentImageWidth(slideElement, imageElement)
        return
      }

      const muxVideoElement = slideElement.querySelector('.mux-video')

      if (muxVideoElement) {
        this.setCurrentVideoWidth(slideElement, muxVideoElement)
        return
      }

      // If we didn't find an image or video, then it's probably a
      // Mux video that hasn't been loaded yet, so we wait 500ms and
      // try again.
      setTimeout(() => {
        this.setCurrentMediaWidth()
      }, 500)
    },
    setCurrentImageWidth(slideElement, imageElement) {
      const imageWidth = imageElement.width

      // If imageElement.naturalWidth = 1, it means the image is not loaded yet.
      // We need to wait for the image to be loaded to get the correct width.
      if (imageElement.naturalWidth === 1) {
        imageElement.addEventListener('load', () => {
          this.setCurrentImageWidth(slideElement, imageElement)
        })
        return
      }

      // During resizes, the image width can be 0 so we need to wait a bit.
      if (imageWidth === 0) {
        setTimeout(() => {
          this.setCurrentImageWidth(slideElement, imageElement)
        }, 500)
        return
      }

      this.currentMediaWidth = this.computeMediaWidth(
        slideElement.clientWidth,
        imageWidth
      )
    },
    setCurrentVideoWidth(slideElement, muxVideoElement) {
      const videoWidth = muxVideoElement.offsetWidth

      this.currentMediaWidth = this.computeMediaWidth(
        slideElement.clientWidth,
        videoWidth
      )
    },
    computeMediaWidth(parentWidth, mediaWidth) {
      // If the image spans too much of the parent div, the {minSidePercent} value will be used to
      // define an area for the arrows on each side of the parent div regardless of the image size.
      const minSideWidth = parentWidth * this.minSidePercent
      const halfSlideWidth = parentWidth / 2
      const maxHalfImageWidth = halfSlideWidth - minSideWidth
      const halfImageWidth = mediaWidth / 2

      if (halfImageWidth > maxHalfImageWidth) {
        mediaWidth = mediaWidth * (1 - this.minSidePercent * 2)
      }

      return mediaWidth
    }
  }
}
</script>

<style>
.gallery {
  --swiper-theme-color: var(--scheme-color);
  --pagination-gutter: 2em;
  max-width: 100%;
  padding-bottom: calc(var(--pagination-gutter) / 3);
  position: relative;
  user-select: none;
}

.gallery__button {
  display: none;
  font-size: var(--cursor-size);
  position: absolute;
  top: 50%;
  z-index: 2;
}

.gallery__button--left {
  right: calc(100% - var(--x-gutter-sm));
  transform: translate(50%, -50%);
}

.gallery__button--right {
  left: calc(100% - var(--x-gutter-sm));
  transform: translate(-50%, -50%);
}

.gallery__icon {
  color: var(--cursor-color);
}

.gallery .swiper-slide-zoomed {
  cursor: none;
}

.swiper-pagination {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.swiper-pagination-bullet {
  background: transparent;
  border: 1px solid currentColor;
  height: 1rem;
  margin: 0 3px;
  opacity: 1;
  width: 1rem;
}

.swiper-pagination-bullet-active {
  background: currentColor;
}

@screen md {
  .gallery__button {
    display: inline-block;
  }
}
</style>
