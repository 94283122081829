import centered from '../styles/centered'
import heading from '../styles/heading'
import link from '../annotations/link'
import productLink from '../annotations/product-link'
import cta from '../annotations/cta'
import small from '../decorators/small'
import largeText from '../decorators/large-text'
import media from './media'
import gallery from './gallery'
import testimonials from './testimonials'
import contactForm from './contact-form'

export default {
  title: 'Text',
  name: 'text',
  type: 'array',
  of: [
    {
      type: 'block',
      styles: [
        { title: 'Normal', value: 'normal' },
        { title: 'H1', value: 'h3' },
        { title: 'H2', value: 'h4' },
        { title: 'H3', value: 'h5' },
        { title: 'Quote', value: 'blockquote' },
        heading,
        centered
      ],
      lists: [
        { title: 'Bullet', value: 'bullet' },
        { title: 'Numbered', value: 'number' }
      ],
      marks: {
        decorators: [
          { title: 'Strong', value: 'strong' },
          { title: 'Emphasis', value: 'em' },
          small,
          largeText
        ],
        annotations: [link, productLink, cta]
      }
    },
    media,
    gallery,
    testimonials,
    contactForm
  ]
}
