import { IoMdMail } from 'react-icons/io'

export default {
  title: 'Contact Form',
  name: 'contactForm',
  type: 'reference',
  to: [{ type: 'form' }],
  icon: IoMdMail,
  description: 'Renders a contact form'
}
