<template>
  <div
    :is="tag"
    v-observe-visibility="visibilityChange"
    v-bind="$attrs"
    :class="classes"
    :style="style"
    class="thumb"
    @mouseover.native="touch && over"
    @focus.native="!touch && over"
    @mouseleave.native="!touch && out"
    @blur.native="!touch && out"
  >
    <template v-if="hoverImages && thumb.hovers && thumb.hovers.length > 0">
      <div
        v-for="(image, i) in thumb.hovers"
        :key="`image-${i}`"
        class="thumb__hover"
      >
        <float
          tag="sanity-media"
          :image="image"
          :speed="i % 2 ? 8 : 10"
          :reverse="i % 2 ? true : false"
          :offset="i % 2 ? '8%' : '-6%'"
          max-width="240"
          max-height="240"
          alt
          class="thumb__hover-image"
        />
      </div>
    </template>
    <div class="thumb__content">
      <slot />
    </div>
    <div class="thumb__bottom">
      <h4 v-if="title" class="thumb__title">{{ title }}</h4>
      <slot name="bottom" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Thumb',

  props: {
    tag: {
      default: 'div',
      type: String
    },
    thumb: {
      default: () => ({}),
      type: Object
    },
    hoverImages: {
      default: false,
      type: Boolean
    },
    colors: {
      default: undefined,
      type: Object
    },
    title: {
      default: undefined,
      type: String
    },
    hoverTitle: {
      default: false,
      type: Boolean
    },
    inline: {
      default: false,
      type: Boolean
    },
    square: {
      default: false,
      type: Boolean
    },
    setColorsManually: {
      default: false,
      type: Boolean
    }
  },

  data: () => ({
    active: false
  }),

  computed: {
    classes() {
      return {
        'thumb--block': !this.inline,
        'thumb--inline': this.inline,
        'thumb--square': this.square,
        'thumb--hover-title': this.hoverTitle,
        'is-active': this.active
      }
    },
    ...mapState({
      touch: state => state.screen.touch
    }),
    style() {
      const colors = this.colors || {}
      return {
        '--link-color': colors.textColor
      }
    },
    sectionColors() {
      const id = this.$_uid
      const colors = this.colors
      if (!colors) {
        return null
      }
      return { id, ...colors }
    }
  },

  beforeDestroy() {
    this.unsetColors()
  },

  methods: {
    ...mapActions('screen', {
      addSectionColors: 'addSectionColors',
      removeSectionColors: 'removeSectionColors'
    }),
    visibilityChange(visible) {
      if (!visible) {
        this.unsetColors()
      }
    },
    setColors() {
      const colors = this.sectionColors
      if (colors) {
        this.addSectionColors(colors)
      }
    },
    unsetColors() {
      const colors = this.sectionColors
      if (colors) {
        this.removeSectionColors(colors)
      }
    },
    over() {
      if (this.active) {
        return
      }
      if (!this.setColorsManually && !this.inline) {
        this.setColors()
      }
      this.active = true
    },
    out() {
      if (!this.active) {
        return
      }
      if (!this.setColorsManually && !this.inline) {
        this.unsetColors()
      }
      this.active = false
    }
  }
}
</script>

<style>
/* base thumb styles */

.thumb {
  display: flex;
  flex-direction: column;
  padding: var(--thumb-gutter);
  position: relative;
}

.thumb__content {
  flex: 1 1 auto;
  position: relative;
  width: 100%;
}

.thumb__title {
  font-size: theme('fontSize.sm');
  font-weight: theme('fontWeight.medium');
  line-height: theme('lineHeight.tight');
  min-height: 3em;
  padding-top: 1em;
  position: relative;
  z-index: 4;
}

.thumb--square .thumb__content > * {
  height: 0;
  padding-top: 100%;
}

.thumb--square .thumb__content > * .image,
.thumb--square .thumb__content > * .image img {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.thumb__bottom {
  flex: 0 1 auto;
}

.thumb__hover {
  display: block;
  max-height: 50%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transform-origin: center;
  transition: opacity var(--hover-speed), transform var(--hover-speed),
    visibility var(--hover-speed);
  visibility: hidden;
  width: var(--thumb-hover-size);
  z-index: 3;
}

.thumb__hover-image {
  top: 50%;
  transform: translateY(-50%);
}

.thumb__hover-image img {
  height: 100%;
  max-width: none;
  object-fit: contain;
  object-position: center;
  width: 100%;
}

.thumb--hover-title .thumb__title {
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--hover-speed), visibility var(--hover-speed);
  visibility: hidden;
}

.is-active .thumb--hover-title .thumb__title,
.thumb--hover-title.is-active .thumb__title,
[data-js-focus-visible]
  .thumb--hover-title[data-focus-visible-added]
  .thumb__title {
  opacity: 1;
  pointer-events: auto;
  transition: opacity var(--hover-speed);
  visibility: visible;
}

.is-active .thumb,
.thumb.is-active,
.thumb:hover,
[data-js-focus-visible] .thumb[data-focus-visible-added] {
  z-index: 5;
}

.is-active .thumb .thumb__hover,
.thumb.is-active .thumb__hover,
[data-js-focus-visible] .thumb[data-focus-visible-added] .thumb__hover {
  opacity: 1;
  transition: opacity var(--hover-speed), transform var(--hover-speed);
  visibility: visible;
}

/* block thumbs */

.thumb--block {
  --thumb-hover-size: var(--thumb-block-hover-size);
  --thumb-top-left: translate(-40%, 20%) scale(0.75) rotate(20deg);
  --thumb-top-left-end: translate(-25%, 0) scale(1) rotate(0deg);
  --thumb-bottom-right: translate(40%, 20%) scale(0.75) rotate(-20deg);
  --thumb-bottom-right-end: translate(25%, 0) scale(1) rotate(0deg);
  --thumb-bottom-left: translate(-40%, 20%) scale(0.5) rotate(0deg);
  --thumb-bottom-left-end: translate(-25%, 0) scale(1) rotate(0deg);
  --thumb-top-right: translate(40%, 20%) scale(0.5) rotate(0deg);
  --thumb-top-right-end: translate(25%, 0) scale(1) rotate(0deg);
  --thumb-top-center: translate(-50%, 20%) scale(0.33) rotate(15deg);
  --thumb-top-center-end: translate(-50%, 0) scale(0.66) rotate(0deg);
  --thumb-bottom-center: translate(-50%, 20%) scale(0.33) rotate(15deg);
  --thumb-bottom-center-end: translate(-50%, 0) scale(0.66) rotate(0deg);
}

/* first block thumb hover */

.thumb--block .thumb__hover:nth-child(1) {
  left: 0;
  top: 0;
}

/* second block thumb hover */

.thumb--block .thumb__hover:nth-child(2) {
  bottom: 0;
  right: 0;
}

/* third block thumb hover */

.thumb--block .thumb__hover:nth-child(3) {
  left: 0;
  top: 100%;
}

/* fourth block thumb hover */

.thumb--block .thumb__hover:nth-child(4) {
  right: 0;
  top: 0;
}

/* fifth block thumb hover */

.thumb--block .thumb__hover:nth-child(5) {
  bottom: 100%;
  left: 50%;
}

/* sixth block thumb hover */

.thumb--block .thumb__hover:nth-child(6) {
  left: 50%;
  top: 50%;
}

/* inline thumbs */

.thumb--inline {
  --thumb-hover-size: min(var(--thumb-inline-hover-size), 3em);
  --thumb-top-left: translate(-25%, -50%) scale(0.75);
  --thumb-top-left-end: translate(-100%, -75%) scale(1);
  --thumb-bottom-right: translate(0, 0) scale(0.75);
  --thumb-bottom-right-end: translate(100%, 50%) scale(1);
  --thumb-bottom-left: translate(-50%, 50%) scale(0.5) rotate(0deg);
  --thumb-bottom-left-end: translate(-125%, 0%) scale(0.75) rotate(10deg);
  --thumb-top-right: translate(0, 0) scale(0.5) rotate(0deg);
  --thumb-top-right-end: translate(125%, -50%) scale(0.75) rotate(-10deg);
  --thumb-top-center: translate(-50%, 20%) scale(0.33) rotate(15deg);
  --thumb-top-center-end: translate(25%, -50%) scale(0.66) rotate(0deg);
  --thumb-bottom-center: translate(-10%, 0) scale(0.33) rotate(0deg);
  --thumb-bottom-center-end: translate(-20%, 25%) scale(0.66) rotate(15deg);

  display: inline-block;
  flex-direction: unset;
  padding-left: 0;
  padding-right: 0;
}

/* first inline thumb hover */

.thumb--inline .thumb__hover:nth-child(1) {
  bottom: 100%;
  left: -2.5%;
}

/* second inline thumb hover */

.thumb--inline .thumb__hover:nth-child(2) {
  right: -2.5%;
  top: 100%;
}

/* third inline thumb hover */

.thumb--inline .thumb__hover:nth-child(3) {
  left: -5%;
  top: 100%;
}

/* fourth inline thumb hover */

.thumb--inline .thumb__hover:nth-child(4) {
  right: -5%;
  top: -5%;
}

/* fifth inline thumb hover */

.thumb--inline .thumb__hover:nth-child(5) {
  bottom: 100%;
  left: 50%;
}

/* sixth inline thumb hover */

.thumb--inline .thumb__hover:nth-child(6) {
  left: 50%;
  top: 100%;
}

/* first thumb hover */

.thumb__hover:nth-child(1) {
  transform: var(--thumb-top-left);
}

.thumb:hover .thumb__hover:nth-child(1),
.is-active .thumb .thumb__hover:nth-child(1),
.thumb.is-active .thumb__hover:nth-child(1),
[data-js-focus-visible]
  .thumb[data-focus-visible-added]
  .thumb__hover:nth-child(1) {
  transform: var(--thumb-top-left-end);
}

/* second thumb hover */

.thumb .thumb__hover:nth-child(2) {
  transform: var(--thumb-bottom-right);
}

.thumb:hover .thumb__hover:nth-child(2),
.is-active .thumb .thumb__hover:nth-child(2),
.thumb.is-active .thumb__hover:nth-child(2),
[data-js-focus-visible]
  .thumb[data-focus-visible-added]
  .thumb__hover:nth-child(2) {
  transform: var(--thumb-bottom-right-end);
}

/* third thumb hover */

.thumb .thumb__hover:nth-child(3) {
  transform: var(--thumb-bottom-left);
}

.thumb:hover .thumb__hover:nth-child(3),
.is-active .thumb .thumb__hover:nth-child(3),
.thumb.is-active .thumb__hover:nth-child(3),
[data-js-focus-visible]
  .thumb[data-focus-visible-added]
  .thumb__hover:nth-child(3) {
  transform: var(--thumb-bottom-left-end);
}

/* fourth thumb hover */

.thumb .thumb__hover:nth-child(4) {
  transform: var(--thumb-top-right);
}

.thumb:hover .thumb__hover:nth-child(4),
.is-active .thumb .thumb__hover:nth-child(4),
.thumb.is-active .thumb__hover:nth-child(4),
[data-js-focus-visible]
  .thumb[data-focus-visible-added]
  .thumb__hover:nth-child(4) {
  transform: var(--thumb-top-right-end);
}

/* fifth thumb hover */

.thumb .thumb__hover:nth-child(5) {
  transform: var(--thumb-top-center);
}

.thumb:hover .thumb__hover:nth-child(5),
.is-active .thumb .thumb__hover:nth-child(5),
.thumb.is-active .thumb__hover:nth-child(5),
[data-js-focus-visible]
  .thumb[data-focus-visible-added]
  .thumb__hover:nth-child(5) {
  transform: var(--thumb-top-center-end);
}

/* sixth thumb hover */

.thumb .thumb__hover:nth-child(6) {
  transform: var(--thumb-bottom-center);
}

.thumb:hover .thumb__hover:nth-child(6),
.is-active .thumb .thumb__hover:nth-child(6),
.thumb.is-active .thumb__hover:nth-child(6),
[data-js-focus-visible]
  .thumb[data-focus-visible-added]
  .thumb__hover:nth-child(6) {
  transform: var(--thumb-bottom-center-end);
}

@media (hover) {
  .thumb:hover .thumb__hover,
  .thumb--hover-title:hover .thumb__title {
    opacity: 1;
    visibility: visible;
  }

  .thumb:hover .thumb__hover {
    transition: opacity var(--hover-speed), transform var(--hover-speed);
  }

  .thumb--hover-title:hover .thumb__title {
    pointer-events: auto;
    transition: opacity var(--hover-speed);
  }
}

@screen lg {
  .thumb__title {
    padding-top: 1.5em;
  }
}

@screen 2xl {
  .thumb__title {
    font-size: theme('fontSize.base');
  }
}
</style>
