import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {"position":"bottom-center","iconPack":"fontawesome"})

const globals = [{"name":"apolloError","message":function(payload) {
        if (payload.message) {
          return payload.message.
          replace('GraphQL error:', '').
          replace('Error:', '').
          trim();
        } else {
          return _recess.messages.error;
        }
      },"options":{"type":"error","duration":3000}},{"name":"cartInfo","message":function(payload) {
        if (payload.message) {
          return payload.message;
        }
      },"options":{"type":"info","duration":5000,"singleton":true,"action":{"text":"ok","onClick":(e, toast) => {
            toast.goAway(0);
          }}}}]
if(globals) {
  globals.forEach(global => {
    Vue.toasted.register(global.name, global.message, global.options)
  })
}

export default function (ctx, inject) {
  inject('toast', Vue.toasted)
}
