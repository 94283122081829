<template>
  <header
    id="site-header"
    :class="{ 'header--unpinned': scrolled }"
    class="text-scheme"
    :style="{ '--global-banner-height': globalBannerHeight + 'px' }"
  >
    <div class="container h-header-inner relative z-above">
      <fade mode="out-in">
        <cart-button
          v-if="bp && cartOpen && !bp.md"
          icon="chevron-left"
          close
        />
        <burger
          v-else-if="bp && !cartOpen && !bp.md"
          :active="menuOpen"
          @click.stop.prevent="toggleMenu"
        />
      </fade>
      <h2>
        <recess-link
          to="/"
          class="link link--never-fade block"
          @click.native="logoClick"
        >
          <span class="font-blank">Recess</span>
          <logo class="block h-header-inner" />
        </recess-link>
      </h2>
      <nav class="lowercase">
        <ul>
          <li>
            <button class="link link--shop" @click.stop.prevent="toggleMenu">
              Shop
              <span class="inline-block w-4 h-0 text-center">
                <fa v-if="bp && bp.md && menuOpen" :icon="['fal', 'times']" />
                <span v-else-if="bp" class="font-antidote">&larr;</span>
              </span>
            </button>
          </li>
        </ul>
        <fade enter-delay="0.5">
          <ul v-if="overlay !== 'gallery'">
            <li>
              <n-link to="/shop/buy-now/subscribe" class="link">
                Subscribe &amp; save
              </n-link>
            </li>
            <li>
              <recess-link :sanity-link="loginLink" />
            </li>
          </ul>
        </fade>
      </nav>
      <div class="flex-grow-0">
        <fade mode="out-in">
          <circular-cta
            v-if="bp && overlay === 'gallery'"
            class="close-button text-2xl text-center"
            fill
            small
            @click.stop.prevent="closeOverlay"
          >
            <fa icon="times" />
          </circular-cta>
          <cart-button
            v-else-if="bp && (bp.md || overlay !== 'cart')"
            class="relative"
          />
        </fade>
      </div>
    </div>
    <gradient
      v-for="i in backgroundLayers"
      :key="`gradient-${i}`"
      :colors="colors"
      force-gradient
      class="absolute inset-0 pointer-events-none"
    />
  </header>
</template>

<script>
import imagesLoaded from 'imagesloaded'
import { gsap } from 'gsap'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import homeQuery from '~/sanity/queries/home'
import Logo from '~/components/logo.vue'
import CartButton from '~/components/shop/cart-button.vue'
import { getSemiopaqueColor, getTransparentColor } from '~/util/color'

// double-up on gradients to increase opacity while also
// maintaining the smooth transition from the bottom edge
const backgroundLayers = 2

export default {
  name: 'SiteHeader',

  components: {
    Logo,
    CartButton
  },

  data: () => ({
    backgroundLayers,
    limitPosition: 56,
    scrolled: false,
    lastPosition: 0,
    home: undefined
  }),

  async fetch() {
    let queryId = this.$route.query.preview
    if (this.nonCBDSite) {
      queryId = 'home-non-cbd'
    }
    if (this.samplerSplash) {
      queryId = 'home-sampler'
    }

    try {
      const home = await this.$sanity.fetch(homeQuery(queryId))

      if (!home) {
        throw new Error('Category not found')
      }
      this.home = home
    } catch (e) {
      this.$sentry.captureException(e)
    }
  },

  computed: {
    ...mapState({
      loginLink: state => state.loginLink,
      overlay: state => state.screen.overlay,
      globalBannerHeight: state => state.screen.globalBannerHeight
    }),
    ...mapGetters('screen', {
      bp: 'breakpoint',
      transparentColors: 'transparentColors',
      semiTransparentColors: 'semiTransparentColors'
    }),
    samplerSplash() {
      return process.env.samplerSplash
    },
    cartOpen() {
      return this.overlay === 'cart'
    },
    menuOpen() {
      return this.overlay === 'site-menu'
    },
    colors() {
      if (
        !this.cartOpen &&
        !this.menuOpen &&
        this.$nuxt.$route.path === '/' &&
        this.home &&
        this.home.featuredProductEnabled &&
        this.home.featuredProductHeaderTopGradientColor
      ) {
        return [
          getSemiopaqueColor(this.home.featuredProductHeaderTopGradientColor),
          getTransparentColor(this.home.featuredProductHeaderTopGradientColor)
        ]
      }

      if (this.overlay) {
        return this.transparentColors
      } else {
        return this.semiTransparentColors
      }
    }
  },

  mounted() {
    imagesLoaded(this.$el, this.resize)
    this.$bus.$on('resize', this.resize)

    window.addEventListener('scroll', this.handleScroll)
  },

  beforeDestroy() {
    this.$bus.$off('resize', this.resize)
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    ...mapActions('screen', {
      setHeaderHeight: 'setHeaderHeight'
    }),
    ...mapMutations('screen', {
      setOverlay: 'setOverlay',
      setTransitioning: 'setTransitioning'
    }),
    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true
      }

      if (this.lastPosition > window.scrollY) {
        this.scrolled = false
      }

      this.lastPosition = window.scrollY
    },
    resize({ sameWidth = false }) {
      if (!this.bp || (!this.bp.lg && sameWidth)) {
        return
      }
      this.setHeaderHeight(this.$el)
    },
    toggleMenu() {
      this.setOverlay(this.menuOpen ? null : 'site-menu')
    },
    closeCart() {
      if (this.cartOpen) {
        this.closeOverlay()
      }
    },
    closeOverlay() {
      this.setOverlay()
    },
    async logoClick() {
      if (this.$route.name === 'index') {
        const vm = this
        this.setTransitioning(true)
        // wait for vue update
        await this.$nextTick()
        gsap.to(window, {
          scrollTo: {
            y: 0,
            autoKill: true,
            onAutoKill() {
              vm.setTransitioning(false)
            }
          },
          duration: 2.5,
          ease: 'expo.inOut',
          onInterrupt() {
            vm.setTransitioning(false)
          },
          onComplete() {
            vm.setTransitioning(false)
          }
        })
      }
    }
  }
}
</script>

<style scoped>
header {
  left: 0;
  padding-bottom: var(--header-gutter-bottom);
  padding-top: calc(
    var(--header-gutter-top) + var(--global-banner-height) + 32px
  );
  position: fixed;
  top: 0;
  transform: translateZ(200px);
  transition: all 0.2s ease;
  user-select: none;
  width: 100%;

  @apply z-header;
}

.header--unpinned {
  padding-top: var(--header-gutter-top);
}

.container {
  align-items: flex-end;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.scheme-dark header,
.scheme-light header {
  --scheme-color: var(--text-color);
}

.is-overlay:not(.overlay--gallery) header,
.is-overlay:not(.overlay--gallery).scheme-dark header .burger,
.is-overlay:not(.overlay--gallery).scheme-dark header .link--shop {
  --scheme-color: var(--light-color);
  color: var(--light-color);
}

h2 {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}

nav {
  position: fixed;
  right: 100%;
  top: 0;
}

header .close-button {
  --cta-text-color: theme('colors.white');
  --cta-background: theme('colors.ultramarine');
  --scheme-color: var(--cta-text-color);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@screen md {
  .is-overlay.overlay--cart header,
  .is-overlay.overlay--cart.scheme-dark header .burger,
  .is-overlay.overlay--cart.scheme-dark header .link--shop {
    --scheme-color: var(--dark-color);
    color: var(--dark-color);
  }

  .container {
    justify-content: normal;
  }

  header {
    padding-top: calc(
      var(--header-gutter-top) + var(--global-banner-height) + 24px
    );
  }

  header /deep/ .burger {
    display: none;
  }

  h2,
  nav {
    position: static;
    right: auto;
    top: auto;
  }

  h2 {
    margin-right: calc(var(--header-gutter) * 1.5);
    transform: none;
  }

  .overlay--site-menu.scheme-dark header h2 {
    --scheme-color: var(--light-color);
  }

  nav,
  nav ul {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
  }

  nav {
    flex: 1 1 auto;
    justify-content: space-between;
    position: static;
  }

  nav li,
  header .close-button {
    margin-left: calc(var(--header-gutter) * 1.5);
  }

  header /deep/ .cart-button {
    margin-left: var(--header-gutter);
  }

  nav li:first-child {
    flex: 0 0 auto;
    margin-left: 0;
  }
}
</style>
