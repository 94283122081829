import Vue from 'vue'
import { config, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faTimes,
  faPlus,
  faMinus,
  faHandPointer,
  faChevronLeft,
  faChevronDown,
  faArrowAltLeft,
  faArrowAltToLeft,
  faArrowAltRight,
  faArrowAltToRight,
  faExpandArrowsAlt,
  faSearchPlus,
  faSearchMinus,
  faLink,
  faExclamationCircle,
  faCircleNotch
} from '@fortawesome/pro-solid-svg-icons'
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons'
import {
  faTwitter,
  faInstagram,
  faFacebook,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'

// Nuxt handles CSS
config.autoAddCss = false

library.add(
  faTimes,
  faPlus,
  faMinus,
  faHandPointer,
  faChevronLeft,
  faChevronDown,
  faArrowAltLeft,
  faArrowAltToLeft,
  faArrowAltRight,
  faArrowAltToRight,
  faExpandArrowsAlt,
  faSearchPlus,
  faSearchMinus,
  faLink,
  faExclamationCircle,
  faCircleNotch,
  falTimes,
  faTwitter,
  faInstagram,
  faFacebook,
  faYoutube
)

Vue.component('fa', FontAwesomeIcon)
