import { IoIosAddCircle } from 'react-icons/io'

export default {
  title: 'Rebuy',
  name: 'rebuy',
  type: 'document',
  icon: IoIosAddCircle,
  __experimental_actions: ['update', 'publish'],
  fields: [
    {
      title: 'Data Source ID',
      name: 'dataSourceId',
      type: 'string'
    }
  ]
}
