<template>
  <video-player
    v-if="video && showVideoPlayer"
    v-bind="video"
    :ratio="ratio"
    :stream="stream"
    :cover="cover"
    @loaded="$emit('loaded')"
  />
  <recess-image
    v-else-if="image && src"
    :src="src"
    :alt="caption"
    :width="imageSize.width"
    :height="imageSize.height"
    :fluid="fluid"
    :boxed="boxed"
    :cover="cover"
    :contain="contain"
    :ratio="ratio"
    :no-ratio="noRatio"
    :spinner="swiper ? true : spinner"
    :not-lazy="notLazy"
    :image-class="imageClass"
    @loaded="$emit('loaded')"
  />
  <video-loop
    v-else-if="video && !showVideoPlayer"
    v-bind="video"
    :stream="stream"
    :cover="cover"
    @loaded="$emit('loaded')"
  />
</template>

<script>
import { mapActions } from 'vuex'
const VideoPlayer = () => import('./video-player')
const VideoLoop = () => import('./video-loop')

export default {
  name: 'SanityMedia',

  components: {
    VideoLoop,
    VideoPlayer
  },

  props: {
    image: {
      default: null,
      type: Object
    },
    imageSrc: {
      default: undefined,
      type: String
    },
    video: {
      default: null,
      type: Object
    },
    showVideoPlayer: {
      default: false,
      type: Boolean
    },
    maxWidth: {
      default: 1024,
      type: [Number, String]
    },
    maxHeight: {
      default: 1024,
      type: [Number, String]
    },
    width: {
      default: undefined,
      type: [Number, String]
    },
    height: {
      default: undefined,
      type: [Number, String]
    },
    maxSize: {
      default: false,
      type: Boolean
    },
    caption: {
      default: null,
      type: String
    },
    spinner: {
      default: false,
      type: Boolean
    },
    crop: {
      default: false,
      type: Boolean
    },
    fluid: {
      default: false,
      type: Boolean
    },
    boxed: {
      default: false,
      type: Boolean
    },
    cover: {
      default: false,
      type: Boolean
    },
    contain: {
      default: false,
      type: Boolean
    },
    noRatio: {
      default: false,
      type: Boolean
    },
    notLazy: {
      default: false,
      type: Boolean
    },
    swiper: {
      default: false,
      type: Boolean
    },
    imageClass: {
      default: '',
      type: String
    },
    ratio: {
      type: Number,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    src: undefined
  }),

  async fetch() {
    const imageSrc = this.imageSrc
    const image = this.image
    if (imageSrc) {
      this.src = imageSrc
      return
    }
    if (image) {
      let width
      let height

      if (this.maxSize) {
        width = this.imageSize.width
        height = this.imageSize.height
      } else {
        width = this.width || this.maxWidth
        height = this.height || this.maxHeight
      }

      const src = await this.getImageUrl({
        image,
        width,
        height,
        crop: this.crop
      })
      this.src = src
    }
  },

  computed: {
    imageSize() {
      const size = {}
      const image = this.image
      if (image) {
        size.width = this.width || image.metadata?.width
        size.height = this.height || image.metadata?.height
      }
      return size
    },
    stream() {
      return this.video ? this.video.src : undefined
    }
  },

  methods: {
    ...mapActions({
      getImageUrl: 'getImageUrl'
    })
  }
}
</script>
