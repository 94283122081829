// import React from 'react'
import { IoIosPaper } from 'react-icons/io'

export default {
  title: 'Press',
  name: 'press',
  type: 'document',
  icon: IoIosPaper,
  fields: [
    {
      title: 'Press Outlet Name',
      name: 'title',
      type: 'string',
      validation: Rule => Rule.required().error('A name is required')
    },
    {
      title: 'Press Outlet Logo',
      name: 'image',
      type: 'image'
    },
    {
      title: 'Quote',
      name: 'quote',
      type: 'text',
      rows: 3,
      description: 'Without quotation marks',
      validation: Rule =>
        Rule.custom(quote => {
          let valid = true
          if (typeof quote === 'undefined') {
            valid = 'A quote is required'
          } else if (
            quote.startsWith('"') ||
            quote.startsWith('“') ||
            quote.endsWith('"') ||
            quote.endsWith('”')
          ) {
            valid = 'Quotation marks will be added for you 🙂'
          }
          return valid
        })
    },
    {
      title: 'Optional URL',
      name: 'url',
      type: 'url'
    }
  ],
  preview: {
    select: {
      title: 'title',
      media: 'image'
    },
    prepare: ({ title = 'New Press', media }) => ({ title, media })
  }
}
