/**
 * Formats line items and products to
 * Storefront API CartLine schema:
 * {
 *   id
 *   title
 *   quantity
 *   attributes {
 *     key
 *     value
 *   }
 *   variant {
 *     id
 *     title
 *     sku
 *     price
 *     compareAtPrice
 *     availableForSale
 *     quantityAvailable
*      selectedOptions {
          name
          value
        }
 *     image {
 *       transformedSrc
 *     }
 *     product {
 *       id
 *       productType
 *     }
 *   }
 * }
 */

export default (obj = {}, type = 'lineItem') => {
  switch (type) {
    case 'product': {
      const lineItem = {
        __typename: 'BaseCartLineEdge',
        node: {
          __typename: 'CartLine',
          id: -1,
          title: obj.title,
          quantity: obj.quantity,
          attributes: obj.attributes.map(a => ({
            __typename: 'Attribute',
            ...a
          })),
          merchandise: {
            __typename: 'ProductVariant',
            id: obj.variant.id,
            title: obj.variant.title,
            sku: obj.variant.sku,
            price: null,
            compareAtPrice: null,
            availableForSale: obj.variant.availableForSale,
            quantityAvailable: obj.variant.quantityAvailable,
            selectedOptions: obj.variant.selectedOptions,
            image: obj.variant.image
              ? {
                  transformedSrc: obj.variant.image.transformedSrc,
                  __typename: 'Image'
                }
              : null,
            product: {
              __typename: 'Product',
              id: obj.id,
              title: obj.title,
              productType: obj.productType
            }
          }
        }
      }

      if (obj.variant.price?.amount) {
        lineItem.node.merchandise.price = {
          amount: obj.variant.price?.amount,
          __typename: 'MoneyV2'
        }
      }

      if (obj.variant.compareAtPrice?.amount) {
        lineItem.node.merchandise.compareAtPrice = {
          amount: obj.variant.compareAtPrice?.amount,
          __typename: 'MoneyV2'
        }
      }

      lineItem.node.sellingPlanAllocation = obj.sellingPlan
        ? {
            __typename: 'SellingPlanAllocation',
            sellingPlan: {
              __typename: 'SellingPlan',
              id: obj.sellingPlan.id,
              name: obj.sellingPlan.name,
              recurringDeliveries: true
            }
          }
        : null

      return lineItem
    }
    default:
      return {
        __typename: 'BaseCartLineEdge',
        node: {
          __typename: 'CartLine',
          id: obj.id,
          title: obj.title,
          quantity: obj.quantity,
          attributes: obj.attributes,
          merchandise: obj.merchandise
        }
      }
  }
}
