<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="name"
    tag="div"
    :rules="required ? 'required' : ''"
  >
    <div
      v-click-outside="collapse"
      :class="classes"
      class="dropdown input input--stacked z-2above"
      @mouseleave="collapse"
    >
      <input
        :id="`dropdown-${$_uid}-input`"
        type="hidden"
        :name="name"
        :value="value"
        :required="required"
      />
      <label
        v-if="label"
        :id="`dropdown-${$_uid}-label`"
        :for="`dropdown-${$_uid}-input`"
        class="dropdown__label"
        >{{ label }}</label
      >
      <div class="dropdown__ui">
        <button
          v-aria="buttonAria"
          :class="
            expanded
              ? 'dropdown__button'
              : 'dropdown__button dropdown__button--collapsed'
          "
          @click.prevent="toggle"
        >
          <span class="dropdown__selected">{{ buttonText }}</span>
          <fa icon="chevron-down" class="dropdown__icon" aria-hidden="true" />
        </button>
        <expand-height appear>
          <ul
            v-show="expanded"
            :id="`dropdown-${$_uid}-menu`"
            v-aria="menuAria"
            class="dropdown__menu"
          >
            <li
              v-for="(option, i) in unselected"
              :id="`dropdown-${$_uid}-option-${i}`"
              :key="`dropdown-${$_uid}-option-${i}`"
              v-aria="optionAria(option)"
              class="dropdown__item"
              @click="optionClick(option)"
            >
              {{ option.key }}
            </li>
          </ul>
        </expand-height>
      </div>
    </div>
    <fade appear>
      <ul
        v-if="errors && Object.values(errors).flat().length"
        class="absolute left-0 w-full"
      >
        <li
          v-for="(error, i) in Object.values(errors).flat()"
          :key="`error-${i}`"
          class="text-xs opacity-75 pt-1/4em"
        >
          {{ error }}
        </li>
      </ul>
    </fade>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'SelectDropdown',

  props: {
    label: {
      default: null,
      type: String
    },
    // array of key, value objects
    options: {
      default: () => [],
      type: Array,
      required: true
    },
    value: {
      default: null,
      type: String
    },
    placeholder: {
      default: null,
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    required: {
      default: false,
      type: Boolean
    },
    name: {
      default: undefined,
      type: String
    }
  },

  data() {
    return {
      expanded: false,
      buttonAria: {
        label: this.label,
        haspopup: true,
        controls: `dropdown-${this.$_uid}-menu`
      }
    }
  },

  computed: {
    classes() {
      return {
        'is-expanded': this.expanded,
        'is-disabled': this.disabled
      }
    },
    selectedOption() {
      const options = this.options
      const value = this.value
      let selected
      if (options.length > 0 && value) {
        selected = options.find(o => o.value === value)
      }
      return selected
    },
    unselected() {
      const options = this.options
      const selected = this.selectedOption
      if (selected) {
        return options.filter(o => o.value !== selected.value)
      } else {
        return options
      }
    },
    selectedText() {
      const selected = this.selectedOption
      let text
      if (selected) {
        text = selected.key
      }
      return text
    },
    buttonText() {
      const selectedText = this.selectedText
      const placeholder = this.placeholder
      return selectedText || placeholder || 'select'
    },
    menuAria() {
      const expanded = this.expanded
      const labelledBy = this.label ? `dropdown-${this.$_uid}-label` : null
      return {
        role: 'listbox',
        expanded,
        labelledBy
      }
    }
  },

  methods: {
    collapse() {
      if (this.expanded) {
        this.expanded = false
      }
    },
    toggle() {
      this.expanded = !this.expanded
    },
    optionAria(option) {
      return {
        role: 'option',
        selected: this.value && this.value === option.value
      }
    },
    optionClick(option) {
      this.$emit('input', option.value)
      this.expanded = false
    }
  }
}
</script>

<style lang="scss">
.dropdown {
  position: relative;
  z-index: 3;
}

.dropdown.is-expanded {
  z-index: 4;
}

.dropdown__ui {
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.dropdown__button,
.dropdown__item {
  line-height: theme('lineHeight.tight');
  padding: var(--input-gutter);
  position: relative;
}

.dropdown__button {
  align-items: center;
  border: var(--input-border);
  border-color: var(--input-text-color);
  border-radius: var(--input-border-radius);
  display: flex;
  flex-wrap: nowrap;
  height: var(--input-height);
  transition: color var(--hover-speed), background-color var(--hover-speed),
    border-radius var(--hover-speed) var(--hover-speed);
  width: 100%;
  z-index: 2;
}

.is-expanded .dropdown__button {
  background-color: var(--input-text-color);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: var(--input-background-color);
  transition: color var(--hover-speed), background-color var(--hover-speed),
    border-radius var(--hover-speed);
}

.dropdown__menu {
  position: absolute;
  width: 100%;
}

.dropdown__item {
  background-color: var(--input-text-color);
  color: var(--input-background-color);
  position: relative;
  transition: color var(--hover-speed), background-color var(--hover-speed);
}

.dropdown__item:last-child {
  border-bottom-left-radius: var(--input-border-radius);
  border-bottom-right-radius: var(--input-border-radius);
}

.dropdown__button:hover,
[data-js-focus-visible] .dropdown__button[data-focus-visible-added],
.dropdown__item:hover,
[data-js-focus-visible] .dropdown__item[data-focus-visible-added] {
  background-color: var(--input-background-color);
  color: var(--input-text-color);
}

.dropdown__button:focus,
.dropdown__item:focus {
  outline: 0;
}

.dropdown__selected {
  display: block;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown__icon {
  display: inline-block;
  flex: 0 0 auto;
  margin-left: var(--input-gutter);
  transition: transform var(--hover-speed);
}

.dropdown.is-expanded .dropdown__icon {
  transform: scaleY(-1);
}

@keyframes border-reveal {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

.dropdown__item::before {
  animation: border-reveal var(--hover-speed) linear alternate forwards;
  background-color: var(--input-background-color);
  content: '';
  display: block;
  height: 2px;
  left: var(--input-gutter);
  position: absolute;
  right: var(--input-gutter);
  top: 0;
  transform: scaleX(0);
  transform-origin: 50% 50%;
  transition: opacity var(--hover-speed);
}

.dropdown__item:hover::before,
[data-js-focus-visible] .dropdown__item[data-focus-visible-added]::before,
.dropdown__item:first-child::before {
  opacity: 0;
}

.is-expanded .dropdown__item:first-child::before {
  opacity: 1;
}

@for $i from 1 to (10) {
  .is-expanded .dropdown__item:nth-child(#{$i})::before {
    animation-delay: #{($i - 1) * 0.12}s;
  }
}

.dropdown__item:hover + .dropdown__item::before,
[data-js-focus-visible]
  .dropdown__item[data-focus-visible-added]
  + .dropdown__item::before,
.dropdown__button:hover + .dropdown__menu .dropdown__item:first-child::before,
[data-js-focus-visible]
  .dropdown__button[data-focus-visible-added]
  + .dropdown__menu
  .dropdown__item:first-child::before {
  opacity: 0;
}
</style>
