<template>
  <window-frame class="window-gallery">
    <gallery
      ref="gallery"
      :slides="slides"
      :active-slide.sync="activeSlide"
      :options="galleryOptions"
      class="window-gallery__gallery"
    >
      <template v-slot:top>
        <div class="window-gallery__tabs">
          <button
            v-for="(slide, i) in slides"
            :key="`slide-tab-${i}`"
            :class="i === activeSlide ? 'is-active' : ''"
            class="window-gallery__tab"
            @click.prevent="tabClick(i)"
          >
            {{ slide.caption || `Image ${i + 1}` }}
          </button>
        </div>
      </template>
      <slot>
        <div
          v-for="(slide, i) in slides"
          :key="`slide-${i}`"
          class="swiper-slide"
        >
          <sanity-media v-bind="slide" class="block" fluid contain swiper />
        </div>
      </slot>
    </gallery>
  </window-frame>
</template>

<script>
export default {
  name: 'WindowGallery',

  props: {
    slides: {
      default: () => [],
      type: Array
    }
  },

  data: () => ({
    activeSlide: 0,
    galleryOptions: {
      pagination: false,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      }
    }
  }),

  methods: {
    tabClick(index) {
      this.activeSlide = index
    }
  }
}
</script>

<style>
.window-gallery.window {
  box-shadow: -1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}

.window-gallery__tabs {
  background-color: theme('colors.gray');
  border-bottom: 1px solid theme('colors.gray');
  display: flex;
  flex-wrap: nowrap;
  text-align: left;
}

.window-gallery__tab {
  background-color: theme('colors.gray');
  border-left: 1px solid theme('colors.dark-gray');
  color: theme('colors.cloud-burst');
  flex: 1 1 auto;
  font-size: theme('fontSize.2xs');
  line-height: 1;
  margin-left: 1px;
  max-width: 50%;
  overflow: hidden;
  padding: 0.5em 1em;
  text-overflow: ellipsis;
  text-transform: lowercase;
  transition: background-color var(--hover-speed);
  white-space: nowrap;
}

.window-gallery__tab:first-child {
  border-left-color: transparent;
}

.window-gallery__tab.is-active {
  background-color: theme('colors.light-gray');
}

.window-gallery__gallery {
  padding-bottom: 0;
}

.window-gallery__gallery .gallery__wrapper {
  padding: 1em;
}

.window-gallery .swiper-slide {
  height: theme('height.win-h-1/2');
}

@screen sm {
  .window-gallery__tab {
    font-size: theme('fontSize.xs');
  }
}

@screen md {
  .window-gallery__tab {
    font-size: theme('fontSize.sm');
  }
}
</style>
