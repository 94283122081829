import Vue from 'vue'
import Badge from '~/components/badge.global.vue'
import Burger from '~/components/burger.global.vue'
import CircularCta from '~/components/circular-cta.global.vue'
import Cloud from '~/components/cloud.global.vue'
import ColorSection from '~/components/color-section.global.vue'
import Cta from '~/components/cta.global.vue'
import CursorOver from '~/components/cursor-over.global.vue'
import Drawer from '~/components/drawer.global.vue'
import Drawers from '~/components/drawers.global.vue'
import DriftingCloud from '~/components/drifting-cloud.global.vue'
import Flipper from '~/components/flipper.global.vue'
import Float from '~/components/float.global.vue'
import GalleryOverlay from '~/components/gallery-overlay.global.vue'
import Gallery from '~/components/gallery.global.vue'
import Gradient from '~/components/gradient.global.vue'
import HeroGallery from '~/components/hero-gallery.global.vue'
import Hero from '~/components/hero.global.vue'
import Hover from '~/components/hover.global.vue'
import Marquee from '~/components/marquee.global.vue'
import PageSection from '~/components/page-section.global.vue'
import Parallax from '~/components/parallax.global.vue'
import Pill from '~/components/pill.global.vue'
import RecessImage from '~/components/recess-image.global.vue'
import RecessLink from '~/components/recess-link.global.vue'
import RichText from '~/components/rich-text.global.vue'
import SanityBlocks from '~/components/sanity-blocks.global.vue'
import SanityMedia from '~/components/sanity-media.global.vue'
import Scroller from '~/components/scroller.global.vue'
import Sky from '~/components/sky.global.vue'
import Spinner from '~/components/spinner.global.vue'
import Testimonials from '~/components/testimonials.global.vue'
import Thumb from '~/components/thumb.global.vue'
import Window3d from '~/components/window-3d.global.vue'
import WindowFrame from '~/components/window-frame.global.vue'
import WindowGallery from '~/components/window-gallery.global.vue'
import ExpandHeight from '~/transitions/expand-height.global.vue'
import Fade from '~/transitions/fade.global.vue'
import SlideGroup from '~/transitions/slide-group.global.vue'
import Slide from '~/transitions/slide.global.vue'

Vue.component(Badge.name || 'Badge', Badge)

Vue.component(Burger.name || 'Burger', Burger)

Vue.component(CircularCta.name || 'CircularCta', CircularCta)

Vue.component(Cloud.name || 'Cloud', Cloud)

Vue.component(ColorSection.name || 'ColorSection', ColorSection)

Vue.component(Cta.name || 'Cta', Cta)

Vue.component(CursorOver.name || 'CursorOver', CursorOver)

Vue.component(Drawer.name || 'Drawer', Drawer)

Vue.component(Drawers.name || 'Drawers', Drawers)

Vue.component(DriftingCloud.name || 'DriftingCloud', DriftingCloud)

Vue.component(Flipper.name || 'Flipper', Flipper)

Vue.component(Float.name || 'Float', Float)

Vue.component(GalleryOverlay.name || 'GalleryOverlay', GalleryOverlay)

Vue.component(Gallery.name || 'Gallery', Gallery)

Vue.component(Gradient.name || 'Gradient', Gradient)

Vue.component(HeroGallery.name || 'HeroGallery', HeroGallery)

Vue.component(Hero.name || 'Hero', Hero)

Vue.component(Hover.name || 'Hover', Hover)

Vue.component(Marquee.name || 'Marquee', Marquee)

Vue.component(PageSection.name || 'PageSection', PageSection)

Vue.component(Parallax.name || 'Parallax', Parallax)

Vue.component(Pill.name || 'Pill', Pill)

Vue.component(RecessImage.name || 'RecessImage', RecessImage)

Vue.component(RecessLink.name || 'RecessLink', RecessLink)

Vue.component(RichText.name || 'RichText', RichText)

Vue.component(SanityBlocks.name || 'SanityBlocks', SanityBlocks)

Vue.component(SanityMedia.name || 'SanityMedia', SanityMedia)

Vue.component(Scroller.name || 'Scroller', Scroller)

Vue.component(Sky.name || 'Sky', Sky)

Vue.component(Spinner.name || 'Spinner', Spinner)

Vue.component(Testimonials.name || 'Testimonials', Testimonials)

Vue.component(Thumb.name || 'Thumb', Thumb)

Vue.component(Window3d.name || 'Window3d', Window3d)

Vue.component(WindowFrame.name || 'WindowFrame', WindowFrame)

Vue.component(WindowGallery.name || 'WindowGallery', WindowGallery)

Vue.component(ExpandHeight.name || 'ExpandHeight', ExpandHeight)

Vue.component(Fade.name || 'Fade', Fade)

Vue.component(SlideGroup.name || 'SlideGroup', SlideGroup)

Vue.component(Slide.name || 'Slide', Slide)
