import { MdPushPin } from 'react-icons/md'

export default {
  title: 'Discount Banner',
  icon: MdPushPin,
  name: 'discountBanner',
  type: 'document',
  __experimental_actions: ['update', 'publish'],
  fields: [
    {
      title: 'Background Color',
      name: 'backgroundColor',
      type: 'color'
    },
    {
      title: 'Text Color',
      name: 'textColor',
      type: 'color'
    },
    {
      title: 'Banner Text',
      name: 'text',
      type: 'string',
      description:
        'The "code" variable can be used to dynamically show the current discount code. (e.g. "Applied your code: {{ code }}").'
    }
  ],
  initialValue: {
    isActive: false,
    backgroundColor: {
      _type: 'color',
      alpha: 1,
      hex: '#000000',
      hsl: {
        _type: 'hslaColor',
        h: 0,
        s: 0,
        l: 0,
        a: 1
      },
      hsv: {
        _type: 'hsvaColor',
        h: 0,
        s: 0,
        v: 0,
        a: 1
      },
      rgb: {
        _type: 'rgbaColor',
        r: 0,
        g: 0,
        b: 0,
        a: 1
      }
    },
    textColor: {
      _type: 'color',
      hex: '#ffffff',
      alpha: 1,
      hsl: {
        _type: 'hslaColor',
        h: 0,
        s: 0,
        l: 1,
        a: 1
      },
      hsv: {
        _type: 'hsvaColor',
        h: 0,
        s: 0,
        v: 1,
        a: 1
      },
      rgb: {
        _type: 'rgbaColor',
        r: 255,
        g: 255,
        b: 255,
        a: 1
      }
    }
  }
}
