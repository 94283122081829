<template>
  <div
    :is="tag"
    :class="classes"
    :style="style"
    class="drawer scheme-light"
    @mouseover="mouseOver"
    @mouseleave="mouseLeave"
  >
    <wave-background
      v-if="needsWaveBox"
      :is-animating="open"
      :background-image="backgroundImage"
      :colors="colors"
    />
    <div v-else class="drawer__wave">
      <marquee
        class="pointer-events-auto drawer__marquee"
        vertical
        static
        fixed-speed
      >
        <wave :color="color" :reverse="right" vertical />
      </marquee>
    </div>
    <div class="drawer__container text-scheme">
      <slot name="container" />
      <div class="drawer__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import wait from 'waait'
import Wave from '~/components/wave'
import WaveBackground from '~/components/wave-background.vue'
import { colors } from '~/recess.json'

const headerSelector = '#site-header'
const defaultColor = colors.melrose

export default {
  name: 'Drawer',

  components: {
    Wave,
    WaveBackground
  },

  props: {
    tag: {
      default: 'div',
      type: String
    },
    open: {
      default: false,
      type: Boolean
    },
    color: {
      default: defaultColor,
      type: String
    },
    colors: {
      default: undefined,
      type: Array
    },
    backgroundImage: {
      default: undefined,
      type: String
    },
    scrimId: {
      default: null,
      type: String
    },
    right: {
      default: false,
      type: Boolean
    },
    autoclose: {
      default: true,
      type: Boolean
    }
  },

  data: () => ({
    ready: false,
    siteHeader: null,
    scrim: null,
    mouseLeaveTarget: null,
    mouseLeaveListener: null
  }),

  computed: {
    ...mapState({
      globalBannerHeight: state => state.screen.globalBannerHeight
    }),
    classes() {
      return {
        'is-open': this.open,
        'is-right': this.right,
        'drawer--solid-color': !this.needsWaveBox
      }
    },
    style() {
      return {
        '--drawer-color': this.color || defaultColor,
        '---gradient': this.gradient,
        '--global-banner-height': this.globalBannerHeight + 'px'
      }
    },
    needsWaveBox() {
      return !!this.backgroundImage || !!this.colors
    }
  },

  watch: {
    /**
     * close any drawer when the route changes
     */
    '$route.path'() {
      this.closeDrawers()
    },
    scrimId(id) {
      if (id) {
        this.scrim = document.querySelector(`#${id}`)
      }
    }
  },

  mounted() {
    this.$nextTick(this.setup)
    this.$bus.$on('escKeydown', this.closeDrawer)
  },

  beforeDestroy() {
    this.removeMouseLeaveListener()
    this.$bus.$off('escKeydown', this.closeDrawer)
  },

  methods: {
    async setup() {
      const scrimId = this.scrimId
      this.siteHeader = document.querySelector(headerSelector)
      if (scrimId) {
        this.scrim = document.querySelector(`#${this.scrimId}`)
      }
      // wait for drawer to open before sending events
      // TODO: figure out how events in functional transitions
      // to tie this to transition enter/leave events
      await wait(1500)
      this.ready = true
    },
    openDrawer() {
      if (this.ready && !this.open) {
        this.$emit('update:open', true)
      }
    },
    closeDrawer() {
      if (this.ready && this.open) {
        this.$emit('update:open', false)
      }
    },
    closeDrawers() {
      if (this.ready) {
        this.$emit('close-drawers')
      }
    },
    setMouseLeaveListener(el) {
      this.mouseLeaveTarget = el
      this.mouseLeaveListener = el.addEventListener(
        'mouseleave',
        this.targetMouseLeave,
        { once: true }
      )
    },
    removeMouseLeaveListener() {
      const target = this.mouseLeaveTarget
      if (target) {
        target.removeEventListener('mouseleave', this.targetMouseLeave)
        this.mouseLeaveTarget = null
        this.mouseLeaveListener = null
      }
    },
    targetMouseLeave(e) {
      const target = e.relatedTarget
      if (this.$el.contains(target)) {
        this.removeMouseLeaveListener()
      } else {
        this.closeDrawer()
      }
    },
    mouseOver() {
      this.openDrawer()
    },
    mouseLeave(e) {
      const target = e.relatedTarget
      const header = this.siteHeader
      const scrim = this.scrim
      // eslint-disable-next-line no-empty
      if (!this.autoclose) {
      } else if (scrim && scrim.contains(target)) {
        this.closeDrawers()
      } else if (header.contains(target)) {
        this.setMouseLeaveListener(header)
      } else {
        this.closeDrawer()
      }
    }
  }
}
</script>

<style>
.drawer {
  --drawer-gutter: var(--x-gutter);
  --drawer-wave-size: var(--x-gutter-lg);
  backface-visibility: hidden;
  flex: 0 0 auto;
  height: auto;
  min-height: 100%;
  padding-top: var(--global-banner-height);
  position: relative;
  transform: translate3d(-100%, 0, 0);
  transition: transform var(--drawer-speed), background-color var(--hover-speed);
  z-index: 3;
}

.drawer--solid-color {
  background-color: var(--drawer-color, theme('colors.melrose'));
}

.drawer.is-right {
  transform: translate3d(100%, 0, 0);
}

.drawer.is-open {
  transform: translate3d(0, 0, 0);
}

.drawer.scheme-light .text-scheme {
  --link-color: theme('colors.ultramarine');
}

.drawer__wave {
  height: 100%;
  left: 99%; /* 1% fixes jagged edge in transitions */
  position: absolute;
  top: 0;
  width: var(--drawer-wave-size);
  z-index: 3;
}

.drawer__wave path {
  fill: var(--drawer-color, theme('colors.melrose')) !important;
  transition: fill var(--hover-speed);
}

.drawer__marquee {
  height: 100%;
}

.drawer.is-right .drawer__wave {
  left: auto;
  right: 99%; /* 1% fixes jagged edge in transitions */
}

.drawer__container {
  padding-bottom: var(--y-gutter-sm);
  padding-top: var(--header-height);
  position: relative;
}

.drawer__content {
  padding-left: var(--drawer-gutter);
  padding-right: var(--drawer-wave-size);
  padding-top: var(--header-gutter-bottom);
}

.drawer.is-right .drawer__content {
  padding-left: var(--drawer-wave-size);
  padding-right: var(--drawer-gutter);
}

.drawer ~ .drawer {
  flex: 0 1 auto;
  z-index: 2;
}

.is-right .drawer ~ .drawer .drawer__content {
  padding-left: var(--drawer-gutter);
  padding-right: calc(var(--drawer-gutter) + var(--drawer-wave-size));
}

@screen md {
  .drawer {
    --drawer-gutter: var(--x-gutter);
    --drawer-wave-size: var(--x-gutter);
  }
}

@screen lg {
  .drawer__container {
    scrollbar-width: none;
  }

  .drawer__container::-webkit-scrollbar {
    display: none;
  }
}

@screen xl {
  .drawer {
    --drawer-wave-size: var(--x-gutter-sm);
  }
}
</style>
