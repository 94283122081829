import { TiSocialAtCircular } from 'react-icons/ti'

export default {
  title: 'Social Media',
  name: 'socialMedia',
  type: 'document',
  icon: TiSocialAtCircular,
  __experimental_actions: ['update', 'publish'],
  fields: [
    {
      title: 'Social Links',
      name: 'links',
      type: 'array',
      of: [
        {
          title: 'Social Link',
          name: 'link',
          type: 'object',
          fields: [
            {
              title: 'Service Name',
              name: 'name',
              type: 'string',
              validation: Rule => Rule.required().error('A name is required.')
            },
            {
              title: 'URL',
              name: 'url',
              type: 'url',
              validation: Rule => Rule.required()
            }
          ]
        }
      ]
    }
  ]
}
