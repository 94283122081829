<template functional>
  <rich-text>
    <slot />
  </rich-text>
</template>

<script>
export default {
  name: 'SanityContainer',

  functional: true
}
</script>
