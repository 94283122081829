import groq from 'groq'
import imageField from './image'
import videoField from './video'

export default groq`{
  image{
    ...${imageField}
  },
  ${videoField}
}`
