import { useClient as useSanityClient } from 'sanity'

export default function useClient() {
  let apiVersion

  // Sanity is being used within Nuxt
  if (window?.$nuxt?.$config) {
    apiVersion = window.$nuxt.$config.sanityEnv.apiVersion
    // Sanity is being used on its own
  } else {
    apiVersion = process.env.apiVersion
  }

  return useSanityClient({
    apiVersion: apiVersion
  })
}
