<template>
  <span class="scrolling-banner__text-wrapper">
    <span v-for="(content, index) in contents" :key="index">
      <recess-link
        v-if="content.link"
        class="scrolling-banner__text scrolling-banner-text__link"
        :sanity-link="banner"
        no-hover-color
      >
        {{ content.text }}
      </recess-link>
      <span v-if="!content.link" class="scrolling-banner__text">
        {{ content.text }}
      </span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'ScrollingBannerText',

  props: {
    banner: {
      type: Object,
      required: true
    }
  },

  computed: {
    contents() {
      const regex = /(\{\{.*\}\})/g

      if (!this.banner.title) {
        return []
      }

      if (!this.banner.url && !this.banner.page) {
        return [{ text: this.banner.title, link: false }]
      }

      const contents = this.banner.title.split(regex)

      if (contents.length === 1) {
        return [{ text: contents[0], link: true }]
      }

      return this.formatContents(contents, regex)
    }
  },

  methods: {
    formatContents(contents, regex) {
      return contents.map(content => {
        if (content.match(regex)) {
          return {
            text: content.replace('{{', '').replace('}}', ''),
            link: true
          }
        } else {
          return { text: content, link: false }
        }
      })
    }
  }
}
</script>

<style>
.scrolling-banner__text-wrapper {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.scrolling-banner-text__link {
  text-decoration: underline;
}

.scrolling-banner__text {
  font-size: 15px;
  font-weight: normal;
}
</style>
