<template>
  <button
    :is="tag"
    v-bind="$attrs"
    :class="classes"
    :style="styles"
    :disabled="disabled"
    class="cta"
    @click="$emit('click', $event)"
  >
    <span class="cta__shadow" />
    <span class="cta__content">
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  name: 'Cta',

  props: {
    tag: {
      default: 'button',
      type: String
    },
    backgroundColor: {
      default: undefined,
      type: String
    },
    textColor: {
      default: undefined,
      type: String
    },
    borderColor: {
      default: undefined,
      type: String
    },
    shadowColor: {
      default: undefined,
      type: String
    },
    active: {
      default: false,
      type: Boolean
    },
    small: {
      default: false,
      type: Boolean
    },
    large: {
      default: false,
      type: Boolean
    },
    fill: {
      default: false,
      type: Boolean
    },
    frame: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },

  computed: {
    classes() {
      return {
        'cta--active': this.active,
        'cta--small': this.small,
        'cta--large': this.large,
        'cta--fill': this.fill,
        'cta--frame': this.frame
      }
    },
    styles() {
      const backgroundColor = this.backgroundColor
      const textColor = this.textColor
      const borderColor = this.borderColor
      const shadowColor = this.shadowColor
      const style = {}
      if (backgroundColor) {
        style['--cta-background'] = backgroundColor
      }
      if (textColor) {
        style['--cta-text-color'] = textColor
      }
      if (shadowColor) {
        style['--shadow-background'] = shadowColor
      }
      if (borderColor) {
        style['--cta-border-color'] = borderColor
      }
      return style
    }
  }
}
</script>

<style>
.cta {
  --cta-background: var(--background-color);
  --cta-text-color: var(--text-color);
  --cta-border-color: currentColor;
  --cta-border: 2px solid var(--cta-border-color);
  --cta-gutter: 1.5em 2.25em;
  --shadow-background: var(--background-color);
  --shadow-gutter: 0.25em;
  --shadow-offset: -0.25em;
  --shadow-translate: translate(var(--shadow-gutter), var(--shadow-offset));
  align-items: stretch;
  display: inline-grid;
  font-weight: theme('fontWeight.bold');
  grid-template-columns: minmax(0, 100%);
  line-height: 1;
  margin-right: var(--shadow-gutter);
  margin-top: var(--shadow-gutter);
  outline: 0;
  text-align: center;
  text-transform: uppercase;
  transition: opacity var(--fade-speed);
  user-select: none;
}

.cta > * {
  grid-column: 1;
  grid-row: 1;
}

.cta.absolute {
  position: absolute;
}

.cta.fixed {
  position: fixed;
}

.cta.block {
  display: grid;
}

.cta.normal-case {
  text-transform: none;
}

.cta.lowercase {
  text-transform: lowercase;
}

a.cta,
button.cta {
  --link-color: var(--cta-text-color);
  cursor: pointer;
}

.cta:disabled {
  cursor: default;
  opacity: 0.75;
}

.cta--fill {
  --shadow-background: var(--cta-text-color);
}

.cta--small {
  --cta-gutter: 0.75em 1.25em;
  --shadow-gutter: 0.25em;
  --shadow-offset: -0.25em;
  font-size: 0.9em;
}

.cta--large {
  --cta-gutter: 1.5em 2.25em;
  --shadow-gutter: 0.5em;
  --shadow-offset: -0.5em;
}

.cta--frame {
  --cta-gutter: 0;
  --shadow-gutter: 0.5em;
  --shadow-offset: -0.5em;
  display: grid;
}

.cta--frame.cta--small {
  --shadow-gutter: 0.25em;
  --shadow-offset: -0.25em;
}

.cta--frame img {
  object-fit: cover !important;
  object-position: center;
}

.cta__shadow,
.cta__content {
  border: var(--cta-border);
  display: block;
  width: 100%;
}

.cta__shadow {
  background-color: var(--shadow-background);
  position: relative;
}

.cta__content {
  background-color: var(--cta-background);
  color: var(--cta-text-color);
  padding: var(--cta-gutter);
  position: relative;
  transform: var(--shadow-translate);
  transition: transform 0.15s;
}

a.cta:hover:not(:disabled),
button.cta:hover:not(:disabled),
[data-js-focus-visible] a.cta[data-focus-visible-added],
[data-js-focus-visible] button.cta[data-focus-visible-added] {
  --shadow-translate: translate(
    calc(var(--shadow-gutter) * 1.5),
    calc(var(--shadow-offset) * 1.5)
  );
}

.cta--active:not(:disabled) .cta__content,
a.cta:active:not(:disabled) .cta__content,
button.cta:active:not(:disabled) .cta__content {
  transform: translate(0, 0);
}

@screen xs {
  .cta {
    --cta-gutter: 1.25em 2.5em;
  }

  .cta--frame {
    --cta-gutter: 0;
  }

  .cta--large {
    --cta-gutter: 1.5em 2.5em;
  }
}

@screen md {
  .cta {
    --cta-gutter: 1.5em 2.5em;
  }

  .cta--frame {
    --cta-gutter: 0;
    --shadow-gutter: 0.75em;
    --shadow-offset: -0.75em;
  }

  .cta--small {
    --cta-gutter: 0.75em 1.25em;
    --shadow-gutter: 0.25em;
    --shadow-offset: -0.25em;
    font-size: 0.8em;
  }

  .cta--large {
    --cta-gutter: 2em 3.25em;
  }

  /* takes precedence over size modifiers */
  .cta.cta--frame {
    --cta-gutter: 0;
  }
}

@screen lg {
  .cta--small {
    --cta-gutter: 1em 1.5em;
  }

  /* takes precedence over size modifiers */
  .cta.cta--frame {
    --cta-gutter: 0;
  }
}
</style>
