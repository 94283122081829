export default id => {
  try {
    if (id && !id.includes('gid://')) {
      return atob(id)
    }

    return id
  } catch (error) {
    return id
  }
}
