<template>
  <button class="magnifier" :class="classProp" v-on="$listeners">
    <svg width="50" height="50" viewBox="0 0 50 50" class="magnifier__svg">
      <g
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="2"
      >
        <circle cx="18.4" cy="17.8" r="16.4" />
        <path d="M30 30.6l18 18M18.4 24V11.7M12.2 17.8h12.4" />
      </g>
    </svg>
  </button>
</template>

<script>
export default {
  name: 'Magnifier',

  props: {
    classProp: {
      type: String,
      required: false,
      default: undefined
    }
  }
}
</script>

<style>
.magnifier {
  align-items: center;
  display: flex;
  justify-content: center;
}

.magnifier__svg {
  display: block;
  height: auto;
  max-width: 100%;
  width: auto;
}
</style>
