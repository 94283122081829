var render = function render(_c,_vm){return _c('div',_vm._g(_vm._b({ref:_vm.data.ref,staticClass:"window-3d",class:[
    _vm.data.class,
    _vm.data.staticClass,
    {
      'window-3d--small': _vm.props.small,
      'window-3d--skew window-3d--skew-left': _vm.props.skewLeft,
      'window-3d--skew window-3d--skew-right': _vm.props.skewRight,
      'window-3d--skew window-3d--skew-down': _vm.props.skewDown,
      'window-3d--skew window-3d--skew-up': _vm.props.skewUp,
      'window-3d--animate-left': _vm.props.animateLeft,
      'window-3d--animate-right': _vm.props.animateRight
    }
  ],style:([_vm.data.style, _vm.data.staticStyle])},'div',_vm.data.attrs,false),_vm.listeners),[_c('div',{staticClass:"window-3d__rect"},[_c('window-frame',{staticClass:"window-3d__side window-3d__side--front"},[_vm._t("default")],2),_vm._v(" "),_c('div',{staticClass:"window-3d__side window-3d__side--right"}),_vm._v(" "),_c('div',{staticClass:"window-3d__side window-3d__side--left"}),_vm._v(" "),_c('div',{staticClass:"window-3d__side window-3d__side--top"}),_vm._v(" "),_c('div',{staticClass:"window-3d__side window-3d__side--bottom"}),_vm._v(" "),_c('div',{staticClass:"window-3d__side window-3d__side--back"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }