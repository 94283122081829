<template>
  <button
    :is="tag"
    v-bind="$attrs"
    :class="classes"
    class="circular-cta"
    @click="$emit('click', $event)"
  >
    <span class="circular-cta__shadow" />
    <span class="circular-cta__content">
      <span class="circular-cta__text"><slot /></span>
    </span>
  </button>
</template>

<script>
export default {
  name: 'CircularCta',

  props: {
    tag: {
      default: 'button',
      type: String
    },
    small: {
      default: false,
      type: Boolean
    },
    pill: {
      default: false,
      type: Boolean
    }
  },

  computed: {
    classes() {
      return {
        'circular-cta--small': this.small,
        'circular-cta--pill': this.pill
      }
    }
  }
}
</script>

<style>
.circular-cta {
  @apply text-xs leading-none;
  --cta-size: var(--input-height);
  --cta-text-color: var(--input-text-color);
  --cta-background: var(--input-background-color);
  --shadow-background: var(--cta-text-color);
  --shadow-gutter: 0.25em;
  --shadow-offset: -0.25em;
  --shadow-translate: translate(var(--shadow-gutter), var(--shadow-offset));
  color: var(--cta-text-color);
  cursor: pointer;
  position: relative;
  text-align: center;
}

.circular-cta:focus {
  outline: 0;
}

.circular-cta svg {
  @apply text-xl;
}

.circular-cta--small {
  --cta-size: 2em;
}

.circular-cta--pill {
  font-size: inherit;
}

.circular-cta__shadow,
.circular-cta__content {
  border: var(--input-border);
  border-radius: 100%;
  box-sizing: content-box;
  height: var(--cta-size);
  padding: calc(var(--input-gutter) / 2);
  width: var(--cta-size);
}

.circular-cta--pill .circular-cta__shadow,
.circular-cta--pill .circular-cta__content {
  border-radius: var(--cta-size);
  box-sizing: border-box;
  padding: 0 calc(var(--input-gutter) * 2);
}

.circular-cta--pill .circular-cta__shadow {
  width: 100%;
}

.circular-cta--pill .circular-cta__content {
  width: auto;
}

.circular-cta__shadow {
  background-color: var(--shadow-background);
  display: block;
  left: 0;
  position: absolute;
  top: 0;
}

.circular-cta__content {
  align-items: center;
  background-color: var(--cta-background);
  border: var(--input-border);
  display: flex;
  justify-content: center;
  position: relative;
  transform: var(--shadow-translate);
  transition: transform var(--hover-speed);
}

.circular-cta__text {
  position: relative;
  z-index: 2;
}

.circular-cta__content::before {
  background-color: theme('colors.white');
  border-radius: 100%;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity var(--hover-speed);
  width: 100%;
}

.circular-cta--pill .circular-cta__content::before {
  border-radius: var(--cta-size);
}

.circular-cta:hover,
[data-js-focus-visible] .circular-cta[data-focus-visible-added] {
  --shadow-translate: translate(
    calc(var(--shadow-gutter) * 1.5),
    calc(var(--shadow-offset) * 1.5)
  );
}

.circular-cta:active .circular-cta__content,
.circular-cta[aria-checked='true'] .circular-cta__content {
  transform: translate(0, 0);
}

.circular-cta:active .circular-cta__content::before,
.circular-cta[aria-checked='true'] .circular-cta__content::before {
  opacity: 0.5;
}

@screen md {
  .circular-cta--small {
    --cta-size: 3em;
  }
}
</style>
