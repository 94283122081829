import { TiHomeOutline } from 'react-icons/ti'
import homeDoc from './home'

export default {
  ...homeDoc,
  title: 'Sampler Home',
  name: 'homeSampler',
  icon: TiHomeOutline,
  fields: [
    {
      title: 'Splash Title Image',
      name: 'splashTitleImage',
      type: 'image',
      fieldset: 'splash',
      description:
        'Should be a properly treated SVG or transparent PNG with no whitespace'
    },
    ...homeDoc.fields
  ]
}
