<template functional>
  <div
    :ref="data.ref"
    :class="[
      data.class,
      data.staticClass,
      {
        'window-3d--small': props.small,
        'window-3d--skew window-3d--skew-left': props.skewLeft,
        'window-3d--skew window-3d--skew-right': props.skewRight,
        'window-3d--skew window-3d--skew-down': props.skewDown,
        'window-3d--skew window-3d--skew-up': props.skewUp,
        'window-3d--animate-left': props.animateLeft,
        'window-3d--animate-right': props.animateRight
      }
    ]"
    :style="[data.style, data.staticStyle]"
    class="window-3d"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <div class="window-3d__rect">
      <window-frame class="window-3d__side window-3d__side--front">
        <slot />
      </window-frame>
      <div class="window-3d__side window-3d__side--right" />
      <div class="window-3d__side window-3d__side--left" />
      <div class="window-3d__side window-3d__side--top" />
      <div class="window-3d__side window-3d__side--bottom" />
      <div class="window-3d__side window-3d__side--back" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Window3d',

  functional: true,

  props: {
    small: {
      default: false,
      type: Boolean
    },
    skewLeft: {
      default: false,
      type: Boolean
    },
    skewRight: {
      default: false,
      type: Boolean
    },
    skewDown: {
      default: false,
      type: Boolean
    },
    skewUp: {
      default: false,
      type: Boolean
    },
    animateLeft: {
      default: false,
      type: Boolean
    },
    animateRight: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style>
.window-3d,
.window-3d__rect,
.window-3d__side {
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.window-3d {
  --window-3d-distance-min: 0.75; /* min scale in animation */
  --window-3d-distance-max: 0.9; /* max scale in animation */
  --window-3d-height: theme('height.win-h-1/3');
  --window-3d-width: calc(var(--window-3d-height) * 0.66);
  --window-3d-depth: 2em;
  --window-3d-skew-x: rotateX(20deg);
  --window-3d-skew-x-end: rotateX(10deg);
  --window-3d-skew-y: rotateY(-30deg);
  --window-3d-skew-y-end: rotateY(-10deg);
  height: var(--window-3d-height);
  max-width: 100%;
  width: var(--window-3d-width);
}

.window-3d--small {
  --window-3d-height: 50vmax;
}

@keyframes animate-left {
  from {
    transform: rotateY(45deg)
      scale3d(
        var(--window-3d-distance-min),
        var(--window-3d-distance-min),
        var(--window-3d-distance-min)
      );
  }

  to {
    transform: rotateY(-45deg)
      scale3d(
        var(--window-3d-distance-max),
        var(--window-3d-distance-max),
        var(--window-3d-distance-max)
      );
  }
}

@keyframes animate-right {
  from {
    transform: rotateY(-45deg)
      scale3d(
        var(--window-3d-distance-min),
        var(--window-3d-distance-min),
        var(--window-3d-distance-min)
      );
  }

  to {
    transform: rotateY(45deg)
      scale3d(
        var(--window-3d-distance-max),
        var(--window-3d-distance-max),
        var(--window-3d-distance-max)
      );
  }
}

.window-3d--animate-left {
  animation: animate-left 8s ease-in-out alternate infinite;
}

.window-3d--animate-right {
  animation: animate-right 6s ease-in-out alternate-reverse infinite;
}

@keyframes skew {
  from {
    transform: var(--window-3d-skew-x) var(--window-3d-skew-y);
  }

  to {
    transform: var(--window-3d-skew-x-end) var(--window-3d-skew-y-end);
  }
}

.window-3d--skew,
.has-window .window-3d {
  animation: skew 6s ease-in-out alternate-reverse infinite;
}

.window-3d--skew-right,
.has-window.is-left .window-3d {
  --window-3d-skew-x: rotateX(-10deg);
  --window-3d-skew-x-end: rotateX(10deg);
  --window-3d-skew-y: rotateY(10deg);
  --window-3d-skew-y-end: rotateY(30deg);
}

.window-3d--skew-left,
.has-window.is-right .window-3d {
  --window-3d-skew-x: rotateX(10deg);
  --window-3d-skew-x-end: rotateX(-10deg);
  --window-3d-skew-y: rotateY(-10deg);
  --window-3d-skew-y-end: rotateY(-30deg);
}

.window-3d--skew-bottom {
  --window-3d-skew-x: rotateX(20deg);
  --window-3d-skew-x-end: rotateX(10deg);
  --window-3d-skew-y: rotateY(-10deg);
  --window-3d-skew-y-end: rotateY(-30deg);
}

.window-3d--skew-top {
  --window-3d-skew-x: rotateX(-30deg);
  --window-3d-skew-x-end: rotateX(-10deg);
  --window-3d-skew-y: rotateY(10deg);
  --window-3d-skew-y-end: rotateY(40deg);
}

.window-3d__side {
  background-color: theme('colors.dark-gray');
  position: absolute;
}

.window-3d__side--front,
.window-3d__side--back {
  height: var(--window-3d-height);
  width: var(--window-3d-width);
}

.window-3d__side--front {
  transform: translate3d(0, 0, calc(var(--window-3d-depth) / 2));
}

.window-3d__side--back {
  transform: rotate3d(0, 1, 0, -180deg)
    translate3d(0, 0, calc(var(--window-3d-depth) / 2));
}

.window-3d__side--left,
.window-3d__side--right {
  left: calc(-1 * var(--window-3d-depth) / 2);
  width: var(--window-3d-depth);
}

.window-3d__side--right {
  height: var(--window-3d-height);
  top: 0;
  transform: rotate3d(0, 1, 0, 90deg) translate3d(0, 0, var(--window-3d-width));
}

.window-3d__side--left {
  height: var(--window-3d-height);
  transform: rotate3d(0, 1, 0, -90deg);
}

.window-3d__side--top,
.window-3d__side--bottom {
  height: var(--window-3d-depth);
  top: calc(-1 * var(--window-3d-depth) / 2);
  width: var(--window-3d-width);
}

.window-3d__side--top {
  transform: rotate3d(1, 0, 0, 90deg);
}

.window-3d__side--bottom {
  transform: rotate3d(1, 0, 0, -90deg)
    translate3d(0, 0, var(--window-3d-height));
}

@screen md {
  .window-3d {
    --window-3d-height: theme('height.win-h-1/2');
    --window-3d-distance-max: 0.75;
    --window-3d-depth: 3em;
  }

  .window-3d--small {
    --window-3d-height: theme('height.win-h-1/3');
    --window-3d-depth: 4em;
  }
}

@screen lg {
  .window-3d {
    --window-3d-distance-min: 0.5;
    --window-3d-distance-max: 0.85;
    --window-3d-depth: 4em;
  }

  .window-3d--small {
    --window-3d-distance-max: 0.75;
    --window-3d-depth: 3em;
  }
}
</style>
