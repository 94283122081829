<template>
  <transition name="slide-left" appear>
    <drawers
      v-if="bp && drawersAreOpen"
      :class="{ 'is-open': drawersAreOpen, 'shop-menu--touch': touch }"
      class="shop-menu"
      data-display="flex"
      @close="closeMenu"
    >
      <template slot-scope="{ scrimId }">
        <drawer
          :scrim-id="scrimId"
          :open="isOpen"
          class="menu menu--parent"
          @update:open="drawerChange"
          @close-drawers="closeMenu"
        >
          <shop-menu-section
            v-for="section in sectionsVisible"
            :key="section._key"
            :scrim-id="scrimId"
            :section="section"
          />
        </drawer>
        <portal-target
          :class="[
            'flex',
            'submenu-portal',
            touch && 'submenu-portal--touch',
            submenuIsOpen && 'submenu-portal--opened'
          ]"
          name="shopMenuDrawers"
          multiple
        />
      </template>
    </drawers>
  </transition>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import ShopMenuSection from './section'

export default {
  name: 'ShopMenu',

  components: {
    ShopMenuSection
  },

  data: () => ({
    transitioning: false
  }),

  computed: {
    ...mapState({
      overlay: state => state.screen.overlay,
      shopMenu: state => state.shop.shopMenu,
      loginLink: state => state.loginLink,
      touch: state => state.screen.touch,
      submenuIsOpen: state => !!state.screen.shopMenuSubmenuOpenedKey
    }),

    drawersAreOpen() {
      return this.overlay === 'site-menu'
    },

    isOpen() {
      return this.drawersAreOpen && (this.touch ? !this.submenuIsOpen : true)
    },

    ...mapGetters('screen', {
      bp: 'breakpoint'
    }),

    sectionsVisible() {
      const mobile = this.$device.isMobileOrTablet
      return this.shopMenu.shopMenuSections.filter(section => {
        return mobile ? section : !section.mobileOnly
      })
    }
  },

  methods: {
    ...mapMutations({
      setOverlay: 'screen/setOverlay',
      setSubmenuOpenedKey: 'screen/setShopMenuSubmenuOpenedKey'
    }),

    drawerChange(open) {
      if (!open && !this.submenuIsOpen) {
        this.closeMenu()
      }
    },

    closeMenu() {
      if (this.isOpen || this.submenuIsOpen) {
        this.setOverlay()
      }
    },

    closeSubmenu() {
      this.setSubmenuOpenedKey()
    }
  }
}
</script>

<style scoped>
.shop-menu {
  --menu-gutter: 0.5rem;
  --menu-size: theme('fontSize.sm');
  content-visibility: hidden;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  user-select: none;
  width: var(--win-width);
  z-index: theme('zIndex.drawer');
}

.shop-menu.is-open {
  content-visibility: visible;
}

.submenu-portal {
  display: grid;
  pointer-events: none;
  z-index: 2;
}

.submenu-portal--opened {
  pointer-events: auto;
}

.submenu-portal--touch {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}

.menu {
  /* this menu needs to at least extend to the "shop" in the navbar */
  min-width: 400px;
}

.menu > .drawer__container {
  background: green;
}

.shop-menu--touch .menu {
  min-width: 45%;
}

.menu__closer--not-touch {
  display: none;
}

@screen sm {
  .shop-menu {
    --menu-size: theme('fontSize.lg');
  }
}

@screen md {
  .shop-menu {
    --menu-size: theme('fontSize.3xl');
  }
}
</style>

<style>
/* these are all quickfixes for drawers not seeming to support overflow well */
.shop-menu .drawer {
  max-height: 100%;
}

.shop-menu .drawer__container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.shop-menu .drawer__content {
  flex-grow: 1;
}
</style>
