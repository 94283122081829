import { MdAddShoppingCart } from 'react-icons/md'
import inlineEditor from './blocks/inline-editor'

export default {
  name: 'cart',
  title: 'Cart',
  type: 'document',
  icon: MdAddShoppingCart,
  __experimental_actions: ['update', 'publish'],
  fields: [
    {
      type: 'text',
      name: 'giftNoteTextPlaceholder',
      title: 'Gift Note Text Placeholder',
      description: 'the prefilled sample gift note text',
      validation: Rule => Rule.required().max(120)
    },
    {
      ...inlineEditor,
      name: 'giftingNotice',
      title: 'Gifting Notice',
      description: 'explains how gifting works',
      validation: Rule => Rule.required()
    },
    {
      ...inlineEditor,
      name: 'cbdWarning',
      title: 'CBD Warning',
      description:
        'a warning displayed in the cart for products ' +
        'that contain hemp extract',
      validation: Rule => Rule.required()
    },
    {
      ...inlineEditor,
      name: 'shippingNotePowderProducts',
      title: 'Shipping Note for Powder Products',
      description:
        'a note that accompanies "powder" products in the cart. ' +
        'displayed only when the cart also includes "drink" products',
      validation: Rule => Rule.required()
    }
  ]
}
