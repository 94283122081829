<template>
  <div
    v-observe-visibility="
      !notLazy && {
        once: true,
        callback: visibilityChange,
        intersection: {
          rootMargin: '25%'
        }
      }
    "
    :style="style"
    :class="classes"
    class="image"
  >
    <img
      ref="lazy"
      :src="notLazy ? src : placeholder"
      :data-src="src"
      :width="width"
      :height="height"
      :alt="alt ? alt : ''"
      :class="imageClass"
      class="image__img"
    />
    <spinner v-if="spinner && !loaded" class="image__spinner" />
  </div>
</template>

<script>
import imagesLoaded from 'imagesloaded'

// transparent 1x1 png
const placeholder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

export default {
  name: 'RecessImage',

  props: {
    src: {
      default: undefined,
      type: String
    },
    alt: {
      default: null,
      type: String
    },
    width: {
      default: null,
      type: [Number, String]
    },
    height: {
      default: null,
      type: [Number, String]
    },
    spinner: {
      default: false,
      type: Boolean
    },
    fluid: {
      default: false,
      type: Boolean
    },
    boxed: {
      default: false,
      type: Boolean
    },
    cover: {
      default: false,
      type: Boolean
    },
    contain: {
      default: false,
      type: Boolean
    },
    noRatio: {
      default: false,
      type: Boolean
    },
    ratio: {
      type: Number,
      required: false,
      default: undefined
    },
    notLazy: {
      default: false,
      type: Boolean
    },
    imageClass: {
      default: '',
      type: String
    }
  },

  data: ({ notLazy }) => ({
    loaded: notLazy,
    visible: false,
    placeholder
  }),

  computed: {
    style() {
      const ratio = this.ratio
      const style = {}
      if (!ratio) {
        return style
      }
      style.paddingTop = `${ratio}%`
      return style
    },
    ratioActual() {
      return this.ratio ?? this.ratioDefault
    },
    ratioDefault() {
      if (this.noRatio || this.cover || this.fluid || this.boxed) {
        return 0
      }
      const width = this.width
      const height = this.height
      if (width && height) {
        return (height / width) * 100
      } else {
        return 0
      }
    },
    classes() {
      return {
        'has-ratio':
          !this.noRatio && !this.fluid && !!this.ratio && !this.boxed,
        'is-fluid': this.fluid,
        'is-boxed': this.boxed,
        'is-cover': this.cover,
        'is-contain': this.contain,
        'is-lazy': !this.notLazy,
        'is-not-lazy': this.notLazy,
        'is-not-loaded': !this.notLazy && !this.loaded,
        'is-loaded': this.loaded,
        'is-visible': this.visible
      }
    }
  },

  mounted() {
    if (this.notLazy) {
      this.$nextTick(this.load)
    }
  },

  methods: {
    visibilityChange(visible) {
      this.visible = visible
      if (visible) {
        if (!this.loaded) {
          this.$nextTick(this.load)
        }
      }
    },
    doneLoading() {
      this.$emit('loaded')
      this.loaded = true
    },
    async load() {
      const image = this.$refs.lazy
      if (!image) {
        return
      }
      if (image.src === placeholder) {
        image.src = this.src
      }
      await new Promise(resolve => imagesLoaded(this.$el, resolve))
      this.doneLoading()
    }
  }
}
</script>

<style>
.image {
  position: relative;
}

.image.is-fluid,
.image.is-fluid .image__img,
.image.is-cover .image__img,
.image.has-ratio .image__img {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.image.is-boxed,
.image.is-boxed .image__img {
  height: 100%;
}

.image.is-boxed img {
  height: 100%;
  width: auto;
}

.image.has-ratio:not(.is-fluid, .is-boxed) {
  height: 0;
  overflow: hidden;
  position: relative;
}

.image__img {
  opacity: 0;
  transition: opacity var(--fade-speed);
}

.image.is-loaded .image__img {
  opacity: 1;
}

.image .image__img.swiper-lazy {
  opacity: 0;
}

.image .image__img.swiper-lazy-loaded {
  opacity: 1;
}

.image .image__img.swiper-lazy-loaded + .image__spinner {
  display: none;
}

.image.is-cover .image__img {
  object-fit: cover;
  object-position: center;
}

.image.is-contain .image__img {
  object-fit: contain;
  object-position: center;
}

.image.block .image__img {
  display: block;
}
</style>
