<template functional>
  <transition name="fade-scrim" appear>
    <div
      v-if="props.active"
      :ref="data.ref"
      :class="[data.class, data.staticClass]"
      :style="[data.style, data.staticStyle]"
      class="scrim fixed inset-0 z-scrim"
      v-on="listeners"
    />
  </transition>
</template>

<script>
export default {
  name: 'Scrim',

  functional: true,

  props: {
    active: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style>
@keyframes fade-scrim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: var(--scrim-opacity);
  }
}

.scrim {
  --scrim-opacity: 0.85;
  background-color: theme('colors.ultramarine');
  opacity: var(--scrim-opacity);
}

.fade-scrim-enter-active {
  animation: fade-scrim var(--fade-speed) forwards;
  pointer-events: none;
}

.fade-scrim-leave-active {
  animation: fade-scrim var(--fade-speed) reverse forwards;
}
</style>
