import { AiFillInfoCircle } from 'react-icons/ai'
import basicEditor from './blocks/basic-editor'
import imageField from './fields/image'

export default {
  title: "What's Inside",
  name: 'whatsInside',
  type: 'document',
  icon: AiFillInfoCircle,
  __experimental_actions: ['update', 'publish'],
  fieldsets: [
    {
      name: 'intro',
      title: 'Introduction'
    },
    {
      name: 'relatedLinks',
      title: 'Related Links Section'
    }
  ],
  fields: [
    {
      title: 'Page Title',
      name: 'title',
      type: 'string',
      validation: Rule => Rule.required()
    },
    {
      title: 'Intro Title',
      name: 'introTitle',
      type: 'string',
      fieldset: 'intro',
      validation: Rule => Rule.required()
    },
    {
      ...basicEditor,
      title: 'Intro Text',
      name: 'introText',
      fieldset: 'intro',
      validation: Rule => Rule.required()
    },
    {
      title: 'Product Category Sections',
      name: 'productCategorySections',
      type: 'array',
      of: [
        {
          title: 'Product Category Section',
          name: 'productCategorySection',
          type: 'object',
          fields: [
            {
              title: 'Product Category',
              name: 'productCategory',
              type: 'reference',
              to: [{ type: 'productCategory' }]
            },
            {
              title: 'Heading',
              name: 'heading',
              type: 'string'
            },
            {
              ...basicEditor,
              title: 'Description',
              name: 'description'
            },
            {
              ...imageField,
              title: 'Image',
              name: 'image',
              preview: {
                select: {
                  image: 'image',
                  imageFilename: 'image.asset.originalFilename'
                },
                prepare: ({ image, imageFilename }) => ({
                  title: imageFilename || 'New Image',
                  media: image
                })
              }
            },
            {
              name: 'backgroundColors',
              title: 'Background Colors',
              type: 'array',
              of: [{ type: 'color' }]
            }
          ],
          preview: {
            select: {
              heading: 'heading',
              image: 'image',
              productCategoryTitle: 'productCategory.title'
            },
            prepare: ({ heading, image, productCategoryTitle }) => ({
              title: heading || productCategoryTitle || 'Untitled',
              subtitle: productCategoryTitle,
              media: image
            })
          }
        }
      ]
    },
    {
      title: 'Section Heading',
      name: 'relatedLinksHeading',
      type: 'string',
      fieldset: 'relatedLinks'
    },
    {
      title: 'Related Links',
      name: 'relatedLinks',
      type: 'array',
      fieldset: 'relatedLinks',
      of: [{ type: 'link' }]
    }
  ]
}
