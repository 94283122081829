export const MoveDom = {
  inserted(el, bindings) {
    // default appends el to body
    const container = bindings.arg
      ? document.getElementById(bindings.arg)
      : document.body
    if (container) {
      bindings.modifiers.prepend && container.firstChild
        ? container.insertBefore(el, container.firstChild) // top of target
        : container.appendChild(el) // bottom of target
    } else {
      // eslint-disable-next-line no-console
      console.warn(`v-move-dom target element id "${bindings.arg}" not found`)
    }
  },

  unbind(el) {
    if (el.parentNode) {
      el.parentNode.removeChild(el)
    }
  }
}

export default function(Vue) {
  Vue.directive('MoveDom', MoveDom)
}
