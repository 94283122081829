/**
 * A basic function for transforming portableText blocks to plain text.
 *
 * @link https://www.sanity.io/docs/presenting-block-text#ac67a867dd69
 *
 * @param {Array} blocks An array of blocks.
 * @returns {String} The text content of the provided blocks.
 */
export default function toString(blocks = []) {
  return blocks
    .map(block =>
      block._type === 'block' && block.children
        ? block.children.map(o => o.text).join('')
        : ''
    )
    .join('\n\n')
}
