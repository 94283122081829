export default {
  title: 'Image',
  name: 'image',
  type: 'image',
  fields: [
    {
      title: 'Caption',
      name: 'caption',
      type: 'string'
    },
    {
      title: 'Attribution',
      name: 'attribution',
      type: 'string'
    }
  ],
  options: {
    metadata: ['image']
  },
  preview: {
    select: {
      caption: 'caption',
      media: 'asset',
      filename: 'asset.originalFilename'
    },
    prepare: ({ caption, filename, media }) => ({
      title: caption || filename,
      media
    })
  }
}
