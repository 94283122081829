import { AiFillAmazonCircle } from 'react-icons/ai'

export default {
  title: 'Amazon Settings',
  icon: AiFillAmazonCircle,
  name: 'amazonSettings',
  type: 'document',
  // __experimental_actions: ['update', 'publish'],
  fields: [
    {
      type: 'image',
      name: 'image',
      title: 'Image'
    }
  ]
}
