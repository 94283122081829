import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2caff676 = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "" */))
const _d99a5efe = () => interopDefault(import('../pages/shop/sparkling-water/index.vue' /* webpackChunkName: "" */))
const _1790d4d1 = () => interopDefault(import('../pages/shop/sparkling-water/_product.vue' /* webpackChunkName: "" */))
const _3dcd1d60 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _54204ff7 = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _5fba1543 = () => interopDefault(import('../pages/find-your-recess.vue' /* webpackChunkName: "pages/find-your-recess" */))
const _7ef70f5e = () => interopDefault(import('../pages/labresults.vue' /* webpackChunkName: "pages/labresults" */))
const _1c9856eb = () => interopDefault(import('../pages/press.vue' /* webpackChunkName: "pages/press" */))
const _78e0b1fe = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _1c6cfcc5 = () => interopDefault(import('../pages/sparkling-water.vue' /* webpackChunkName: "pages/sparkling-water" */))
const _4ce46cbc = () => interopDefault(import('../pages/testimonials.vue' /* webpackChunkName: "pages/testimonials" */))
const _1f30c362 = () => interopDefault(import('../pages/whats-inside.vue' /* webpackChunkName: "pages/whats-inside" */))
const _94d51c3c = () => interopDefault(import('../pages/where-to-buy.vue' /* webpackChunkName: "pages/where-to-buy" */))
const _9a6c78b4 = () => interopDefault(import('../pages/wholesale.vue' /* webpackChunkName: "pages/wholesale" */))
const _7cb81288 = () => interopDefault(import('../pages/shop/gift-card.vue' /* webpackChunkName: "pages/shop/gift-card" */))
const _372af441 = () => interopDefault(import('../pages/shop/realitywear/index.vue' /* webpackChunkName: "pages/shop/realitywear/index" */))
const _5785d88f = () => interopDefault(import('../pages/shop/sparkling-water/index.vue' /* webpackChunkName: "pages/shop/sparkling-water/index" */))
const _0f6bcd9d = () => interopDefault(import('../pages/shop/buy-now/_page.vue' /* webpackChunkName: "pages/shop/buy-now/_page" */))
const _923d15ba = () => interopDefault(import('../pages/shop/collections/_page.vue' /* webpackChunkName: "pages/shop/collections/_page" */))
const _60d91911 = () => interopDefault(import('../pages/shop/realitywear/_product.vue' /* webpackChunkName: "pages/shop/realitywear/_product" */))
const _dbf1abfa = () => interopDefault(import('../pages/shop/sparkling-water/_product.vue' /* webpackChunkName: "pages/shop/sparkling-water/_product" */))
const _c85204ca = () => interopDefault(import('../pages/preview/_id.vue' /* webpackChunkName: "pages/preview/_id" */))
const _8af18de2 = () => interopDefault(import('../pages/shop/_category/index.vue' /* webpackChunkName: "pages/shop/_category/index" */))
const _74fcff9c = () => interopDefault(import('../pages/sign-up/_page.vue' /* webpackChunkName: "pages/sign-up/_page" */))
const _21780cfa = () => interopDefault(import('../pages/shop/_category/_product.vue' /* webpackChunkName: "pages/shop/_category/_product" */))
const _22b0fbfa = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e91b7ed4 = () => interopDefault(import('../pages/_page.vue' /* webpackChunkName: "pages/_page" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'is-parent-active',
  linkExactActiveClass: 'is-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin/*",
    component: _2caff676,
    name: "admin-catch-all"
  }, {
    path: "/shop/powder-stick-pack",
    component: _d99a5efe,
    name: "powder-stick-pack"
  }, {
    path: "/shop/powder-stick-pack/:product",
    component: _1790d4d1,
    name: "shop-powder-stick-pack-product"
  }, {
    path: "/shop/recess-mood-powders",
    component: _d99a5efe,
    name: "mood-powders"
  }, {
    path: "/shop/mood-powder-tubs",
    component: _d99a5efe,
    name: "mood-powder-tubs"
  }, {
    path: "/shop/mood-powder-tubs/:product",
    component: _1790d4d1,
    name: "shop-mood-powder-tub-product"
  }, {
    path: "/shop/recess-mood",
    component: _d99a5efe,
    name: "recess-mood"
  }, {
    path: "/shop/recess-mood/:product",
    component: _1790d4d1,
    name: "shop-recess-mood-product"
  }, {
    path: "/shop/zero-proof",
    component: _d99a5efe,
    name: "zero-proof"
  }, {
    path: "/shop/zero-proof/:product",
    component: _1790d4d1,
    name: "shop-zero-proof-product"
  }, {
    path: "/404",
    component: _3dcd1d60,
    name: "404"
  }, {
    path: "/admin",
    component: _54204ff7,
    name: "admin"
  }, {
    path: "/find-your-recess",
    component: _5fba1543,
    name: "find-your-recess"
  }, {
    path: "/labresults",
    component: _7ef70f5e,
    name: "labresults"
  }, {
    path: "/press",
    component: _1c9856eb,
    name: "press"
  }, {
    path: "/shop",
    component: _78e0b1fe,
    name: "shop"
  }, {
    path: "/sparkling-water",
    component: _1c6cfcc5,
    name: "sparkling-water"
  }, {
    path: "/testimonials",
    component: _4ce46cbc,
    name: "testimonials"
  }, {
    path: "/whats-inside",
    component: _1f30c362,
    name: "whats-inside"
  }, {
    path: "/where-to-buy",
    component: _94d51c3c,
    name: "where-to-buy"
  }, {
    path: "/wholesale",
    component: _9a6c78b4,
    name: "wholesale"
  }, {
    path: "/shop/gift-card",
    component: _7cb81288,
    name: "shop-gift-card"
  }, {
    path: "/shop/realitywear",
    component: _372af441,
    name: "shop-realitywear"
  }, {
    path: "/shop/sparkling-water",
    component: _5785d88f,
    name: "shop-sparkling-water"
  }, {
    path: "/shop/buy-now/:page?",
    component: _0f6bcd9d,
    name: "shop-buy-now-page"
  }, {
    path: "/shop/collections/:page?",
    component: _923d15ba,
    name: "shop-collections-page"
  }, {
    path: "/shop/realitywear/:product",
    component: _60d91911,
    name: "shop-realitywear-product"
  }, {
    path: "/shop/sparkling-water/:product?",
    component: _dbf1abfa,
    name: "shop-sparkling-water-product"
  }, {
    path: "/preview/:id?",
    component: _c85204ca,
    name: "preview-id"
  }, {
    path: "/shop/:category",
    component: _8af18de2,
    name: "shop-category"
  }, {
    path: "/sign-up/:page?",
    component: _74fcff9c,
    name: "sign-up-page"
  }, {
    path: "/shop/:category/:product",
    component: _21780cfa,
    name: "shop-category-product"
  }, {
    path: "/",
    component: _22b0fbfa,
    name: "index"
  }, {
    path: "/:page",
    component: _e91b7ed4,
    name: "page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
