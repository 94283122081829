<template>
  <recess-link
    v-if="product"
    :to="documentPath(product)"
    :style="style"
    class="product-link link"
  >
    <slot />
  </recess-link>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SanityProductLink',

  props: {
    reference: {
      default: () => ({}),
      type: Object
    }
  },

  computed: {
    ...mapGetters('shop', {
      productById: 'productById'
    }),
    product() {
      const ref = this.reference
      return ref ? this.productById(ref._ref) : undefined
    },
    style() {
      const product = this.product
      const color = product ? this.documentColor(product) : []
      return {
        color
      }
    }
  }
}
</script>
