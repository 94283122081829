<template functional>
  <div
    v-if="props.gallery.length > 2"
    class="gallery-block container container--narrow"
  >
    <window-gallery :slides="props.gallery" class="gallery-block__gallery" />
  </div>
  <div
    v-else-if="props.gallery.length > 0 && props.gallery.length <= 2"
    class="gallery-block container"
  >
    <div class="gallery-block__2-up">
      <div
        v-for="(media, i) in props.gallery"
        :key="`media-${i}`"
        class="gallery-block__1-up"
      >
        <sanity-media v-bind="media" class="gallery-block__media" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SanityGalleryBlock',

  functional: true,

  props: {
    gallery: {
      default: () => [],
      type: Array
    }
  }
}
</script>

<style scoped>
.gallery-block__gallery {
  margin-left: auto;
  margin-right: auto;
}

.gallery-block__2-up {
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: 3em;
  grid-template-columns: 1fr 1fr;
}

.gallery-block__2-up .gallery-block__1-up {
  position: relative;
}

.gallery-block__media {
  display: block;
  width: 100%;
}
</style>
