import groq from 'groq'

export default groq`{
  "klaviyo": *[_type == "klaviyo"][0]{
    listId
  },
  "siteMeta": *[_type == "siteMeta"][0]{
    siteDescription,
    image {
      asset-> {
        ...
      }
    }
  },
  "rebuy": *[_type == "rebuy"][0]{
    dataSourceId
  },
  "socialMedia": *[_type == "socialMedia"][0]{
    links
  },
  "cart": *[_type == "cart"][0]{
    giftNoteTextPlaceholder,
    giftingNotice[],
    cbdWarning,
    shippingNotePowderProducts
  },
  "scrollIndicator": *[_type == "scrollIndicator"][0]{
    image {
      asset-> {
        ...
      }
    }
  },
  "amazonSettings": *[_type == "amazonSettings"][0]{
    image {
      asset-> {
        ...
      }
    }
  },
  "globalBanner": *[_type == "globalBanner"][0]{
    isActive,
    isScrolling,
    staticBanner,
    backgroundColor,
    textColor,
    banners[]
  },
  "discountBanner": *[_type == "discountBanner"][0]{
    backgroundColor,
    textColor,
    text
  },
  "footer": *[_type == "siteFooter"][0]{
    newsletterText,
    disclaimer,
    fdaDisclaimer,
    copyright
  },
  "discountCodeSettings": *[_type == "discountCodeSettings"][0]{
    isEnabled,
    discountCode,
    startTime,
    endTime,
    isSubscriptionOnly
  }
}`
