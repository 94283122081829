import link from '../annotations/link'
import productLink from '../annotations/product-link'
import small from '../decorators/small'

export default {
  title: 'Text',
  name: 'text',
  type: 'array',
  of: [
    {
      type: 'block',
      styles: [
        { title: 'Normal', value: 'normal' },
        { title: 'Heading', value: 'h4' }
      ],
      lists: [{ title: 'Bullet', value: 'bullet' }],
      marks: {
        decorators: [
          { title: 'Strong', value: 'strong' },
          { title: 'Emphasis', value: 'em' },
          { title: 'Underline', value: 'underline' },
          small
        ],
        annotations: [link, productLink]
      }
    }
  ]
}
