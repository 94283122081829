<template>
  <gallery
    v-if="testimonials.length > 0"
    :slides="testimonials"
    :cursor-over="cursorOver"
    :options="swiperOptions"
    class="container testimonials"
  >
    <div
      v-for="testimonial in testimonials"
      :key="testimonial._id"
      class="flex h-full px-6 py-4 swiper-slide"
    >
      <figure
        class="relative flex flex-col items-center justify-center w-full h-full text-center before:shadow after:quote"
      >
        <div
          class="w-full p-10 bg-background before:testimonials-white-overlay"
        >
          <sanity-blocks
            v-if="testimonial.quote"
            class="relative mb-8 text-xl font-medium leading-tight testimonial-content"
            :blocks="testimonial.quote"
          />
          <figcaption
            v-if="testimonial.attribution"
            class="relative testimonial-content"
          >
            &mdash; {{ testimonial.attribution }}
          </figcaption>
        </div>
      </figure>
    </div>
  </gallery>
</template>

<script>
export default {
  name: 'Testimonials',

  props: {
    testimonials: {
      default: () => [],
      type: Array
    },
    options: {
      default: () => ({}),
      type: Object
    }
  },

  data() {
    return {
      windowWidth: null
    }
  },

  computed: {
    swiperOptions() {
      return {
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 60
          },
          1024: {
            slidesPerView: 3
          }
        },
        autoHeight: false,
        autoplay: false,
        centeredSlides: false,
        ...this.options
      }
    },
    cursorOver() {
      if (this.windowWidth === null) {
        return false
      }

      let slidePerView = 1
      const sizes = Object.keys(this.swiperOptions.breakpoints).reverse()

      for (const i in sizes) {
        if (this.windowWidth > sizes[i]) {
          slidePerView = this.swiperOptions.breakpoints[sizes[i]].slidesPerView
          break
        }
      }

      // Only show the cusrsor over if number of testimonials
      // is higher than the configured slidePerView
      return this.testimonials.length > slidePerView
    }
  },

  mounted() {
    this.$nextTick(this.handleResize)
    this.$bus.$on('resize', this.handleResize)
  },

  beforeDestroy() {
    this.$bus.$off('resize', this.handleResize)
  },

  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style scoped>
.before\:shadow::before {
  background-color: var(--text-color);
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(1rem, 1rem);
  z-index: -1;
}

.after\:quote::after {
  content: '\201C';
  display: block;
  font-size: 5rem;
  font-weight: theme('fontWeight.medium');
  left: 50%;
  line-height: 1;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate(-50%, -0.25em);
  user-select: none;
}

.bg-background {
  background-color: var(--background-color);
  z-index: 0;
}

.before\:testimonials-white-overlay::before {
  background-color: theme('colors.white');
  content: '';
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  transition: opacity var(--hover-speed);
  width: 100%;
  z-index: 1;
}

.testimonials {
  --pagination-gutter: 3em;
}

.testimonials /deep/ .gallery__wrapper {
  align-items: center;
  margin-bottom: 1.5em;
}

.testimonial-content {
  z-index: 2;
}
</style>
