<template>
  <div :class="classes" class="number">
    <label v-if="label" :id="`number-${$_uid}-label`" class="number__label">
      {{ label }}
    </label>
    <div class="number__ui" @mouseover="mouseOver" @mouseleave="mouseLeave">
      <button
        v-if="controls"
        type="button"
        class="number__button"
        :disabled="disabled || numericValue <= min"
        @mousedown="start(decrement)"
        @touchstart="
          $event.preventDefault()
          start(decrement)
        "
        @touchend="
          $event.preventDefault()
          stop($event)
        "
      >
        <fa icon="minus" class="number__icon" />
      </button>
      <input
        ref="input"
        :name="name"
        type="number"
        :class="['numeric-input', controls ? '' : 'no-control']"
        :value="numericValue"
        :max="max"
        :min="min"
        :autofocus="autofocus"
        :disabled="disabled"
        :readonly="readonly"
        :aria-labelledby="`number-${$_uid}-label`"
        class="number__input"
        @input="inputHandler($event.target.value)"
        @change="onChange"
        @blur="onBlur"
        @focus="onFocus"
      />
      <button
        v-if="controls"
        type="button"
        class="number__button"
        :disabled="disabled || numericValue >= max"
        @mousedown="start(increment)"
        @touchstart="
          $event.preventDefault()
          start(increment)
        "
        @touchend="
          $event.preventDefault()
          stop($event)
        "
      >
        <fa icon="plus" class="number__icon" />
      </button>
    </div>
  </div>
</template>

<script>
/**
 * Based on vue-number-input
 * https://github.com/fengyuanchen/vue-number-input
 * MIT © Chen Fengyuan
 */

const timeInterval = 100

export default {
  name: 'NumberInput',

  props: {
    name: {
      default: undefined,
      type: String
    },
    label: {
      default: undefined,
      type: String
    },
    value: {
      default: undefined,
      type: Number
    },
    min: {
      type: Number,
      default: -Infinity
    },
    max: {
      type: Number,
      default: Infinity
    },
    step: {
      type: Number,
      default: 1
    },
    size: {
      type: String,
      default: '150px'
    },
    precision: {
      default: 1,
      type: Number,
      validator(val) {
        return val >= 0 && Number.isInteger(val)
      }
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    controls: {
      type: Boolean,
      default: true
    },
    controlsType: {
      type: String,
      default: 'plusminus'
    }
  },

  data() {
    return {
      numericValue: null,
      interval: null,
      startTime: null,
      handler: Function
    }
  },

  computed: {
    classes() {
      return {
        'is-hovered': this.hovered,
        'is-disabled': this.disabled
      }
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        let newValue = val
        if (newValue) {
          newValue = this.toPrecision(newValue, this.precision)
          if (newValue >= this.max) {
            newValue = this.max
          }
          if (newValue <= this.min) {
            newValue = this.min
          }
          if (newValue !== val) {
            this.$emit('input', newValue)
          }
        }
        this.numericValue = newValue
      }
    }
  },

  beforeDestroy() {
    clearInterval(this.interval)
    this.interval = null
    this.handler = null
    this.startTime = null
  },

  methods: {
    /**
     * Function convert value to number
     * @param val
     * @returns {number | Number}
     */
    toNumber(val) {
      let num = parseFloat(val)
      if (isNaN(val) || !isFinite(val)) {
        num = 0
      }
      return num
    },
    /**
     * Function to return fixed decimal precision of input val
     * @param val
     * @param precision
     * @returns {number | Number}
     */
    toPrecision(val, precision) {
      return precision !== undefined ? parseFloat(val.toFixed(precision)) : val
    },
    /**
     * Increment the current numeric value
     */
    increment() {
      this.updateValue(this.toNumber(this.numericValue) + this.step)
    },
    /**
     * Decrement the current numeric value
     */
    decrement() {
      this.updateValue(this.toNumber(this.numericValue) - this.step)
    },
    /**
     * Handle value on Input
     */
    inputHandler(val) {
      this.updateValue(this.toNumber(val), val)
    },
    /**
     * Update value on operation performed
     * @param val
     */
    updateValue(val, strVal = null) {
      const oldVal = this.numericValue
      val = this.toPrecision(val, this.precision)
      if (val >= this.max) {
        val = this.max
      }
      if (val <= this.min) {
        val = this.min
      }
      if (val === oldVal) {
        this.$refs.input.value =
          strVal && val === this.toNumber(strVal) ? strVal : val
        return
      }
      this.numericValue = val
      this.$emit('input', val)
    },
    /**
     * Start a repetitive call to increment and decrement
     * method after a timeInterval on mousedown event and
     * will stop on mouseup event on controls
     * @param handler - increment or decrement method
     */
    start(handler) {
      document.addEventListener('mouseup', this.stop)
      this.startTime = new Date()
      this.handler = handler
      clearInterval(this.interval)
      this.interval = setInterval(handler, timeInterval)
    },
    /**
     * clear interval on mouseup event and remove the listener
     * @param evt - event to be removed
     */
    stop(evt) {
      document.removeEventListener(evt.type, this.stop)
      if (new Date() - this.startTime < timeInterval) {
        this.handler()
      }
      clearInterval(this.interval)
      this.interval = null
      this.handler = null
      this.startTime = null
      if (this.value !== this.numericValue)
        this.$emit('change', this.numericValue)
    },
    onBlur(event) {
      this.$emit('blur', event)
    },
    onFocus(event) {
      this.$emit('focus', event)
    },
    onChange(event) {
      this.$emit('change', this.numericValue)
    },
    mouseOver() {
      this.hovered = true
    },
    mouseLeave() {
      this.hovered = false
    },
    focus() {
      if (!this.disabled) {
        this.$refs.input.focus()
      }
    },
    blur() {
      this.$refs.input.blur()
    }
  }
}
</script>

<style>
.number {
  --number-border: var(--input-border);
  --number-border-radius: var(--input-border-radius);
  --number-height: var(--input-height);
  --number-background: var(--input-background-color);
  --number-gutter: var(--input-gutter);
  --number-button-size: 0.75em;
}

.number__ui,
.number__button {
  border-radius: var(--number-border-radius);
  transition: color var(--hover-speed), background-color var(--hover-speed);
}

.number__ui {
  align-items: center;
  border: var(--number-border);
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  font-weight: theme('fontWeight.medium');
  height: var(--number-height);
  overflow: hidden;
  text-align: center;
}

.number__ui:hover,
[data-js-focus-visible] .number__ui[data-focus-visible-added] {
  background-color: var(--number-background);
}

.number__button,
.number__input {
  height: 100%;
  line-height: 1;
  transition: opacity var(--hover-speed);
}

.number__button {
  flex: 0 1 auto;
  font-size: var(--number-button-size);
  padding: var(--number-gutter);
  vertical-align: middle;
}

.number__input {
  flex: 1 1 auto;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 3em;
}

.number__button:focus,
.number__input:focus {
  outline: 0;
}

.number__ui:hover .number__button,
.number__ui:hover .number__input {
  opacity: 0.6;
}

.is-hovered .number__ui .number__input {
  opacity: 1;
}

.number__ui:hover .number__button:hover,
.number__ui:hover .number__input:hover,
[data-js-focus-visible]
  .number__ui:hover
  .number__button[data-focus-visible-added],
[data-js-focus-visible]
  .number__ui:hover
  .number__input[data-focus-visible-added] {
  opacity: 1;
}
</style>
