export default {
  setContactFormSubmitted(state, payload) {
    const { route, message, id } = payload
    const key = route.params.page
    const page = state.pages[key] || {}
    state.pages = {
      ...state.pages,
      [key]: {
        ...page,
        contactForm: {
          id,
          isSubmitted: true,
          submissionMessage: message
        }
      }
    }
  },
  resetContactForm(state, payload) {
    const { route } = payload
    const key = route.params.page
    const page = state.pages[key] || {}
    state.pages = {
      ...state.pages,
      [key]: {
        ...page,
        contactForm: undefined
      }
    }
  }
}
