import { IoIosVideocam } from 'react-icons/io'
import media from './media'

export default {
  title: 'Social Media',
  name: 'socialMedia',
  icon: IoIosVideocam,
  type: 'array',
  of: [
    {
      title: 'Media Item',
      name: 'post',
      type: 'object',
      fields: [
        media,
        {
          title: 'Title',
          name: 'title',
          type: 'string'
        },
        {
          title: 'URL',
          name: 'url',
          type: 'url'
        }
      ],
      preview: {
        select: {
          title: 'title',
          media: 'media',
          imageFilename: 'media.image.asset.originalFilename'
        },
        prepare: ({ title, media, imageFilename }) => ({
          title:
            title ||
            imageFilename ||
            (media?.video && 'Social Video') ||
            'Media',
          media: media?.image || null
        })
      }
    }
  ],
  validation: Rule => Rule.max(4).error("Can't have more than 4 social posts")
}
