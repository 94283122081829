<template functional>
  <blockquote class="blockquote container container--wide">
    <div class="wrap">
      <slot />
    </div>
  </blockquote>
</template>

<script>
export default {
  name: 'SanityBlockquoteBlock',

  functional: true
}
</script>
