<template>
  <div
    id="global-banner"
    v-observe-visibility="visibilityChanged"
    class="global-banner font-medium"
    :style="{ backgroundColor: backgroundColor.hex, color: textColor.hex }"
  >
    <div class="container text-center">
      {{ text }}
    </div>
    <div class="global-banner__waves">
      <marquee
        class="absolute bottom-full left-0 w-full -mb-px"
        speed="16"
        fixed-speed
        reverse
      >
        <div class="global-banner__wave-group">
          <wave
            v-for="waveIndex in waves"
            :key="waveIndex"
            :color="backgroundColor.hex"
          />
        </div>
      </marquee>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { times } from 'lodash'
import Wave from './wave'

const numberOfWaves = 6

export default {
  name: 'GlobalBanner',

  components: {
    Wave
  },

  props: {
    text: {
      type: String,
      required: true
    },
    textColor: {
      type: Object,
      default() {
        return this.$store.state.settings.globalBanner.textColor
      }
    },
    backgroundColor: {
      type: Object,
      default() {
        return this.$store.state.settings.globalBanner.backgroundColor
      }
    }
  },

  data: () => ({
    waves: times(numberOfWaves)
  }),

  mounted() {
    this.$nextTick(function() {
      this.setup()
    })
  },

  methods: {
    ...mapActions('screen', {
      setGlobalBannerHeight: 'setGlobalBannerHeight',
      resetGlobalBannerHeight: 'resetGlobalBannerHeight'
    }),
    async setup() {
      await this.setGlobalBannerHeight()
    },
    async visibilityChanged(isVisible, entry) {
      if (isVisible) {
        await this.setGlobalBannerHeight()
      } else {
        await this.resetGlobalBannerHeight()
      }
    }
  }
}
</script>

<style>
.global-banner {
  --global-banner-wave-height: 12px;
  padding: 0.5em 0;
  position: relative;

  /* this matches the transfomr in the header
   otherwise, safari z indexes get mixed up */
  transform: translateZ(200px);

  @apply z-global-banner;
}

.global-banner__wave-group {
  display: flex;
  flex-direction: row;
  height: var(--global-banner-wave-height);
  width: 100vw;
}

.global-banner__wave-group .wave--horizontal {
  flex: 1;
  min-width: auto;
}

.global-banner__waves {
  bottom: 0;
  left: 0;
  position: absolute;
  transform: rotateZ(180deg);
  transform-origin: 50% 50%;
  width: 100%;
}

.is-overlay .global-banner {
  @apply z-base;
}
</style>
