import groq from 'groq'
import imageField from './image'

export default groq`
  defined(thumb) => {
    "thumb": thumb{
      ...,
      image{
        ...${imageField}
      }
    }
  }
`
