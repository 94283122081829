import { IoIosLeaf } from 'react-icons/io'

export default {
  title: 'Ingredients Settings',
  name: 'ingredients',
  type: 'document',
  icon: IoIosLeaf,
  fields: [
    {
      title: 'Title',
      name: 'title',
      type: 'string',
      validation: Rule => Rule.required().error('A title is required.')
    },
    {
      title: 'Short Description',
      name: 'description',
      type: 'text',
      rows: 3
    },
    {
      title: 'Long Description',
      name: 'longDescription',
      type: 'text',
      rows: 6
    },
    {
      title: 'Featured Ingredients',
      name: 'featuredIngredients',
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [{ type: 'ingredient' }]
        }
      ]
    }
  ]
}
