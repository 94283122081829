<template>
  <svg
    :viewBox="`0 0 ${width} ${height}`"
    preserveAspectRatio="none"
    :class="classes"
    class="wave animation-patch"
  >
    <path :fill="color" :d="path" />
  </svg>
</template>

<script>
import { mapState } from 'vuex'

const horizontalPath =
  'M640 60C516 60 476-0 316 0 156 0 116 60 0 60V100h640V60z'
const verticalPath = 'M40 640c0-124 60-164 60-324 0-160-60-200-60-316H0v640h40z'
const nativeWidth = 640
const nativeHeight = 100

export default {
  name: 'Wave',

  props: {
    color: {
      default: 'currentColor',
      type: String
    },
    vertical: {
      default: false,
      type: Boolean
    },
    reverse: {
      default: false,
      type: Boolean
    }
  },

  data: () => ({
    nativeWidth,
    nativeHeight
  }),

  computed: {
    ...mapState('screen', {
      screenWidth: state => state.width,
      screenHeight: state => state.height
    }),
    classes() {
      const vertical = this.vertical
      const reverse = this.reverse
      return {
        'wave--horizontal': !vertical,
        'wave--vertical': vertical,
        'animation-patch-x-reverse': reverse,
        'animation-patch-y-reverse': vertical && reverse
      }
    },
    width() {
      return this.vertical ? nativeHeight : nativeWidth
    },
    height() {
      return this.vertical ? nativeWidth : nativeHeight
    },
    path() {
      return this.vertical ? verticalPath : horizontalPath
    }
  }
}
</script>

<style>
.wave {
  display: block;
  max-height: 100%;
  max-width: none;
}

.wave.w-full {
  width: 100%;
}

.wave.h-full {
  height: 100%;
}

.wave--horizontal {
  min-width: 640px;
  width: calc(var(--win-width) * 0.75);
}

.wave--vertical {
  height: var(--win-height);
  max-height: none;
  max-width: 100%;
  min-height: 640px;
}
</style>
