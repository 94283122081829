var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(
    !_vm.notLazy && {
      once: true,
      callback: _vm.visibilityChange,
      intersection: {
        rootMargin: '25%'
      }
    }
  ),expression:"\n    !notLazy && {\n      once: true,\n      callback: visibilityChange,\n      intersection: {\n        rootMargin: '25%'\n      }\n    }\n  "}],staticClass:"image",class:_vm.classes,style:(_vm.style)},[_c('img',{ref:"lazy",staticClass:"image__img",class:_vm.imageClass,attrs:{"src":_vm.notLazy ? _vm.src : _vm.placeholder,"data-src":_vm.src,"width":_vm.width,"height":_vm.height,"alt":_vm.alt ? _vm.alt : ''}}),_vm._v(" "),(_vm.spinner && !_vm.loaded)?_c('spinner',{staticClass:"image__spinner"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }