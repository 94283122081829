<template>
  <div v-move-dom class="text-white gallery-overlay">
    <div v-click-outside="clickOut" class="container gallery-overlay__wrap">
      <gallery
        ref="gallery"
        :slides="slides"
        :options="options"
        :active-slide="activeSlide"
        :color="getColor('white')"
        :center-area-enabled="centerAreaEnabled"
        :min-side-percent="minSidePercent"
        :hide-cursor-in-center="hideCursorInCenter"
        class="gallery-overlay__gallery"
        @update:zoom="$emit('update:zoom', $event)"
        @update:activeSlide="$emit('update:activeSlide', $event)"
      >
        <slot />
      </gallery>
      <div v-if="showOverlayBottomSlot">
        <slot name="overlay-bottom" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'GalleryOverlay',

  props: {
    tag: {
      default: 'div',
      type: String
    },
    slides: {
      default: () => [],
      type: Array
    },
    options: {
      default: () => ({}),
      type: Object
    },
    activeSlide: {
      default: 0,
      type: Number
    },
    centerAreaEnabled: {
      default: false,
      type: Boolean
    },
    minSidePercent: {
      default: 0.2,
      type: Number
    },
    hideCursorInCenter: {
      default: false,
      type: Boolean
    },
    showOverlayBottomSlot: {
      default: true,
      type: Boolean
    }
  },

  computed: {
    ...mapState('screen', {
      overlay: state => state.overlay
    }),
    opened() {
      return this.overlay === 'gallery'
    }
  },

  watch: {
    opened(open) {
      if (!open) {
        this.$emit('close')
      }
    },
    '$route.path'() {
      this.close()
    }
  },

  mounted() {
    this.open()
    this.$bus.$on('escKeydown', this.close)
  },

  beforeDestroy() {
    this.close()
    this.$bus.$off('escKeydown', this.close)
  },

  methods: {
    ...mapMutations({
      setOverlay: 'screen/setOverlay'
    }),
    open() {
      this.setOverlay('gallery')
    },
    close() {
      if (this.opened) {
        this.$emit('close')
        this.setOverlay()
      }
    },
    toggleZoom() {
      const gallery = this.$refs.gallery
      if (gallery) {
        gallery.toggleZoom()
      }
    },
    clickOut(e) {
      const target = e.currentTarget
      if (!this.$el.contains(target)) {
        this.close()
      }
      e.preventDefault()
      e.stopPropagation()
    }
  }
}
</script>

<style>
.gallery-overlay {
  height: var(--win-height);
  left: 0;
  padding-bottom: var(--header-height);
  padding-top: var(--header-height);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: theme('zIndex.galleryOverlay');
}

.gallery-overlay .swiper-container,
.gallery-overlay .swiper-wrapper {
  height: 100% !important;
}

.gallery-overlay .swiper-container {
  overflow: visible;
}

.gallery-overlay .swiper-slide img {
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.gallery-overlay__wrap,
.gallery-overlay__gallery {
  height: 100%;
}
</style>
