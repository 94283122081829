export default (context, inject) => {
  if (process.env.ga4MeasurementId) {
    const script = document.createElement('script')
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.ga4MeasurementId}`
    script.async = true
    document.head.appendChild(script)

    window.dataLayer = window.dataLayer || []

    function gtag() {
      window.dataLayer.push(arguments)
    }

    inject('gtag', gtag)
  }
}
