import formField from './fields/form-field'

export default {
  title: 'Form',
  name: 'form',
  type: 'document',
  fields: [
    {
      title: 'Title',
      name: 'title',
      type: 'string',
      validate: Rule => Rule.required()
    },
    {
      title: 'Slug',
      name: 'slug',
      type: 'slug',
      options: {
        source: 'title'
      }
    },
    {
      title: 'Notification recipients',
      name: 'notificationRecipients',
      type: 'array',
      of: [
        {
          type: 'object',
          fields: [
            {
              title: 'Name',
              name: 'name',
              type: 'string'
            },
            {
              title: 'Email',
              name: 'email',
              type: 'string'
            }
          ]
        }
      ],
      validate: Rule => Rule.required()
    },
    {
      title: 'Fields',
      name: 'fields',
      type: 'array',
      of: [formField],
      validate: Rule => Rule.required()
    },
    {
      title: 'Submit button text',
      name: 'submitValue',
      type: 'string',
      validate: Rule => Rule.required(),
      initialValue: 'Submit'
    },
    {
      title: 'Confirmation message',
      name: 'successMessage',
      type: 'text',
      initialValue: 'Success! Thank you.'
    }
  ]
}
