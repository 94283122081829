import imageField from './image'
import videoField from './video'

export default {
  title: 'Media',
  name: 'media',
  type: 'object',
  fields: [
    imageField,
    videoField,
    {
      title: 'Caption',
      name: 'caption',
      type: 'string'
    },
    {
      title: 'Attribution',
      name: 'attribution',
      type: 'string'
    }
  ],
  validation: Rule =>
    Rule.custom((fields = {}) => {
      const { image, video } = fields
      if (!image && !video) {
        return 'An image or video is required'
      }
      if (image && video) {
        return 'Image and video are mutually exclusive'
      }
      return true
    }),
  preview: {
    select: {
      caption: 'caption',
      video: 'mux',
      image: 'image',
      imageFilename: 'image.asset.originalFilename'
    },
    prepare: ({ caption, image, imageFilename, video }) => ({
      title: caption || (video && 'Video') || imageFilename || 'New Media',
      media: image
    })
  }
}
