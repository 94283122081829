import { now } from 'lodash'

export default (discountCodeSettings, hasSubscriptionItem) => {
  // If the code is sub only and we don't have a sub item in the cart,
  // we return null
  if (
    (discountCodeSettings.isSubscriptionOnly && !hasSubscriptionItem) ||
    !discountCodeSettings.isEnabled ||
    !discountCodeSettings.discountCode
  ) {
    return null
  }

  // If a timeframe wasn't defined, the discount code is considered
  // valid at this point
  if (
    discountCodeSettings.startTime === null &&
    discountCodeSettings.endTime === null
  ) {
    return discountCodeSettings.discountCode
  }

  const timestamp = now()
  const isAfterStartTime =
    discountCodeSettings.startTime &&
    Date.parse(discountCodeSettings.startTime) <= timestamp
  const isBeforeEndTime =
    discountCodeSettings.endTime &&
    Date.parse(discountCodeSettings.endTime) >= timestamp

  if (
    (isAfterStartTime && isBeforeEndTime) ||
    (isAfterStartTime && !discountCodeSettings.endTime) ||
    (isBeforeEndTime && !discountCodeSettings.startTime)
  ) {
    return discountCodeSettings.discountCode
  }
}
