import Vue from 'vue'
import slugify from 'slugify'

Vue.filter('slugify', (val, opts) => {
  opts = {
    replacement: '-',
    lower: true,
    ...opts
  }
  return slugify(val, opts)
})
