function getPage(state, route) {
  return state.pages[route.params.page]
}

export default {
  getContactForm: state => route => {
    const page = getPage(state, route)
    return page && page.contactForm
  }
}
