<template functional>
  <div class="bulleted-list container container--narrow text-center">
    <ul>
      <slot />
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SanityBulletedList',

  functional: true
}
</script>

<style>
.bulleted-list ul {
  display: block !important;
}
</style>
