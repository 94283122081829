<template>
  <div
    v-observe-visibility="visibilityOptions"
    :style="{ '--row-height': `${100 / rows}%` }"
    class="sky"
  >
    <div v-if="visible && initialized" class="sky__wrap">
      <div
        v-for="(cloud, i) in clouds"
        :key="`cloud-${i}`"
        class="sky__cloud-wrap"
      >
        <drifting-cloud
          ref="clouds"
          :width="width"
          :column="cloud.column"
          :image="cloud.image"
          :distance="cloud.distance"
          :speed="cloud.speed"
          :dismissed="cloud.dismissed"
          :restart-after="null"
          :style="{ '--column-count': columns }"
          class="sky__cloud"
          @dismiss="dismiss(i, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import random from 'lodash/random'
import sample from 'lodash/sample'
import shuffle from 'lodash/shuffle'
import without from 'lodash/without'
import { clouds as recessClouds } from '~/recess.json'
import {
  randomCloudImage,
  randomCloudDistance,
  randomCloudSpeed
} from '~/util/clouds'

export default {
  props: {
    columns: {
      default: 6,
      type: Number
    },
    rows: {
      default: 6,
      type: Number
    },
    distance: {
      default: undefined,
      type: [Number, String]
    },
    distances: {
      default: () =>
        Array.from({ length: recessClouds.distances }, (_, i) => i + 1),
      type: Array
    },
    speed: {
      default: undefined,
      type: [Number, String]
    },
    width: {
      default: undefined,
      type: String
    },
    restartAfter: {
      default: 120000, // ms, defaults to 2 minutes
      type: [Number, String]
    },
    restartMinMax: {
      default: undefined,
      type: Array
    }
  },

  data() {
    return {
      clouds: [],
      randomColumns: [],
      randomImages: [],
      initialized: false,
      visible: true,
      visibilityOptions: {
        throttle: 500,
        leading: 'hidden',
        callback: this.visibilityChange
      }
    }
  },

  computed: {
    activeColumns() {
      return this.clouds.filter(c => !c.dismissed).map(c => c.column)
    },
    activeImages() {
      return this.clouds.filter(c => !c.dismissed).map(c => c.image)
    }
  },

  mounted() {
    this.$nextTick(this.resize)
    this.$bus.$on('resize', this.resize)
  },

  beforeDestroy() {
    this.$bus.$off('resize')
  },

  methods: {
    async dismiss(index, dismissed) {
      const cloud = this.clouds[index]
      if (!dismissed || !cloud) {
        return
      }
      this.$set(cloud, 'dismissed', true)
      try {
        await new Promise((resolve, reject) => {
          this.$once('hook:destroyed', reject)
          setTimeout(() => {
            // wait for the cloud to transition out (give or take)
            let restart = this.restartAfter
            const restartMinMax = this.restartMinMax
            if (restartMinMax && restartMinMax.length === 2) {
              restart = random(restartMinMax[0], restartMinMax[1])
            }
            this.$emit('dismiss')
            setTimeout(() => {
              // add the cloud with new column and image after a set or random interval
              const newDistance =
                this.distance || randomCloudDistance(this.distances)
              const newSpeed = this.speed || randomCloudSpeed(newDistance)
              const newCloud = {
                column:
                  sample(
                    without(
                      this.randomColumns,
                      cloud.column,
                      ...this.activeColumns
                    )
                  ) || randomCloudImage(),
                image:
                  sample(
                    without(
                      this.randomImages,
                      cloud.image,
                      ...this.activeImages
                    )
                  ) || random(1, this.columns),
                distance: newDistance,
                speed: newSpeed,
                dismissed: false
              }
              this.$set(this.clouds, index, newCloud)
              this.$nextTick(resolve)
            }, restart)
          }, 1000)
        })
      } catch (e) {}
    },
    setRandomizedClouds() {
      const clouds = shuffle(Array.from({ length: this.rows }, (_, i) => i + 1))
      const columns = shuffle(
        Array.from({ length: this.columns }, (_, i) => i + 1)
      )
      const images = shuffle(
        Array.from({ length: recessClouds.images.length }, (_, i) => i + 1)
      )
      this.randomColumns = columns
      this.randomImages = images
      this.clouds = clouds.map(c => {
        const distance = this.distance || randomCloudDistance(this.distances)
        return {
          column: columns[c - 1],
          image: images[c - 1],
          distance,
          speed: this.speed || randomCloudSpeed(distance),
          dismissed: false
        }
      })
    },
    visibilityChange(visible) {
      this.$nextTick(() => {
        this.initialized = visible
        this.visible = visible
      })
      if (!visible) {
        this.setRandomizedClouds()
      }
    },
    resize() {
      // remove clouds
      this.initialized = false
      this.$nextTick(this.refresh)
    },
    async refresh() {
      this.setRandomizedClouds()
      await this.$nextTick()
      this.initialized = true
    }
  }
}
</script>

<style>
.sky {
  contain-intrinsic-size: theme('height.win-h');
  content-visibility: auto;
  height: theme('height.win-h');
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translateZ(-200px);
  transform-style: flat;
  width: 100%;
}

.sky.relative {
  position: relative;
}

.sky.fixed {
  position: fixed;
}

.sky.sticky {
  position: sticky;
}

.sky.h-full {
  height: theme('height.full');
}

.sky__wrap {
  height: 100%;
}

.sky__cloud-wrap {
  height: var(--row-height, auto);
  position: relative;
}

.sky__cloud,
.sky__cloud .cloud,
.sky__cloud .cloud__wrap {
  height: 100%;
}

.sky__cloud {
  @apply py-4;
}

.sky__cloud .cloud .cloud__primary {
  cursor: pointer;
  pointer-events: auto;
}
</style>
