import sanityClient from '@sanity/client'

// Config data for Sanity Client
const sanity = sanityClient({
  projectId: "umoebutk",
  dataset: "production",
  token: "",
  useCdn: true,
  withCredentials: false
})

export default (context, inject) => {
  // Inject sanity client to the instance and context as $sanity
  context.$sanity = sanity
  inject('sanity', sanity)
}