import getProductPath from '~/util/paths/get-product-path'
import getProductCategoryPath from '~/util/paths/get-product-category-path'

export default document => {
  if (!document) {
    return null
  }
  switch (document._type) {
    case 'product':
      return getProductPath(document.category, document.slug)
    case 'productCategory':
      return getProductCategoryPath(document.slug)
    case 'productCategoryCollection':
      return `/shop/collections/${document.slug}`
    case 'buyNow':
      return `/shop/buy-now/${document.slug}`
    case 'signUp':
      return `/sign-up/${document.slug}`
    case 'wholesale':
      return '/wholesale'
    case 'whatsInside':
    case 'ingredients':
      return '/whats-inside'
    case 'press':
      return '/press'
    case 'home':
    case 'featuredProduct':
      return '/'
    case 'testimonialsPage':
      return '/testimonials'
    default:
      return `/${document.slug}`
  }
}
