<template>
  <hover id="hero" :smooth="smooth" :has-hover="!!hoverMedia" class="hero">
    <sanity-media
      :max-width="maxWidth"
      :max-height="maxHeight"
      :caption="title"
      v-bind="defaultMedia"
      class="hero__default"
    />
    <template #hover>
      <sanity-media
        v-if="hoverMedia"
        v-bind="hoverMedia"
        :caption="title"
        :max-width="maxWidth"
        :max-height="maxHeight"
        class="hero__hover"
      />
    </template>
  </hover>
</template>

<script>
export default {
  name: 'Hero',

  props: {
    defaultMedia: {
      default: () => ({}),
      type: Object,
      required: true
    },
    hoverMedia: {
      default: null,
      type: Object
    },
    title: {
      default: undefined,
      type: String
    },
    smooth: {
      default: false,
      type: Boolean
    },
    maxWidth: {
      default: undefined,
      type: [Number, String]
    },
    maxHeight: {
      default: undefined,
      type: [Number, String]
    }
  }
}
</script>

<style>
.hero,
.hero img {
  max-height: calc(var(--win-height) * 0.75);
}

.hero {
  position: relative;
}

.hero,
.hero__default,
.hero__hover {
  display: block;
  width: 100%;
}

.hero__default img,
.hero__hover img {
  max-width: 100%;
  object-fit: contain;
  object-position: center;
  width: auto;
}

@screen 2short {
  .hero,
  .hero img {
    max-height: calc(var(--win-height) / 2);
  }
}

@screen tall {
  .hero,
  .hero img {
    max-height: calc(var(--win-height) / 2);
  }
}
</style>
