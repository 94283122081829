import { MdShoppingCart } from 'react-icons/md'

import { ShopifyProductVariantInput } from '../../components'
import basicEditor from '../blocks/basic-editor'
import inlineEditor from '../blocks/inline-editor'

export default {
  title: 'Buy Now Page',
  name: 'buyNow',
  type: 'document',
  icon: MdShoppingCart,
  fieldsets: [
    {
      name: 'whyItsGreat',
      title: "Why It's Great"
    }
  ],
  fields: [
    {
      title: 'CBD',
      name: 'cbd',
      type: 'boolean',
      description:
        'If CBD is enabled, this record will not be shown on the non-CBD storefront.'
    },
    {
      title: 'Title',
      name: 'title',
      type: 'string',
      validation: Rule => Rule.required().error('A title is required.')
    },
    {
      title: 'Slug',
      name: 'slug',
      type: 'slug',
      description: 'The URL path this page will exist at',
      options: {
        source: 'title',
        maxLength: 96
      },
      validation: Rule => Rule.required().error('A slug is required.')
    },
    {
      ...inlineEditor,
      title: 'Short Description',
      name: 'description'
    },
    {
      title: 'Poster',
      name: 'poster',
      type: 'image',
      description:
        'Representative image used in page sharing and search engine results'
    },
    {
      title: 'Discount Explainer Text',
      name: 'discountExplainer',
      type: 'string',
      description: 'For ex. "(pause, skip, or cancel at anytime)"'
    },
    {
      title: 'Hero Floating Image (top right)',
      name: 'floatingImage',
      type: 'image',
      description: 'Optional image (replaces peach & blackberry images)'
    },
    {
      title: 'Hero Floating Image (bottom right)',
      name: 'floatingImageBottomRight',
      type: 'image',
      description: 'Optional image'
    },
    {
      title: 'Hero Floating Image (bottom left)',
      name: 'floatingImageBottomLeft',
      type: 'image',
      description: 'Optional image'
    },
    {
      title: 'Hero CTA',
      name: 'cta',
      type: 'string',
      description: 'For ex. "shop all flavors"'
    },
    {
      title: 'Title',
      name: 'whyItsGreatTitle',
      type: 'string',
      description: 'Defaults to, you guessed it, "Why It\'s Great"',
      fieldset: 'whyItsGreat'
    },
    {
      title: 'Reasons',
      name: 'whyItsGreat',
      type: 'array',
      of: [
        {
          title: 'Reason',
          name: 'reason',
          type: 'object',
          fields: [
            {
              title: 'Thumbnail',
              name: 'thumbnail',
              type: 'image'
            },
            {
              title: 'Heading 1',
              name: 'heading1',
              type: 'string'
            },
            {
              title: 'Heading 2',
              name: 'heading2',
              type: 'string'
            }
          ]
        }
      ],
      validation: Rule =>
        Rule.max(6).error("Why It's Great can't have more than 4 reasons"),
      fieldset: 'whyItsGreat'
    },
    {
      title: 'Frequency Selector',
      name: 'frequency',
      type: 'object',
      fields: [
        {
          title: 'Title',
          name: 'title',
          type: 'string',
          description: 'For ex. "Choose frequency"'
        },
        basicEditor,
        {
          title: 'Frequency Headings',
          name: 'headings',
          type: 'string',
          description: 'For ex. "Delivery every"'
        },
        {
          title: 'Frequency Options',
          name: 'intervals',
          type: 'array',
          description:
            'Intervals that match *exactly* with products\' in Shopify. For ex. "6 weeks"',
          of: [
            {
              name: 'interval',
              type: 'string'
            }
          ],
          validation: Rule => Rule.max(4)
        }
      ]
    },
    {
      title: 'Scrolling Marquees',
      name: 'marquees',
      description: 'Overrides the default marquees',
      type: 'array',
      of: [{ type: 'string' }],
      validation: Rule =>
        Rule.max(4).error("Buy now page can't have more than 4 marquees")
    },
    {
      title: 'Products',
      name: 'products',
      type: 'array',
      of: [
        {
          title: 'Product',
          name: 'product',
          type: 'object',
          fields: [
            {
              title: 'Custom Title',
              name: 'title',
              type: 'string'
            },
            {
              title: 'Subtitle',
              name: 'subtitle',
              type: 'string',
              description: 'for ex. "12 pack"'
            },
            {
              title: 'Product',
              name: 'product',
              type: 'reference',
              to: [{ type: 'product' }]
            },
            {
              title: 'Product Variant',
              name: 'variant',
              type: 'object',
              fields: [
                {
                  title: 'Variant ID',
                  name: 'id',
                  type: 'string'
                },
                {
                  title: 'Product Title',
                  name: 'productTitle',
                  type: 'string'
                },
                {
                  title: 'Variant Title',
                  name: 'variantTitle',
                  type: 'string'
                }
              ],
              components: {
                input: ShopifyProductVariantInput
              },
              validation: Rule => Rule.required()
            }
          ],
          preview: {
            select: {
              title: 'title',
              productTitle: 'variant.productTitle',
              variantTitle: 'variant.variantTitle',
              image: 'product.shopify.image'
            },
            prepare: ({ title, productTitle, variantTitle, image }) => ({
              title:
                title ||
                [productTitle, variantTitle].filter(Boolean).join(' ') ||
                'New Product',
              media: image
            })
          }
        }
      ]
    },
    {
      title: 'FAQ',
      name: 'faq',
      type: 'object',
      fields: [
        {
          title: 'Title',
          name: 'title',
          type: 'string',
          description: 'For ex. "About subscriptions"'
        },
        {
          title: 'Frequently Asked Question',
          name: 'faqs',
          type: 'array',
          of: [
            {
              title: 'faq',
              name: 'faq',
              type: 'object',
              fields: [
                {
                  ...inlineEditor,
                  title: 'Question',
                  name: 'question',
                  validation: Rule => Rule.required()
                },
                {
                  ...basicEditor,
                  title: 'Answer',
                  name: 'answer',
                  validation: Rule => Rule.required()
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  preview: {
    select: {
      title: 'title',
      media: 'poster'
    }
  }
}
