<script>
import { gsap } from 'gsap'

export default {
  name: 'ExpandHeight',

  functional: true,

  render(createElement, ctx) {
    const props = {
      name: 'expand-height',
      css: false,
      fade: false,
      ...ctx.props,
      ...ctx.attrs
    }
    const data = {
      props,
      attrs: ctx.props,
      on: {
        beforeEnter(el) {
          const style = getComputedStyle(el)
          el.style.display = 'block'
          el.style.visibility = 'hidden'
          el.style.width = style.width
          el.style.height = 'auto'
          el.style.position = 'absolute'
          if (props.fade) {
            el.style.opacity = '0'
          }
        },
        async enter(el, done) {
          // force repaint to ensure correct height
          await new Promise(resolve => requestAnimationFrame(resolve))
          const height = el.clientHeight
          const animateVars = { height, duration: 0.25 }
          if (props.fade) {
            animateVars.autoAlpha = 1
          }
          const tl = gsap
            .timeline({ paused: true, onComplete: done })
            .to(el, animateVars)
          el.style.height = '0'
          el.style.overflow = 'hidden'
          el.style.visibility = ''
          el.style.width = ''
          el.style.position = ''
          // another repaint to ensure all the above
          await new Promise(resolve => requestAnimationFrame(resolve))
          tl.play()
        },
        afterEnter(el) {
          el.style.height = ''
          el.style.overflow = ''
        },
        leave(el, done) {
          const animateVars = { height: 0, duration: 0.25 }
          if (props.fade) {
            animateVars.autoAlpha = 0
          }
          gsap
            .timeline({ onComplete: done })
            .set(el, { overflow: 'hidden' })
            .to(el, animateVars)
        }
      }
    }
    return createElement('transition', data, ctx.children)
  }
}
</script>
