import { AiOutlinePercentage } from 'react-icons/ai'

export default {
  title: 'Discount Code Settings',
  icon: AiOutlinePercentage,
  name: 'discountCodeSettings',
  type: 'document',
  fields: [
    {
      title: 'Enabled',
      name: 'isEnabled',
      type: 'boolean',
      description:
        "Disabled discount codes will not be automatically added to customers' carts."
    },
    {
      title: 'Code',
      name: 'discountCode',
      type: 'string'
    },
    {
      title: 'Start Time',
      name: 'startTime',
      type: 'datetime',
      description: (function () {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        return `Current timezone: ${timezone}`
      })()
    },
    {
      title: 'End Time',
      name: 'endTime',
      type: 'datetime',
      description: (function () {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        return `Current timezone: ${timezone}`
      })()
    },
    {
      title: 'Subscription-only',
      name: 'isSubscriptionOnly',
      type: 'boolean'
    }
  ]
}
