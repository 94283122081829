import { FaLink } from 'react-icons/fa'

export default {
  name: 'link',
  type: 'object',
  title: 'Link',
  fields: [
    {
      title: 'URL',
      name: 'url',
      type: 'url',
      validation: Rule => Rule.uri({ allowRelative: true })
    },
    {
      title: 'Should this link be opened in a new tab?',
      name: 'external',
      type: 'boolean'
    }
  ],
  icon: FaLink
}
