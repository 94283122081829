import imageField from '../fields/captionable-image'
import videoField from '../fields/captionable-video'

export default {
  title: 'Gallery',
  name: 'gallery',
  type: 'array',
  of: [
    {
      ...imageField,
      options: {
        ...imageField.options,
        hotspot: true
      }
    },
    videoField
  ]
}
