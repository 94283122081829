<template>
  <div :is="tag" :class="classes">
    <slot />
  </div>
</template>

<script>
import SanityBlockquoteBlock from '~/components/sanity/blocks/blockquote.vue'
import SanityHeadingBlock from '~/components/sanity/blocks/heading.vue'

export default {
  name: 'SanityBlock',

  components: {
    SanityBlockquoteBlock,
    SanityHeadingBlock
  },

  props: {
    // eslint-disable-next-line vue/prop-name-casing
    _sanityProps: {
      default: undefined,
      type: Object
    },
    type: {
      default: undefined,
      type: String
    }
  },

  computed: {
    tag() {
      const sanityProps = this._sanityProps
      const style = sanityProps?.node?.style ?? 'normal'
      let tag
      switch (style) {
        case 'normal':
        case 'centered':
          tag = 'div'
          break
        case 'blockquote':
          tag = 'sanity-blockquote-block'
          break
        case 'heading':
          tag = 'sanity-heading-block'
          break
        default:
          tag = style
      }
      return tag
    },
    classes() {
      const sanityProps = this._sanityProps
      const style = sanityProps && sanityProps.node.style
      const tag = this.tag
      return {
        container: sanityProps.options.imageOptions.containerClass,
        'text-center': style === 'centered',
        'container--narrow':
          !sanityProps.options.imageOptions.containersAreWide &&
          tag !== 'sanity-blockquote-block'
      }
    }
  }
}
</script>
