var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gallery",class:_vm.classes},[_vm._t("top"),_vm._v(" "),_c('cursor-over',{staticClass:"relative w-full h-full",attrs:{"enabled":_vm.cursorOver,"icon-left":_vm.leftIcon,"icon-center":_vm.centerIcon,"icon-right":_vm.rightIcon,"icon-override":_vm.zoomIcon,"color":_vm.color,"center-area-enabled":_vm.centerAreaEnabled,"center-area-width":_vm.currentMediaWidth,"hide-cursor-in-center":_vm.hideCursorInCenter},on:{"click-left":_vm.previous,"click-right":_vm.next,"click-center":_vm.openLightbox}},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:swiper",value:({
        initialSlide: _vm.initialSlide,
        lazy: {
          loadPrevNext: true
        },
        centeredSlides: true,
        observer: true,
        observeParents: true,
        slidePrevClass: 'is-prev',
        slideActiveClass: 'is-active',
        slideNextClass: 'is-next',
        pagination: {
          el: `.swiper-pagination-${_vm.$_uid}`,
          clickable: true
        },
        virtual: _vm.virtual,
        on: {
          zoomChange: _vm.zoomChange
        },
        ..._vm.options
      }),expression:"{\n        initialSlide,\n        lazy: {\n          loadPrevNext: true\n        },\n        centeredSlides: true,\n        observer: true,\n        observeParents: true,\n        slidePrevClass: 'is-prev',\n        slideActiveClass: 'is-active',\n        slideNextClass: 'is-next',\n        pagination: {\n          el: `.swiper-pagination-${$_uid}`,\n          clickable: true\n        },\n        virtual: virtual,\n        on: {\n          zoomChange: zoomChange\n        },\n        ...options\n      }",arg:"swiper"}],staticClass:"gallery__swiper swiper",on:{"slide-change":_vm.slideChange,"transition-start":function($event){return _vm.$emit('update:transitioning', true)},"transition-end":function($event){return _vm.$emit('update:transitioning', false)},"zoom-change":_vm.zoomChange}},[_c('div',{ref:"slidesContainer",staticClass:"gallery__wrapper swiper-wrapper"},[_vm._t("default")],2)]),_vm._v(" "),(_vm.isShowingArrows)?_vm._t("arrows",function(){return [_c('nav',[(!_vm.atStart)?_c('button',{staticClass:"gallery__button gallery__button--left link",on:{"click":function($event){$event.preventDefault();return _vm.previous.apply(null, arguments)}}},[_c('span',{staticClass:"font-blank"},[_vm._v("Go to previous slide")]),_vm._v(" "),_c('fa',{staticClass:"gallery__icon",attrs:{"icon":_vm.leftIcon}})],1):_vm._e(),_vm._v(" "),(!_vm.atEnd)?_c('button',{staticClass:"gallery__button gallery__button--right link",on:{"click":function($event){$event.preventDefault();return _vm.next.apply(null, arguments)}}},[_c('span',{staticClass:"font-blank"},[_vm._v("Go to next slide")]),_vm._v(" "),_c('fa',{staticClass:"gallery__icon",attrs:{"icon":_vm.rightIcon}})],1):_vm._e()])]}):_vm._e()],2),_vm._v(" "),_vm._t("bottom",function(){return [_c('fade',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.zoomedIn),expression:"!zoomedIn"}],staticClass:"gallery__pagination swiper-pagination",class:`swiper-pagination-${_vm.$_uid}`})])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }