/**
 * Escapes special characters in the provided string, making it usable
 * in RegExp().
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
 *
 * @param {string} str The string literal to escape.
 * @returns string The escaped string.
 */
export default function escapeRegExp(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}
